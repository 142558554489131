import { Component, OnInit, ViewChild, Inject, EventEmitter, Output, Input, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { EmployeeReportsService } from 'src/app/@core/services/employee-reports.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDateFormats, MAT_DATE_FORMATS } from '@angular/material/core';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import * as moment from 'moment';
import { Observable } from 'rxjs/internal/Observable';
import { LeaveReportService } from 'src/app/@core/services/leave-report.service';


@Component({
  selector: 'app-snapshot-report',
  templateUrl: './snapshot.component.html',
  styleUrls: ['./snapshot.component.scss']
})
export class SnapshotComponent implements OnInit {

  @Input() companyDD: any;
  @Input() gradeDD: any;
  @Input() BranchDD: any;
  @Input() buDD: any;
  @Input() DepDD: any;
  @Input() DesignationDD: any;
  @Input() checkListElements: any;
  @Input() disabled: any;
  @Input() empstatusDD: any;
  @Input() emptypeDD: any;
  @Input() employeeDropdown: any;
  // Above needs to be removed
  @Input() Snapshot : any;
  @Input() salFilter: any;
  @Input() viewDetail: any;
  @Input() callemp : any;
  @Input() closeControl: any;
  @Input() empstatArr: any;
  @Input() tempArr: any;
  @Input() submitted: any;
  @Input() dateSetup : any
  @Input() pageNumber : any
  @Output() loader = new EventEmitter();
  @Input() lastPage :any
  @Input() ordering :any
  @Input() ouFilterForm: any
  @Input() Years : any;

  attendListOfMonth:string  = "listOfMonth";
  year_placeholder = 'Select year'
  month_placeholder = 'Select month'
  monthValue : any
  selectedYear = new Date().getFullYear();
  empLoader         : boolean = false

isInvalidDate = (m: moment.Moment) =>  {
}
fromDateFilter = (date: Date | null): boolean => { return true; }
​
  @Input() resDataFilter:any;

  dateFormat          :any;

  get f()  { return this.Snapshot.controls; }

  @Output() filterPanel                   = new EventEmitter();
  @Output() callMonthlyReport             = new EventEmitter();
  @Output() submitDone                    = new EventEmitter();
  @Output() resetFilter                   = new EventEmitter();

  constructor( public employeereportService:EmployeeReportsService,
    @Inject(MAT_DATE_FORMATS) public matDateFormat: MatDateFormats,
    public appservice:AppService,private leaveService : LeaveReportService,
    public messageService: MessageService,public cd : ChangeDetectorRef) { }

  ngOnInit(): void {
    this.getDateFormat()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['viewDetail'] && changes['viewDetail'].currentValue) {
      this.getEmployeeList();
    }
  }


   // Employee List
   getEmployeeList() {
    this.empLoader = true
    this.leaveService.getEmpList(true)
      .subscribe((res: any) => {
        if (res?.length > 0) {
          for (let i = 0; i < res?.length; i++) {
            res[i]['name'] = res[i]['first_name'];
            if (res[i]['first_name'] != null)
              res[i]['name'] = res[i]['first_name'] + " ";
            if (res[i]['middle_name'] != null)
              res[i]['name'] += res[i]['middle_name'] + " ";
            if (res[i]['last_name'] != null)
              res[i]['name'] += res[i]['last_name'];
            res[i]['name'] += " (" + res[i]['employee_code'] + ")";
          }
          this.employeeDropdown = res;
          let len = this.employeeDropdown.length;
          for (let i = 0; i < len; i++) {
            this.employeeDropdown[i]['fullname'] = "";
            if (this.employeeDropdown[i]['first_name'] != null)
              this.employeeDropdown[i]['fullname'] += this.employeeDropdown[i]['first_name'] + " ";
            if (this.employeeDropdown[i]['middle_name'] != null)
              this.employeeDropdown[i]['fullname'] += this.employeeDropdown[i]['middle_name'] + " ";
            if (this.employeeDropdown[i]['last_name'] != null)
              this.employeeDropdown[i]['fullname'] += this.employeeDropdown[i]['last_name'];
          }
          // this.selectAllForDropdownItems(this.employeeDropdown);
        }
        this.empLoader = false
      })
  }


  filterFormData(use :any){
    let selectedDate;
    let selectedLogin;
    let selectedConfirm;

    if(this.Snapshot.controls.from_date_birth.value != '' && this.Snapshot.controls.to_date_birth.value != ''){
      let start1         = moment(this.Snapshot.controls.from_date_birth.value,this.dateFormat).format('YYYY-MM-DD');
      let end1           = moment(this.Snapshot.controls.to_date_birth.value,this.dateFormat).format('YYYY-MM-DD');
      selectedDate          = moment(start1).isValid() || moment(end1).isValid() ?  start1+','+end1 : []
    }  ​
    this.Snapshot.controls.birthday.value == true ? this.Snapshot.get('birthday')?.setValue(selectedDate) : this.Snapshot.get('birthday')?.setValue(this.Snapshot.controls.birthday.value);
    let birthday = this.Snapshot.controls.birthday.value;


    if(this.Snapshot.controls.from_date_join.value != null && this.Snapshot.controls.from_date_join.value != '' && this.Snapshot.controls.to_date_join.value != ''){
      let start1         = moment(this.Snapshot.controls.from_date_join.value,this.dateFormat).format('YYYY-MM-DD');
      let end1           = moment(this.Snapshot.controls.to_date_join.value,this.dateFormat).format('YYYY-MM-DD');
      selectedLogin          = moment(start1).isValid() || moment(end1).isValid() ?  start1+','+end1 : []
    }  ​
    this.Snapshot.controls.date_of_joining.value == true ? this.Snapshot.get('date_of_joining')?.setValue(selectedLogin) : this.Snapshot.get('date_of_joining')?.setValue(this.Snapshot.controls.date_of_joining.value);
    let date_of_joining = this.Snapshot.controls.date_of_joining.value==undefined?'':this.Snapshot.controls.date_of_joining.value;


    if(this.Snapshot.controls.from_date_confirm.value != '' && this.Snapshot.controls.to_date_confirm.value != ''){
      let start1         = moment(this.Snapshot.controls.from_date_confirm.value,this.dateFormat).format('YYYY-MM-DD');
      let end1           = moment(this.Snapshot.controls.to_date_confirm.value,this.dateFormat).format('YYYY-MM-DD');
      selectedConfirm          = moment(start1).isValid() || moment(end1).isValid() ?  start1+','+end1 : []
    }  ​
    this.Snapshot.controls.date_of_confirmation.value == true ? this.Snapshot.get('date_of_confirmation')?.setValue(selectedConfirm) : this.Snapshot.get('date_of_confirmation')?.setValue(this.Snapshot.controls.date_of_confirmation.value);
    let date_of_confirmation = this.Snapshot.controls.date_of_confirmation.value==null?'':this.Snapshot.controls.date_of_confirmation.value;

   this.Snapshot.controls.created_date.value == true ? this.Snapshot.get('created_date')?.setValue(this.appservice.dateFormatConvert(this.Snapshot.controls.cd_specific_date.value)) : this.Snapshot.get('created_date')?.setValue(this.Snapshot.controls.created_date.value);

    // this.Snapshot.controls.date_of_leaving.value == true ? this.Snapshot.get('date_of_leaving')?.setValue(this.appservice.dateFormatConvert(this.Snapshot.controls.dol_specific_date.value)) : this.Snapshot.get('date_of_leaving')?.setValue(this.Snapshot.controls.date_of_leaving.value);
    if(this.Snapshot.controls.date_of_leaving.value == true){
      this.Snapshot.get('date_of_leaving')?.setValue(this.Snapshot.controls.specific_year.value+'-'+this.monthValue)
    }
    else{
      this.Snapshot.get('date_of_leaving')?.setValue(this.Snapshot.controls.date_of_leaving.value)
    }

    let company     = this.stringifyCont(this.ouFilterForm.controls.company.value);
    let bu          = this.stringifyCont(this.ouFilterForm.controls.bu.value);
    let dept        = this.stringifyCont(this.ouFilterForm.controls.department.value);
    let desig        = this.stringifyCont(this.ouFilterForm.controls.designation.value);
    let branch      = this.stringifyCont(this.ouFilterForm.controls.branch.value);
    let employee_list = this.stringifyCont(this.ouFilterForm.controls.employee_list.value);
    let grade         = this.stringifyCont(this.ouFilterForm.controls.grade.value);
    let employment_status  = this.stringifyCont(this.ouFilterForm.controls.employment_status.value);
    let employee_type    = this.stringifyCont(this.ouFilterForm.controls.employee_type.value);
    let total_experience   = this.Snapshot.controls.total_experience.value==''?JSON.stringify([]):JSON.stringify(this.Snapshot.controls.total_experience.value);
    let reporting_manager   = this.Snapshot.controls.reporting_manager.value==''?JSON.stringify([]):JSON.stringify(this.Snapshot.controls.reporting_manager.value);
    let functional_manager  = this.Snapshot.controls.functional_manager.value==''?JSON.stringify([]):JSON.stringify(this.Snapshot.controls.functional_manager.value);

    if(use == 'filter'){
      return `birthday=${birthday}&bank_status=${this.Snapshot.controls.bank_status.value}&personal_status=${this.Snapshot.controls.personal_status.value}&document_status=${this.Snapshot.controls.document_status.value}&education_status=${this.Snapshot.controls.education_status.value}&family_status=${this.Snapshot.controls.family_status.value}&health_insurance_status=${this.Snapshot.controls.health_insurance_status.value}&previous_employer_detail_status=${this.Snapshot.controls.previous_employer_detail_status.value}&work_experience_status=${this.Snapshot.controls.work_experience_status.value}&total_experience=${total_experience}&reporting_manager=${reporting_manager}&functional_manager=${functional_manager}&created_date=${this.Snapshot.controls.created_date.value}&date_of_joining=${date_of_joining}&date_of_leaving=${this.Snapshot.controls.date_of_leaving.value == null ? [] : this.Snapshot.controls.date_of_leaving.value}&date_of_confirmation=${date_of_confirmation}&company=${company}&bu=${bu}&department=${dept}&designation=${desig}&grade=${grade}&emp_status=${employment_status}&emp_type=${employee_type}&branch=${branch}&employee_list=${employee_list}&ordering=${this.ordering}`
    }else{
      return JSON.stringify({'birthday':birthday,'bank_status':this.Snapshot.controls.bank_status.value,'personal_status':this.Snapshot.controls.personal_status.value,'document_status':this.Snapshot.controls.document_status.value,'education_status':this.Snapshot.controls.education_status.value,'family_status':this.Snapshot.controls.family_status.value,'health_insurance_status':this.Snapshot.controls.health_insurance_status.value,'previous_employer_detail_status':this.Snapshot.controls.previous_employer_detail_status.value,'work_experience_status':this.Snapshot.controls.work_experience_status.value,'total_experience':this.Snapshot.controls.total_experience.value,'reporting_manager':this.Snapshot.controls.reporting_manager.value,'functional_manager':this.Snapshot.controls.functional_manager.value,'created_date':this.Snapshot.controls.created_date.value,'date_of_joining':date_of_joining,'date_of_leaving':this.Snapshot.controls.date_of_leaving.value,'date_of_confirmation':date_of_confirmation,'company' : this.ouFilterForm.controls.company?.value, 'bu' : this.ouFilterForm.controls.bu?.value, 'department' : this.ouFilterForm.controls.department?.value, 'designation' : this.ouFilterForm.controls.designation?.value,'grade' : this.ouFilterForm.controls.grade?.value, 'emp_status' : this.ouFilterForm.controls.employment_status?.value == null ? '' : this.ouFilterForm.controls.employment_status?.value, 'emp_type' : this.ouFilterForm.controls.employee_type.value == null ? '' :this.ouFilterForm.controls.employee_type?.value, 'branch' : this.ouFilterForm.controls.branch.value,'employee_list':this.ouFilterForm.controls.employee_list.value})
    }
  }

  getDateFormat(){
    this.dateFormat = this.appservice.getdatepickerformat();
    if(this.dateFormat == ''){
      setTimeout(() => {
        this.getDateFormat();
      }, 1000);
    }
  }

  resetValues:any = [
    {'birthday_specific_date':['from_date_birth','to_date_birth']},
    {'cd_specific_date' :['cd_specific_date']},
    {'dol_specific_date':['specific_month','specific_year']},
    {'doj_specific_date':['from_date_join','to_date_join']},
    {'doc_specific_date':['from_date_confirm','to_date_confirm']},
  ]
   // Emiting the value of radio to parent
   radioBtnChangeFunctions(e:any,contrlName:any,val:any,type:any){
    if(e.target.value == 'on' ){
     this.Snapshot?.get(contrlName)?.setValue(true);
    }
    else{
      for(let j=0;j<this.resetValues.length;j++){
        Object.entries(this.resetValues[j]).forEach(([key, value]) => {
          if(key==type){
            let data:any = value
            for(let i=0;i<data.length;i++){
              this.Snapshot?.get(data[i])?.setValue(null);
            }
          }
        })
      }
     this.Snapshot?.get(contrlName)?.setValue(val);
     this.Snapshot?.get(type)?.setValue('');
    }
    if(val==true)
    this.setRequired(contrlName,true)
    else
    this.setRequired(contrlName,false)

  }

  // Close Filter Panel
  close(){
    this.viewDetail = false
    this.filterPanel.emit(this.viewDetail)
  }

  // Select All
  selectAllForDropdownItems(items: any[]) {
    let allSelect = (items: any[]) => {
      items.forEach(element => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }

  clearcommonForm(item:any,index:any){
   if(item == "employeesrm"){
      let daata = this.Snapshot.value.reporting_manager;
      daata.splice(index, 1);
      this.Snapshot.patchValue({
        reporting_manager : daata
      });
    }
    else if(item == "employeesfm"){
      let daata = this.Snapshot.value.functional_manager;
      daata.splice(index, 1);
      this.Snapshot.patchValue({
        functional_manager : daata
      });
    }
  }

  resetFilterFunction(){
    if(this.resDataFilter != undefined && this.resDataFilter != null && this.resDataFilter != ''){
      if(!!Object.keys(this.resDataFilter)?.length == true){
        this.resetFilter.emit(true)
      }else{
        this.resetFilterControllers('reset');
      }
    }
  }

  resetFilterControllers(data:any){
    this.viewDetail = false;
    this.filterPanel.emit(this.viewDetail)
    if(data == 'reset'){
      this.Snapshot.get('birthday')?.reset('');
      this.Snapshot.get('date_of_joining')?.reset('');
      this.Snapshot.get('date_of_leaving')?.reset('');
      this.Snapshot.get('date_of_confirmation')?.reset('');
      this.Snapshot.get('created_date')?.reset('');
      this.Snapshot.get('bank_status')?.reset('');
      this.Snapshot.get('personal_status')?.reset('');
      this.Snapshot.get('document_status')?.reset('');
      this.Snapshot.get('education_status')?.reset('');
      this.Snapshot.get('family_status')?.reset('');
      this.Snapshot.get('health_insurance_status')?.reset('');
      this.Snapshot.get('previous_employer_detail_status')?.reset('');
      this.Snapshot.get('work_experience_status')?.reset('');
      this.Snapshot.get('total_experience')?.reset('');
      this.Snapshot.get('reporting_manager')?.reset('');
      this.Snapshot.get('functional_manager')?.reset('');
      this.ouFilterForm.get('company')?.reset('');
      this.ouFilterForm.get('department')?.reset('');
      this.ouFilterForm.get('bu')?.reset('');
      this.ouFilterForm.get('designation')?.reset('');
      this.ouFilterForm.get('grade')?.reset('');
      this.ouFilterForm.get('branch')?.reset('');
      this.ouFilterForm.get('employee_list')?.reset('');
      this.ouFilterForm.get('employment_status')?.reset('');
      this.ouFilterForm.get('employee_type')?.reset('');
      this.Snapshot.get('specific_date')?.reset('');
      this.Snapshot.get('cd_specific_date')?.reset('');
      this.Snapshot.get('dol_specific_date')?.reset('');
      this.Snapshot.get('from_date_birth')?.reset('');
      this.Snapshot.get('from_date_confirm')?.reset('');
      this.Snapshot.get('from_date_join')?.reset('');
      this.Snapshot.get('to_date_birth')?.reset('');
      this.Snapshot.get('to_date_confirm')?.reset('');
      this.Snapshot.get('to_date_join')?.reset('');
      this.Snapshot.get('specific_month')?.reset('');
      this.Snapshot.get('specific_year')?.reset('');
  }
  this.callMonthlyReport.emit({
    reportName : 'Snapshot'
  })
}

applyBtn : boolean = false

submitSnapshot(){
  let offset = 0
  this.submitted      = true;
  if (this.Snapshot.invalid) {
    return;
  }
  let limit = 20
  offset = this.applyBtn == true ? 0 : this.employeereportService.calculateOffset(this.pageNumber)
  let filterForm = this.filterFormData('filter')
  this.submitDone.emit({
    limit : limit,
    offset : offset,
    filterForm : filterForm
  });
  this.viewDetail           = false;
  this.applyBtn = false
}


// Tags closing
applyTagClose1(){
  this.applyBtn = true
  if(this.closeControl == 'emp_status'){
    this.empstatArr = [];
    this.closeControl = 'employment_status';
  }else if(this.closeControl == 'emp_type'){
    this.tempArr = [];
  }
  this.closeControl = this.closeControl == 'date_of_birth' ? 'birthday' : this.closeControl
  if(this.closeControl == 'company' || this.closeControl == 'department' || this.closeControl == 'bu' || this.closeControl == 'designation' || this.closeControl == 'grade' || this.closeControl == 'branch' || this.closeControl == 'employee_list' || this.closeControl == 'employee_type' || this.closeControl == 'employment_status'){
    this.ouFilterForm.get(this.closeControl)?.setValue('')
  }else{
    this.Snapshot.get(this.closeControl)?.setValue('');
  }
  this.submitSnapshot();
}

  resetPage(){
    this.applyBtn = true
    this.employeereportService.calculateOffset(0)
    this.cd.detectChanges()
  }

  getData() {
    const data = this.filterFormData('')
    const emptyKeys = Object.keys(data)
    .filter((key:any) => this.isValueEmpty(data[key]));
    return emptyKeys?.length ==  Object.keys(data)?.length ? "" : data
  }

  isValueEmpty(value:any) {
    return value === '' || value === null || value === undefined || (Array.isArray(value) && value.length === 0);
  }

  stringifyCont(control: any): string {
    return control === '' ? JSON.stringify([]) : JSON.stringify(control);
  }
  setRequired(key:any,val:any){
    var arr:any = []
    switch(key) {
      case 'birthday': {
        arr = ['from_date_birth','to_date_birth']
        break;
      }
      case 'date_of_joining': {
        arr = ['from_date_join','to_date_join']
        break;
      }
      case 'created_date': {
        arr = ['cd_specific_date']
        break;
      }
      case 'date_of_leaving':{
        arr = ['specific_year','specific_month']
        break;

      }
      default: {
        arr = ['from_date_confirm','to_date_confirm']
        break;
      }
    }

    if(val){
      arr.forEach((item:any)=>{
        this.Snapshot.get(item)?.setValidators([Validators.required]);
        this.Snapshot.get(item)?.updateValueAndValidity();
      })
    }else{
      arr.forEach((item:any)=>{
        this.Snapshot.get(item)?.clearValidators()
        this.Snapshot.get(item)?.updateValueAndValidity();
      })
    }
  }
  //function for get month no from month name
   attendancemonthChangeFn(event:any){
    var date = new Date(Date.parse(event +" 1, "+this.selectedYear+""))
    this.monthValue = String(date.getMonth() + 1).padStart(2, '0');
  }
}




