import { ChangeDetectorRef, Component, Inject, NgZone, OnInit, ViewChild } from '@angular/core';
import { LeaveParticularDayComponent } from './leave-particular-day/leave-particular-day.component';
import { MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
import { AppService } from 'src/app/app.global';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { RoleService } from 'src/app/@core/services/role.service';
import { ReportsService } from 'src/app/@core/services/reports.service';
import { AbstractControl, FormBuilder, FormControl, ValidationErrors, Validators } from '@angular/forms';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { LeaveReportService } from 'src/app/@core/services/leave-report.service';
import { MatTableDataSource } from '@angular/material/table';
import { LeavePolicyService } from 'src/app/@core/services/leave-policy.service';
import { LeaveCarryForwardComponent } from './leave-carry-forward/leave-carry-forward.component';
import { LeaveAvailedComponent } from './leave-availed/leave-availed.component';
import { LeaveCreditComponent } from './leave-credit/leave-credit.component';
import { LeaveEncashmentComponent } from './leave-encashment/leave-encashment.component';
import { LeaveLapsedComponent } from './leave-lapsed/leave-lapsed.component';
import { LeaveNegativeComponent } from './leave-negative/leave-negative.component';
import { LeaveLopComponent } from './leave-lop/leave-lop.component';
import { LeaveSummaryComponent } from './leave-summary/leave-summary.component';
import { LeaveLedgerComponent } from './leave-ledger/leave-ledger.component';
import { LeaveRequestComponent } from './leave-request/leave-request.component';
import { leavetypeService } from 'src/app/@core/services/leave-type.service';
import * as moment from 'moment';
import { NotificationService } from 'src/app/@core/services/notification.service';
@Component({
  selector: 'app-leave-report-configure',
  templateUrl: './leave-report-configure.component.html',
  styleUrls: ['./leave-report-configure.component.scss']
})
export class LeaveReportConfigureComponent implements OnInit {

  scrollLeft(e: any): void {
    let wrapper = e.srcElement.parentElement.nextSibling;
    wrapper.scrollLeft -= 150;
  }
  scrollRight(e: any): void {
    let wrapper = e.srcElement.parentElement.nextSibling;
    wrapper.scrollLeft += 150;
  }
  alertShow = true;
  reportName: any = '';
  reportId: any = 0;
  closeControl: any
  callReportVal: any
  responseLength: any
  headerCheck: any
  checkList: any
  dataSource: any = [];
  employeeDropdown: any = [];
  displayedColumns: any = [];
  fullData: any = [];
  columnOptions: any = [];
  excelHeaders: string[] = [];
  templateToExcel: string[][] = []
  checkColumn: any = [];
  columns1: string[] = [];
  columns2: string[] = [];
  columnOptions1: any = [];
  applyFilter = false;
  viewDetail = false;
  resetFilter = false;
  scheduleReport = false;
  addColumnFilter = false;
  nodata = false;
  loader = true;
  disabled = false;
  columnsControl = new FormControl();

  fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  fileExtension = '.xlsx';

  //Filter Memorize Variables
  resetReportName: any;
  resDataFilter: any;
  hasChangeCount: any = 0;
  dataFilter: any = [];
  header_filter: any = [];
  resHeader_filter: any = [];
  leaveType: any = [];
  leavePolicy: any = [];
  filterDisable = false;
  is_colfilter = false;
  submitted = false;
  filterSubmit = false;
  checkFilterSubmit = false;
  saveFilterClicked = false
  restFilterSave = false

  event: any
  contrlName: any
  val: any
  type: any
  form: any
  dateSetup: any
  dateSplit: any

  //Tag Filter Variables
  appliedFilter: any = [];
  tagMultiData: any = [];
  tagMultiCnt: any = 0;

  filterFormData: any = ''
  childFilterSubmit: boolean = false

  //SCHEDULE REPORT MEMORIZE VARIABLES
  isFilter = false;
  scheduleText: any = '';

  hint_dict: any;

  emptypeDD = [
    { id: "Contractual", name: "Contractual" },
    { id: "Consultant", name: "Consultant" },
    { id: "Permanent", name: "Permanent" },
    { id: "Trainee", name: "Trainee" },
    { id: "Wages", name: "Wages" },
  ];
  empstatusDD = [
    { id: 0, name: "Probation" },
    { id: 1, name: "Confirmed" },
    { id: 2, name: "Relieved" },
    { id: 3, name: "Resigned" },
    { id: 4, name: "Settled" },
    { id: 5, name: "Terminated" },
    { id: 6, name: "Retired" },
  ];

  companyDD: any = [{
    id: 0,
    company_name: ''
  }];

  buDD = [{
    id: 0,
    name: ''
  }];

  DepDD = [{
    id: 0,
    name: ''
  }];

  DesignationDD = [{
    id: 0,
    name: ''
  }];

  countryDD = [{
    id: 0,
    name: ''
  }];

  stateDD = [{
    id: 0,
    name: ''
  }];

  BranchDD = [{
    id: 0,
    name: ''
  }];

  cityDD = [{
    id: 0,
    name: ''
  }];

  gradeDD = [{
    id: 0,
    name: ''
  }];

  columnsArr: any = [] // for store optional headers
  columnsSave: any = []

  requiredWithDateRange = (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    const isNullDateRange = (value === null) ||
      (typeof value === 'object' && value.startDate === null && value.endDate === null);
    return isNullDateRange ? { required: true } : Validators.required(control);
  };

  OuFilterForm = this.formBuilder.group({
    leave_policy: [''],
    leave_type: [''],
    company: [''],
    bu: [''],
    branch: [''],
    designation: [''],
    department: [''],
    employee_list: [''],
    grade: [''],
    employee_type: [''],
    employment_status: [''],
  })

  // Leave Balance on a Particular Day Form
  balanceParticularForm = this.formBuilder.group({
    selected_date: ['', [Validators.required]],
    ouFilterForm: this.OuFilterForm
  })

  // Leave carry forward form
  carryForwardForm = this.formBuilder.group({
    period: [true, [Validators.required]],
    ouFilterForm: this.OuFilterForm
  })

  // Availed Form
  availedForm = this.formBuilder.group({
    transaction_date: ['this_month', [Validators.required]],
    from_date: '',
    to_date: '',
    ouFilterForm: this.OuFilterForm
  })

  // Credit Form
  creditForm = this.formBuilder.group({
    leave_credit_for: ['this_month', [Validators.required]],
    from_date: '',
    to_date: '',
    ouFilterForm: this.OuFilterForm
  })

  // Encashment Form
  encashmentForm = this.formBuilder.group({
    period: [true, [Validators.required]],
    ouFilterForm: this.OuFilterForm
  })

  // Lapsed Form
  lapsedForm = this.formBuilder.group({
    period: [true, [Validators.required]],
    ouFilterForm: this.OuFilterForm
  })

  // Negative Form
  negativeForm = this.formBuilder.group({
    selected_date: ['', [Validators.required]],
    ouFilterForm: this.OuFilterForm
  })

  // LOP Form
  lopForm = this.formBuilder.group({
    transaction_date: ['this_month', [Validators.required]],
    from_date: '',
    to_date: '',
    ouFilterForm: this.OuFilterForm
  })

  // Balance Form
  balanceForm = this.formBuilder.group({
    from_date: '',
    to_date: '',
    ouFilterForm: this.OuFilterForm
  })

  // Ledger Form
  ledgerForm = this.formBuilder.group({
    transaction_date: ['this_month', [Validators.required]],
    trans_from_date: '',
    trans_to_date: '',
    from_date: ['', [Validators.required]],
    to_date: ['', [Validators.required]],
    ouFilterForm: this.OuFilterForm
  })

  // Request Form
  requestForm = this.formBuilder.group({
    leave_period: ['this_month', [Validators.required]],
    from_date: '',
    to_date: '',
    request_type: '',
    status: ['', [Validators.required]],
    ouFilterForm: this.OuFilterForm
  })

  // Test Form
  testform = this.formBuilder.group({
    checkbox: [''],
  })

  // Schedule
  scheduleReportFilterMemorizeForm = this.formBuilder.group({
    filter: true,
  });

  // Filter Save Form
  filterSaveForm = this.formBuilder.group({
    filter: true,
  });

  columnChangedArr : any = []

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  @ViewChild(LeaveParticularDayComponent, { static: false }) balanceParticularDay !: LeaveParticularDayComponent;
  @ViewChild(LeaveCarryForwardComponent, { static: false }) carryForwadReport !: LeaveCarryForwardComponent;
  @ViewChild(LeaveAvailedComponent, { static: false }) availedReport !: LeaveAvailedComponent;
  @ViewChild(LeaveCreditComponent, { static: false }) creditReport !: LeaveCreditComponent;
  @ViewChild(LeaveEncashmentComponent, { static: false }) encashmentReport !: LeaveEncashmentComponent;
  @ViewChild(LeaveLapsedComponent, { static: false }) lapsedReport !: LeaveLapsedComponent;
  @ViewChild(LeaveNegativeComponent, { static: false }) negativeReport !: LeaveNegativeComponent;
  @ViewChild(LeaveLopComponent, { static: false }) lopReport !: LeaveLopComponent;
  @ViewChild(LeaveSummaryComponent, { static: false }) balanceReport !: LeaveSummaryComponent;
  @ViewChild(LeaveLedgerComponent, { static: false }) ledgerReport !: LeaveLedgerComponent;
  @ViewChild(LeaveRequestComponent, { static: false }) requestReport !: LeaveRequestComponent;

  defaultHeaderList: any = [];

  constructor(
    @Inject(MAT_DATE_FORMATS) public matDateFormat: MatDateFormats,
    public appservice: AppService,
    public route: ActivatedRoute,
    private roleService: RoleService,
    private reportService: ReportsService,
    public router: Router,
    private formBuilder: FormBuilder,
    private leaveService: LeaveReportService,
    private leavePolicyServ: LeavePolicyService,
    public cd: ChangeDetectorRef,
    private leaveTypeServ: leavetypeService,
    private notify :  NotificationService
  ) { }

  ngOnInit(): void {
    // Date Format
    this.getDateFormat();
    this.childFilterSubmit = true
    // ID
    this.route.params.subscribe((params: Params) => {
      if (!isNaN(params['id'])) {
        this.reportId = params['id'];
      }
      if (params['name'] == 'LeaveBalanceOnAParticularDayReport') {
        this.reportName = 'Leave Balance On A Particular Day'
        this.leaveBalanceParticularDay();
      }
      if (params['name'] == 'LeaveCarryForwardReport') {
        this.reportName = 'Leave Carry Forward Report'
        this.carryForwardReport();
      }
      if (params['name'] == 'LeaveAvailedReport') {
        this.reportName = 'Leave Availed Report'
        this.leaveAvailedReport();
      }
      if (params['name'] == 'LeaveCreditReport') {
        this.reportName = 'Leave Credit Report'
        this.leaveCreditReport();
      }
      if (params['name'] == 'LeaveEncashmentReport') {
        this.reportName = 'Leave Encashment Report'
        this.leaveEncashmentReport();
      }
      if (params['name'] == 'LapsedLeavesReport') {
        this.reportName = 'Lapsed Leaves Report'
        this.leaveLapsedReport();
      }
      if (params['name'] == 'NegativeLeaveBalanceReport') {
        this.reportName = 'Negative Leave Balance Report'
        this.leaveNegativeReport();
      }
      if (params['name'] == 'UnpaidLeaveReport') {
        this.reportName = 'Unpaid Leave Report'
        this.leaveLOPReport();
      }
      if (params['name'] == 'LeaveBalanceSummaryReport') {
        this.reportName = 'Leave Balance Summary Report'
        this.leaveBalanceReport();
      }
      if (params['name'] == 'LeaveLedgerReport') {
        this.reportName = 'Leave Ledger Report'
        this.leaveLedgerReport();
      }
      if (params['name'] == 'LeaveRequestStatusReport') {
        this.reportName = 'Leave Request Status Report'
        this.leaveRequest();
      }
    })
  }

  onPopoverClick(event: Event): void {
    event.stopPropagation();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.getCompanyList();
    this.getBUList();
    this.getDepList();
    this.getDesignationList();
    this.getGradeList();
    this.getBranchList();
    this.getEmployeeList();
    this.getLeaveTypeList();
    this.getLeavePolicyList();
    this.getUnpaidLeaveList();

    this.selectAllForDropdownItems(this.empstatusDD);
    this.selectAllForDropdownItems(this.emptypeDD);
  }
  pageChangeDetect: boolean = false
  // Leave Balance On a Particular Day
  leaveBalanceParticularDay() {
    this.loader = true;
    this.reportName = "Leave Balance On A Particular Day";
    this.appliedFilter = [];
    this.offset = this.resetOffset ? 0 : this.offset
    this.page = this.resetOffset ? 1 : this.page

    this.leaveService.balanceParticularDay(this.limit, this.offset, this.ordering, this.filterFormData, this.childFilterSubmit).subscribe((res: any) => {
      if (res?.data?.length != 0) {
        this.dataSource = res?.data;
        this.lastPage = res?.count
        // Formating as per standard for dates
        if (this.dataSource?.length > 0) {
          for (let i = 0; i < res.data?.length; i++) {
            this.dataSource[i]['Date of Joining'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Date of Joining']);
            this.dataSource[i]['Leave Type'] = this.dataSource[i]['Leave type']
            this.dataSource[i]['Employment status'] = this.dataSource[i]['Employee Status']
          }
        }
        this.fullData = this.dataSource
        this.dataSource = new MatTableDataSource(this.dataSource);
        this.setDataSourceAttributes();
      }

      // if(this.childFilterSubmit){
        this.resDataFilter     = res?.data_filter;
        this.resHeader_filter  = res?.header_filter;
      // }
      this.defaultHeaderList = res?.header_list?.slice()
      this.hint_dict = res?.hint_dict ? res?.hint_dict : {}

      if (res?.hasOwnProperty('applied_filter'))
        this.appliedFilter = res.applied_filter;

      this.columns2 = res['check_list'];
      this.columnOptions = this.columns2;
      this.columnsControl.setValue(this.columnOptions1);
      this.nodata = res?.data?.length == 0 ? true : false;
      this.cd.detectChanges()

      // /**dynamic data table**/
      if (!this.resetOffset && !this.pageChangeDetect) {
        if (this.resHeader_filter?.length != 0)
          this.dynamicHeader(res.data?.length, this.resHeader_filter, res['check_list']);
        else
          this.dynamicHeader(res.data?.length, res['header_list'], res['check_list']);
      }

      /**Filter data**/
      if (this.resDataFilter != null && this.resDataFilter != undefined) {
        if (!!Object.keys(this.resDataFilter)?.length == true) {
          this.balanceParticularForm.reset(this.resDataFilter);
          this.OuFilterForm.reset(this.resDataFilter)
          if (this.resDataFilter?.company?.length > 0) {
            this.getBUList();
            if (this.resDataFilter?.bu?.length > 0)
              this.OuFilterForm.get('bu')?.setValue(this.resDataFilter.bu);
          }
          if (this.balanceParticularForm.get('selected_date')?.value == null) {
            this.balanceParticularForm.get('selected_date')?.reset(new Date())
          } else {
            this.balanceParticularForm.get('selected_date')?.reset(this.resDataFilter.selected_date)
          }
        }
      }

      /**Header filter data**/
      if (!this.resetOffset && !this.pageChangeDetect) {
        if (this.resHeader_filter?.length != 0)
          this.headerFilterImplementation(this.resHeader_filter)
        else
          this.headerFilterImplementation(res?.header_list)
      }

      this.resetOffset = false
      this.checkListElements();
      this.checkChange = true
      this.headerFilterApply()
      this.loader = false;
      this.pageChangeDetect = false
    })
  }

  // Carry Forward Report
  carryForwardReport() {
    this.loader = true;
    this.reportName = "Leave Carry Forward Report";
    this.appliedFilter = [];
    this.offset = this.resetOffset ? 0 : this.offset
    this.page = this.resetOffset ? 1 : this.page

    this.leaveService.carryForwardReport(this.limit, this.offset, this.ordering, this.filterFormData, this.childFilterSubmit).subscribe((res: any) => {
      if (res.data?.length != 0) {
        this.dataSource = res?.data
        this.lastPage = res?.count

        // Formating as per standard for dates
        if (this.dataSource?.length > 0) {
          for (let i = 0; i < res.data?.length; i++) {
            this.dataSource[i]['Date of Joining'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Date of Joining']);
            this.dataSource[i]['Leave Type'] = this.dataSource[i]['Leave type']
            this.dataSource[i]['Employee status'] = this.dataSource[i]['Employee Status']
          }
        }
        this.fullData = this.dataSource;
        this.dataSource = new MatTableDataSource(this.dataSource);
        this.setDataSourceAttributes();
      }

      // if(this.childFilterSubmit){
        this.resDataFilter     = res?.data_filter;
        this.resHeader_filter  = res?.header_filter;
      // }
      this.defaultHeaderList = res?.header_list?.slice()
      this.hint_dict = res?.hint_dict ? res?.hint_dict : {}

      if (res.hasOwnProperty('applied_filter'))
        this.appliedFilter = res?.applied_filter;

      this.columns2 = res['check_list']
      this.columnOptions = this.columns2;
      this.columnsControl.setValue(this.columnOptions1);
      this.nodata = res?.data?.length == 0 ? true : false;
      this.cd.detectChanges()

      // /**dynamic data table**/
      if (!this.resetOffset && !this.pageChangeDetect) {
        if (this.resHeader_filter?.length != 0)
          this.dynamicHeader(res.data?.length, this.resHeader_filter, res['check_list']);
        else
          this.dynamicHeader(res.data?.length, res['header_list'], res['check_list']);
      }


      /**Filter data**/
      if (this.resDataFilter != null && this.resDataFilter != undefined) {
        if (!!Object.keys(this.resDataFilter)?.length == true) {
          this.carryForwardForm.reset(this.resDataFilter);
          this.OuFilterForm.reset(this.resDataFilter)

          if (this.resDataFilter?.company?.length > 0) {
            this.getBUList();
            if (this.resDataFilter?.bu?.length > 0)
              this.OuFilterForm.get('bu')?.setValue(this.resDataFilter.bu);
          }
        }
      }

      /**Header filter data**/
      if (!this.resetOffset && !this.pageChangeDetect) {
        if (this.resHeader_filter?.length != 0)
          this.headerFilterImplementation(this.resHeader_filter)
        else
          this.headerFilterImplementation(res?.header_list)
      }

      this.resetOffset = false
      this.checkListElements();
      this.checkChange = true
      this.headerFilterApply()
      this.loader = false;
      this.pageChangeDetect = false
    })
  }

  // Leave Availed
  leaveAvailedReport() {
    this.loader = true;
    this.reportName = "Leave Availed Report";
    this.appliedFilter = [];
    this.offset = this.resetOffset ? 0 : this.offset
    this.page = this.resetOffset ? 1 : this.page

    this.leaveService.availedReport(this.limit, this.offset, this.ordering, this.filterFormData, this.childFilterSubmit).subscribe((res: any) => {
      if (res.data?.length != 0) {
        this.dataSource = res?.data
        this.lastPage = res?.count
        // Formating as per standard for dates
        if (this.dataSource?.length > 0) {
          for (let i = 0; i < res.data?.length; i++) {
            this.dataSource[i]['Date of Joining'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Date of Joining']);
            this.dataSource[i]['Applied on'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Applied on']);
            this.dataSource[i]['Availed From'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Availed From']);
            this.dataSource[i]['Availed To'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Availed To']);
            this.dataSource[i]['Approved on'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Approved on'])
            this.dataSource[i]['Leave Type'] = this.dataSource[i]['Leave type']
            this.dataSource[i]['Employee status'] = this.dataSource[i]['Employee Status']
          }
        }
        this.fullData = this.dataSource;
        this.dataSource = new MatTableDataSource(this.dataSource);
        this.setDataSourceAttributes();
      }

      // if(this.childFilterSubmit){
        this.resDataFilter     = res?.data_filter;
        this.resHeader_filter  = res?.header_filter;
      // }
      this.defaultHeaderList = res?.header_list?.slice()
      this.hint_dict = res?.hint_dict ? res?.hint_dict : {}

      if (res.hasOwnProperty('applied_filter')) {
        this.appliedFilter = res.applied_filter;
        this.dateSetup = res.applied_filter
        if (res.applied_filter?.transaction_date && res.applied_filter?.transaction_date != 'last_month' && res.applied_filter?.transaction_date != 'this_month')
          this.dateSplit = res.applied_filter?.transaction_date
        if (this.dateSplit != undefined && this.appliedFilter?.transaction_date != 'last_month' && this.appliedFilter?.transaction_date != 'this_month') {
          this.availedForm.get('transaction_date')?.setValue(true);
          this.availedForm.get('from_date')?.setValue(new Date(this.dateSplit[0]))
          this.availedForm.get('to_date')?.setValue(new Date(this.dateSplit[1]))
        } else {
          this.availedForm.get('transaction_date')?.reset(res?.applied_filter?.transaction_date);
        }
        if (res?.applied_filter?.transaction_date == undefined) {
          this.availedForm.get('transaction_date')?.reset('this_month')
        }
      }

      this.columns2 = res['check_list'];
      this.columnOptions = this.columns2;
      this.columnsControl.setValue(this.columnOptions1);
      this.nodata = res?.data?.length == 0 ? true : false;
      this.cd.detectChanges()

      // /**dynamic data table**/
      if (!this.resetOffset && !this.pageChangeDetect) {
        if (this.resHeader_filter?.length != 0)
          this.dynamicHeader(res.data?.length, this.resHeader_filter, res['check_list']);
        else
          this.dynamicHeader(res.data?.length, res['header_list'], res['check_list']);
      }


      /**Filter data**/
      if (this.resDataFilter != null && this.resDataFilter != undefined) {
        if (!!Object.keys(this.resDataFilter)?.length == true) {
          this.availedForm.reset(this.resDataFilter);
          this.OuFilterForm.reset(this.resDataFilter)

          if (this.resDataFilter?.company?.length > 0) {
            this.getBUList();
            if (this.resDataFilter?.bu?.length > 0)
              this.OuFilterForm.get('bu')?.setValue(this.resDataFilter.bu);
          }

          // Setting for the specific month to be active if no other option is selected
          if (this.dateSplit != undefined && this.resDataFilter.transaction_date != 'last_month' && this.resDataFilter.transaction_date != 'this_month') {
            this.availedForm.get('transaction_date')?.setValue(true);
            this.availedForm.get('from_date')?.setValue(new Date(this.dateSplit[0]))
            this.availedForm.get('to_date')?.setValue(new Date(this.dateSplit[1]))
          } else {
            this.availedForm.get('transaction_date')?.reset(this.resDataFilter.transaction_date);
          }
        }
      }

      /**Header filter data**/
      if (!this.resetOffset && !this.pageChangeDetect) {
        if (this.resHeader_filter?.length != 0)
          this.headerFilterImplementation(this.resHeader_filter)
        else
          this.headerFilterImplementation(res?.header_list)
      }

      this.resetOffset = false
      this.checkListElements();
      this.checkChange = true
      this.headerFilterApply()
      this.loader = false;
      this.pageChangeDetect = false
    })
  }

  // Credit Report
  leaveCreditReport() {
    this.loader = true;
    this.reportName = "Leave Credit Report";
    this.appliedFilter = [];
    this.offset = this.resetOffset ? 0 : this.offset
    this.page = this.resetOffset ? 1 : this.page

    this.leaveService.creditReport(this.limit, this.offset, this.ordering, this.filterFormData, this.childFilterSubmit).subscribe((res: any) => {
      if (res?.data?.length != 0) {
        this.dataSource = res?.data
        this.lastPage = res?.count
        // Formating as per standard for dates
        if (this.dataSource?.length > 0) {
          for (let i = 0; i < res.data?.length; i++) {
            this.dataSource[i]['Date of Joining'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Date of Joining']);
            this.dataSource[i]['Leave Type'] = this.dataSource[i]['Leave type']
            this.dataSource[i]['Granted on'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Granted on'])
            this.dataSource[i]['Employee status'] = this.dataSource[i]['Employee Status']
          }
        }

        this.fullData = this.dataSource
        this.dataSource = new MatTableDataSource(this.dataSource);
        this.setDataSourceAttributes();
      }

      // if(this.childFilterSubmit){
        this.resDataFilter     = res?.data_filter;
        this.resHeader_filter  = res?.header_filter;
      // }
      this.defaultHeaderList = res?.header_list?.slice()
      this.hint_dict = res?.hint_dict ? res?.hint_dict : {}

      if (res?.hasOwnProperty('applied_filter')) {
        this.appliedFilter = res?.applied_filter;
        this.dateSetup = res?.applied_filter
        if (res?.applied_filter?.leave_credit_for && res.applied_filter?.leave_credit_for != 'last_month' && res.applied_filter?.leave_credit_for != 'this_month') {
          this.dateSplit = res.applied_filter?.leave_credit_for
          if (this.dateSplit != undefined && this.appliedFilter?.leave_credit_for != 'last_month' && this.appliedFilter?.leave_credit_for != 'this_month')
            this.creditForm.get('leave_credit_for')?.setValue(true);
          this.creditForm.get('from_date')?.setValue(new Date(this.dateSplit[0]))
          this.creditForm.get('to_date')?.setValue(new Date(this.dateSplit[1]))
        } else {
          this.creditForm.get('leave_credit_for')?.reset(res.applied_filter.leave_credit_for)
        }
        if (res?.applied_filter?.leave_credit_for == undefined) {
          this.creditForm.get('leave_credit_for')?.reset('this_month')
        }
      }

      this.columns2 = res['check_list'];
      this.columnOptions = this.columns2;
      this.columnsControl.setValue(this.columnOptions1);
      this.nodata = res?.data?.length == 0 ? true : false;
      this.cd.detectChanges()

      // /**dynamic data table**/
      if (!this.resetOffset && !this.pageChangeDetect) {
        if (this.resHeader_filter?.length != 0)
          this.dynamicHeader(res.data?.length, this.resHeader_filter, res['check_list']);
        else
          this.dynamicHeader(res.data?.length, res['header_list'], res['check_list']);
      }

      /**Filter data**/
      if (this.resDataFilter != null && this.resDataFilter != undefined) {
        if (!!Object.keys(this.resDataFilter)?.length == true) {
          this.creditForm.reset(this.resDataFilter);
          this.OuFilterForm.reset(this.resDataFilter)

          if (this.resDataFilter?.company?.length > 0) {
            this.getBUList();
            if (this.resDataFilter?.bu?.length > 0)
              this.OuFilterForm.get('bu')?.setValue(this.resDataFilter.bu);
          }

          if (this.dateSplit != undefined && this.resDataFilter.leave_credit_for != 'last_month' && this.resDataFilter.leave_credit_for != 'this_month') {
            this.creditForm.get('leave_credit_for')?.setValue(true);
            this.creditForm.get('from_date')?.setValue(new Date(this.dateSplit[0]))
            this.creditForm.get('to_date')?.setValue(new Date(this.dateSplit[1]))
          } else {
            this.creditForm.get('leave_credit_for')?.reset(this.resDataFilter.leave_credit_for);
          }
        }
      }

      /**Header filter data**/
      if (!this.resetOffset && !this.pageChangeDetect) {
        if (this.resHeader_filter?.length != 0)
          this.headerFilterImplementation(this.resHeader_filter)
        else
          this.headerFilterImplementation(res?.header_list)
      }


      this.resetOffset = false
      this.checkListElements();
      this.checkChange = true
      this.headerFilterApply()
      this.loader = false;
      this.pageChangeDetect = false
    })
  }

  // Leave Encashment
  leaveEncashmentReport() {
    this.loader = true;
    this.reportName = "Leave Encashment Report";
    this.appliedFilter = [];
    this.offset = this.resetOffset ? 0 : this.offset
    this.page = this.resetOffset ? 1 : this.page

    this.leaveService.encashmentReport(this.limit, this.offset, this.ordering, this.filterFormData, this.childFilterSubmit).subscribe((res: any) => {
      if (res.data?.length != 0) {
        this.dataSource = res?.data
        this.lastPage = res?.count
        // Formating as per standard for dates
        if (this.dataSource?.length > 0) {
          for (let i = 0; i < res.data?.length; i++) {
            this.dataSource[i]['Date of Joining'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Date of Joining']);
            this.dataSource[i]['Leave Type'] = this.dataSource[i]['Leave type']
            this.dataSource[i]['Employee status'] = this.dataSource[i]['Employee Status']
            this.dataSource[i]['Number of days encashed'] = this.dataSource[i]['Number of days encashed']
          }
        }

        this.fullData = this.dataSource
        this.dataSource = new MatTableDataSource(this.dataSource);
        this.setDataSourceAttributes();
      }

      // if(this.childFilterSubmit){
        this.resDataFilter     = res?.data_filter;
        this.resHeader_filter  = res?.header_filter;
      // }
      this.defaultHeaderList = res?.header_list?.slice()
      this.hint_dict = res?.hint_dict ? res?.hint_dict : {}

      if (res.hasOwnProperty('applied_filter'))
        this.appliedFilter = res.applied_filter;


      this.columns2 = res['check_list'];
      this.columnOptions = this.columns2;
      this.columnsControl.setValue(this.columnOptions1);
      this.nodata = res?.data?.length == 0 ? true : false;
      this.cd.detectChanges()

      // /**dynamic data table**/
      if (!this.resetOffset && !this.pageChangeDetect) {
        if (this.resHeader_filter?.length != 0)
          this.dynamicHeader(res.data?.length, this.resHeader_filter, res['check_list']);
        else
          this.dynamicHeader(res.data?.length, res['header_list'], res['check_list']);
      }


      /**Filter data**/
      if (this.resDataFilter != null && this.resDataFilter != undefined) {
        if (!!Object.keys(this.resDataFilter)?.length == true) {
          this.encashmentForm.reset(this.resDataFilter);
          this.OuFilterForm.reset(this.resDataFilter)

          if (this.resDataFilter?.company?.length > 0) {
            this.getBUList();
            if (this.resDataFilter?.bu?.length > 0)
              this.OuFilterForm.get('bu')?.setValue(this.resDataFilter.bu);
          }
        }
      }

      /**Header filter data**/
      if (!this.resetOffset && !this.pageChangeDetect) {
        if (this.resHeader_filter?.length != 0)
          this.headerFilterImplementation(this.resHeader_filter)
        else
          this.headerFilterImplementation(res?.header_list)
      }

      this.resetOffset = false
      this.checkListElements();
      this.checkChange = true
      this.headerFilterApply()
      this.loader = false;
      this.pageChangeDetect = false
    })
  }

  // Leave Lapsed
  leaveLapsedReport() {
    this.loader = true;
    this.reportName = "Lapsed Leaves Report";
    this.appliedFilter = [];
    this.offset = this.resetOffset ? 0 : this.offset
    this.page = this.resetOffset ? 1 : this.page

    this.leaveService.lapsedReport(this.limit, this.offset, this.ordering, this.filterFormData, this.childFilterSubmit).subscribe((res: any) => {
      if (res.data?.length != 0) {
        this.dataSource = res?.data
        this.lastPage = res?.count

        // Formating as per standard for dates
        if (this.dataSource?.length > 0) {
          for (let i = 0; i < res.data?.length; i++) {
            this.dataSource[i]['Date of Joining'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Date of Joining']);
            this.dataSource[i]['Leave Type'] = this.dataSource[i]['Leave type']
            this.dataSource[i]['Employee status'] = this.dataSource[i]['Employee Status']
            this.dataSource[i]['Lapsed on'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Lapsed on'])
          }
        }

        this.fullData = this.dataSource;
        this.dataSource = new MatTableDataSource(this.dataSource);
        this.setDataSourceAttributes();
      }

      // if(this.childFilterSubmit){
        this.resDataFilter     = res?.data_filter;
        this.resHeader_filter  = res?.header_filter;
      // }
      this.defaultHeaderList = res?.header_list?.slice()
      this.hint_dict = res?.hint_dict ? res?.hint_dict : {}

      if (res.hasOwnProperty('applied_filter'))
        this.appliedFilter = res.applied_filter;

      this.columns2 = res['check_list'];
      this.columnOptions = this.columns2;
      this.columnsControl.setValue(this.columnOptions1);
      this.nodata = res?.data?.length == 0 ? true : false;
      this.cd.detectChanges()

      // /**dynamic data table**/
      if (!this.resetOffset && !this.pageChangeDetect) {
        if (this.resHeader_filter?.length != 0)
          this.dynamicHeader(res.data?.length, this.resHeader_filter, res['check_list']);
        else
          this.dynamicHeader(res.data?.length, res['header_list'], res['check_list']);
      }

      /**Filter data**/
      if (this.resDataFilter != null && this.resDataFilter != undefined) {
        if (!!Object.keys(this.resDataFilter)?.length == true) {
          this.lapsedForm.reset(this.resDataFilter);
          this.OuFilterForm.reset(this.resDataFilter)

          if (this.resDataFilter?.company?.length > 0) {
            this.getBUList();
            if (this.resDataFilter?.bu?.length > 0)
              this.OuFilterForm.get('bu')?.setValue(this.resDataFilter.bu);
          }
        }
      }

      /**Header filter data**/
      if (!this.resetOffset && !this.pageChangeDetect) {
        if (this.resHeader_filter?.length != 0)
          this.headerFilterImplementation(this.resHeader_filter)
        else
          this.headerFilterImplementation(res?.header_list)
      }

      this.resetOffset = false
      this.checkListElements();
      this.checkChange = true
      this.headerFilterApply()
      this.loader = false;
      this.pageChangeDetect = false
    })
  }

  // Leave Negative Report
  leaveNegativeReport() {
    this.loader = true;
    this.reportName = "Negative Leave Balance Report";
    this.appliedFilter = [];
    this.offset = this.resetOffset ? 0 : this.offset
    this.page = this.resetOffset ? 1 : this.page

    this.leaveService.negativeBalanceReport(this.limit, this.offset, this.ordering, this.filterFormData, this.childFilterSubmit).subscribe((res: any) => {
      if (res.data?.length != 0) {
        this.dataSource = res?.data;
        this.lastPage = res?.count

        // Formating as per standard for dates
        if (this.dataSource?.length > 0) {
          for (let i = 0; i < res.data?.length; i++) {
            this.dataSource[i]['Date of Joining'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Date of Joining']);
            this.dataSource[i]['Leave Type'] = this.dataSource[i]['Leave type']
            this.dataSource[i]['Employee status'] = this.dataSource[i]['Employee Status']
          }
        }
        this.fullData = this.dataSource
        this.dataSource = new MatTableDataSource(this.dataSource);
        this.setDataSourceAttributes();
      }

      // if(this.childFilterSubmit){
        this.resDataFilter     = res?.data_filter;
        this.resHeader_filter  = res?.header_filter;
      // }
      this.defaultHeaderList = res?.header_list?.slice()
      this.hint_dict = res?.hint_dict ? res?.hint_dict : {}

      if (res.hasOwnProperty('applied_filter'))
        this.appliedFilter = res.applied_filter;

      this.columns2 = res['check_list'];
      this.columnOptions = this.columns2;
      this.columnsControl.setValue(this.columnOptions1);
      this.nodata = res?.data?.length == 0 ? true : false;
      this.cd.detectChanges()

      // /**dynamic data table**/
      if (!this.resetOffset && !this.pageChangeDetect) {
        if (this.resHeader_filter?.length != 0)
          this.dynamicHeader(res.data?.length, this.resHeader_filter, res['check_list']);
        else
          this.dynamicHeader(res.data?.length, res['header_list'], res['check_list']);
      }

      /**Filter data**/
      if (this.resDataFilter != null && this.resDataFilter != undefined) {
        if (!!Object.keys(this.resDataFilter)?.length == true) {
          this.negativeForm.reset(this.resDataFilter);
          this.OuFilterForm.reset(this.resDataFilter)

          if (this.resDataFilter?.company?.length > 0) {
            this.getBUList();
            if (this.resDataFilter?.bu?.length > 0)
              this.OuFilterForm.get('bu')?.setValue(this.resDataFilter.bu);
          }

          if (this.negativeForm.get('selected_date')?.value == null) {
            this.negativeForm.get('selected_date')?.reset(new Date())
          } else {
            this.negativeForm.get('selected_date')?.reset(this.resDataFilter.selected_date)
          }
        }
      }

      /**Header filter data**/
      if (!this.resetOffset && !this.pageChangeDetect) {
        if (this.resHeader_filter?.length != 0)
          this.headerFilterImplementation(this.resHeader_filter)
        else
          this.headerFilterImplementation(res?.header_list)
      }

      this.resetOffset = false
      this.checkListElements();
      this.checkChange = true
      this.headerFilterApply()
      this.loader = false;
      this.pageChangeDetect = false
    })
  }

  // LOP Report
  leaveLOPReport() {
    this.loader = true;
    this.reportName = "Unpaid Leave Report";
    this.appliedFilter = [];
    this.offset = this.resetOffset ? 0 : this.offset
    this.page = this.resetOffset ? 1 : this.page

    this.leaveService.lopReport(this.limit, this.offset, this.ordering, this.filterFormData, this.childFilterSubmit).subscribe((res: any) => {
      if (res.data?.length != 0) {
        this.dataSource = res?.data
        this.lastPage = res?.count
        // Formating as per standard for dates
        if (this.dataSource?.length > 0) {
          for (let i = 0; i < res.data?.length; i++) {
            this.dataSource[i]['Date of Joining'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Date of Joining']);
            this.dataSource[i]['Leave Type'] = this.dataSource[i]['Leave type']
            this.dataSource[i]['Employee status'] = this.dataSource[i]['Employee Status']
            this.dataSource[i]['Lapsed on'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Lapsed on'])
            this.dataSource[i]['Leave policy'] = this.dataSource[i]['Leave policy']
          }
        }
        this.fullData = this.dataSource;
        this.dataSource = new MatTableDataSource(this.dataSource);
        // this.fullData         = this.dataSource?.filteredData;

        this.setDataSourceAttributes();
      }

      // if(this.childFilterSubmit){
        this.resDataFilter     = res?.data_filter;
        this.resHeader_filter  = res?.header_filter;
      // }
      this.defaultHeaderList = res?.header_list?.slice()
      this.hint_dict = res?.hint_dict ? res?.hint_dict : {}


      if (res.hasOwnProperty('applied_filter')) {
        this.appliedFilter = res.applied_filter;
        this.dateSetup = res.applied_filter
        //  this.OuFilterForm.reset(res.applied_filter)
        if (res.applied_filter?.transaction_date && res.applied_filter?.transaction_date != 'last_month' && res.applied_filter?.transaction_date != 'this_month')
          this.dateSplit = res.applied_filter?.transaction_date

        if (this.dateSplit != undefined && this.appliedFilter?.transaction_date != 'last_month' && this.appliedFilter?.transaction_date != 'this_month') {
          this.lopForm.get('transaction_date')?.setValue(true);
          this.lopForm.get('from_date')?.setValue(new Date(this.dateSplit[0]))
          this.lopForm.get('to_date')?.setValue(new Date(this.dateSplit[1]))
        } else {
          this.lopForm.get('transaction_date')?.reset(res.applied_filter.transaction_date)
        }
        if (res?.applied_filter?.transaction_date == undefined) {
          this.lopForm.get('transaction_date')?.reset('this_month')
        }
      }

      this.columns2 = res['check_list'];
      this.columnOptions = this.columns2;
      this.columnsControl.setValue(this.columnOptions1);
      this.nodata = res?.data?.length == 0 ? true : false;
      this.cd.detectChanges()

      // /**dynamic data table**/
      if (!this.resetOffset && !this.pageChangeDetect) {
        if (this.resHeader_filter?.length != 0)
          this.dynamicHeader(res.data?.length, this.resHeader_filter, res['check_list']);
        else
          this.dynamicHeader(res.data?.length, res['header_list'], res['check_list']);
      }

      /**Filter data**/
      if (this.resDataFilter != null && this.resDataFilter != undefined) {
        if (!!Object.keys(this.resDataFilter)?.length == true) {
          this.lopForm.reset(this.resDataFilter);
          this.OuFilterForm.reset(this.resDataFilter)

          if (this.resDataFilter?.company?.length > 0) {
            this.getBUList();
            if (this.resDataFilter?.bu?.length > 0)
              this.OuFilterForm.get('bu')?.setValue(this.resDataFilter.bu);
          }

          // Setting for the specific month to be active if no other option is selected
          if (this.dateSplit != undefined && this.resDataFilter.transaction_date != 'last_month' && this.resDataFilter.transaction_date != 'this_month') {
            this.lopForm.get('transaction_date')?.setValue(true);
            this.lopForm.get('from_date')?.setValue(new Date(this.dateSplit[0]))
            this.lopForm.get('to_date')?.setValue(new Date(this.dateSplit[1]))
          } else {
            this.lopForm.get('transaction_date')?.reset(this.resDataFilter.transaction_date);
          }
        }
      }

      /**Header filter data**/
      if (!this.resetOffset && !this.pageChangeDetect) {
        if (this.resHeader_filter?.length != 0)
          this.headerFilterImplementation(this.resHeader_filter)
        else
          this.headerFilterImplementation(res?.header_list)
      }

      this.resetOffset = false
      this.checkListElements();
      this.checkChange = true
      this.headerFilterApply()
      this.loader = false;
      this.pageChangeDetect = false
    })
  }

  // Leave Balance Summary
  leaveBalanceReport() {
    this.loader = true;
    this.reportName = "Leave Balance Summary Report";
    this.appliedFilter = [];
    this.offset = this.resetOffset ? 0 : this.offset
    this.page = this.resetOffset ? 1 : this.page

    this.leaveService.balanceReport(this.limit, this.offset, this.ordering, this.filterFormData, this.childFilterSubmit).subscribe((res: any) => {
      if (res.data?.length != 0) {
        this.dataSource = res?.data
        this.lastPage = res?.count

        // Formating as per standard for dates
        if (this.dataSource?.length > 0) {
          for (let i = 0; i < res.data?.length; i++) {
            this.dataSource[i]['Date of Joining'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Date of Joining']);
            this.dataSource[i]['Leave Type'] = this.dataSource[i]['Leave name']
            this.dataSource[i]['Employee status'] = this.dataSource[i]['Employee Status']
            this.dataSource[i]['Lapsed on'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Lapsed on'])
            this.dataSource[i]['Leave policy'] = this.dataSource[i]['Leave policy']
            this.dataSource[i]['Last updated on'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Last updated on'])
          }
        }

        this.fullData = this.dataSource
        this.dataSource = new MatTableDataSource(this.dataSource);
        this.setDataSourceAttributes();
      }

      // if(this.childFilterSubmit){
        this.resDataFilter     = res?.data_filter;
        this.resHeader_filter  = res?.header_filter;
      // }
      this.defaultHeaderList = res?.header_list?.slice()
      this.hint_dict = res?.hint_dict ? res?.hint_dict : {}

      if (res.hasOwnProperty('applied_filter'))
        this.appliedFilter = res.applied_filter;
      this.dateSetup = res.applied_filter
      this.dateSplit = res.applied_filter?.leave_period
      if (res.applied_filter?.leave_period == undefined) {
        this.balanceForm.get('from_date')?.setValue('')
        this.balanceForm.get('to_date')?.setValue('')
      } else {
        this.balanceForm.get('from_date')?.setValue(new Date(this.dateSplit[0]))
        this.balanceForm.get('to_date')?.setValue(new Date(this.dateSplit[1]))
      }
      this.columns2 = res['check_list'];
      this.columnOptions = this.columns2;
      this.columnsControl.setValue(this.columnOptions1);
      this.nodata = res?.data?.length == 0 ? true : false;
      this.cd.detectChanges()

      // /**dynamic data table**/
      if (!this.resetOffset && !this.pageChangeDetect) {
        if (this.resHeader_filter?.length != 0)
          this.dynamicHeader(res.data?.length, this.resHeader_filter, res['check_list']);
        else
          this.dynamicHeader(res.data?.length, res['header_list'], res['check_list']);
      }


      /**Filter data**/
      if (this.resDataFilter != null && this.resDataFilter != undefined) {
        if (!!Object.keys(this.resDataFilter)?.length == true) {
          this.balanceForm.reset(this.resDataFilter);
          this.OuFilterForm.reset(this.resDataFilter)

          if (this.resDataFilter?.company?.length > 0) {
            this.getBUList();
            if (this.resDataFilter?.bu?.length > 0)
              this.OuFilterForm.get('bu')?.setValue(this.resDataFilter.bu);
          }
          if (this.dateSplit != undefined) {
            this.balanceForm.get('from_date')?.setValue(new Date(this.dateSplit[0]))
            this.balanceForm.get('to_date')?.setValue(new Date(this.dateSplit[1]))
          }
        }
      }

      /**Header filter data**/
      if (!this.resetOffset && !this.pageChangeDetect) {
        if (this.resHeader_filter?.length != 0)
          this.headerFilterImplementation(this.resHeader_filter)
        else
          this.headerFilterImplementation(res?.header_list)
      }

      this.resetOffset = false
      this.checkListElements();
      this.checkChange = true
      this.headerFilterApply()
      this.loader = false;
      this.pageChangeDetect = false
    })
  }

  // Leave Ledger Report
  leaveLedgerReport() {
    this.loader = true;
    this.reportName = "Leave Ledger Report";
    this.appliedFilter = [];
    this.offset = this.resetOffset ? 0 : this.offset
    this.page = this.resetOffset ? 1 : this.page

    this.leaveService.ledgerReport(this.limit, this.offset, this.ordering, this.filterFormData, this.childFilterSubmit).subscribe((res: any) => {
      if (res.data?.length != 0) {
        this.dataSource = res?.data
        this.lastPage = res?.count

        // Formating as per standard for dates
        if (this.dataSource?.length > 0) {
          for (let i = 0; i < res.data?.length; i++) {
            this.dataSource[i]['Date of Joining'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Date of Joining']);
            this.dataSource[i]['Transaction date'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Transaction date']);
            this.dataSource[i]['Leave Type'] = this.dataSource[i]['Leave type']
            this.dataSource[i]['Employee status'] = this.dataSource[i]['Employee Status']
            this.dataSource[i]['Leave policy'] = this.dataSource[i]['Leave policy']
            this.dataSource[i]['Opening balance'] = this.dataSource[i]['Opening_balance']
            this.dataSource[i]['Closing Balance'] = this.dataSource[i]['Closing balance']
          }
        }

        this.fullData = this.dataSource
        this.dataSource = new MatTableDataSource(this.dataSource);
        this.setDataSourceAttributes();
      }

      // if(this.childFilterSubmit){
        this.resDataFilter     = res?.data_filter;
        this.resHeader_filter  = res?.header_filter;
      // }
      this.defaultHeaderList = res?.header_list?.slice()
      this.hint_dict = res?.hint_dict ? res?.hint_dict : {}

      if (res.hasOwnProperty('applied_filter')) {
        this.appliedFilter = res.applied_filter;
        this.dateSetup = res.applied_filter
        if (res.applied_filter?.transaction_date && res.applied_filter?.transaction_date != 'last_month' && res.applied_filter?.transaction_date != 'this_month')
          this.dateSplit = res.applied_filter?.transaction_date
        if (this.dateSplit != undefined && res.applied_filter?.transaction_date != 'last_month' && res.applied_filter?.transaction_date != 'this_month') {
          this.ledgerForm.get('transaction_date')?.setValue(true);
          this.ledgerForm.get('trans_from_date')?.setValue(new Date(this.dateSplit[0]))
          this.ledgerForm.get('trans_to_date')?.setValue(new Date(this.dateSplit[1]))
        } else {
          this.ledgerForm.get('transaction_date')?.reset(res.applied_filter?.transaction_date)
        }
        if (res?.applied_filter?.transaction_date == undefined) {
          this.ledgerForm.get('transaction_date')?.reset('this_month')
        }
      }

      this.columns2 = res['check_list'];
      this.columnOptions = this.columns2;
      this.columnsControl.setValue(this.columnOptions1);
      this.nodata = res?.data?.length == 0 ? true : false;
      this.cd.detectChanges()

      // /**dynamic data table**/
      if (!this.resetOffset && !this.pageChangeDetect) {
        if (this.resHeader_filter?.length != 0)
          this.dynamicHeader(res.data?.length, this.resHeader_filter, res['check_list']);
        else
          this.dynamicHeader(res.data?.length, res['header_list'], res['check_list']);
      }


      /**Filter data**/
      if (this.resDataFilter != null && this.resDataFilter != undefined) {
        if (!!Object.keys(this.resDataFilter)?.length == true) {
          this.ledgerForm.reset(this.resDataFilter);
          this.OuFilterForm.reset(this.resDataFilter)

          if (this.resDataFilter?.company?.length > 0) {
            this.getBUList();
            if (this.resDataFilter?.bu?.length > 0)
              this.OuFilterForm.get('bu')?.setValue(this.resDataFilter.bu);
          }

          if (this.resDataFilter.transaction_date != 'last_month' && this.resDataFilter.transaction_date != 'this_month') {
            this.ledgerForm.get('transaction_date')?.setValue(true);
            this.ledgerForm.get('trans_from_date')?.setValue(new Date(this.dateSplit[0]))
            this.ledgerForm.get('trans_to_date')?.setValue(new Date(this.dateSplit[1]))
          } else {
            this.ledgerForm.get('transaction_date')?.reset(this.resDataFilter.transaction_date);
          }
        }
      }

      /**Header filter data**/
      if (!this.resetOffset && !this.pageChangeDetect) {
        if (this.resHeader_filter?.length != 0)
          this.headerFilterImplementation(this.resHeader_filter)
        else
          this.headerFilterImplementation(res?.header_list)
      }

      this.resetOffset = false
      this.checkListElements();
      this.checkChange = true
      this.headerFilterApply()
      this.loader = false;
      this.pageChangeDetect = false
    })
  }

  // Leave Request
  leaveRequest() {
    this.loader = true;
    this.reportName = "Leave Request Status Report";
    this.appliedFilter = [];
    this.offset = this.resetOffset ? 0 : this.offset
    this.page = this.resetOffset ? 1 : this.page

    this.leaveService.requestReport(this.limit, this.offset, this.ordering, this.filterFormData, this.childFilterSubmit).subscribe((res: any) => {
      if (res.data?.length != 0) {
        this.dataSource = res?.data
        this.lastPage = res?.count

        // Formating as per standard for dates
        if (this.dataSource?.length > 0) {
          for (let i = 0; i < res.data?.length; i++) {
            this.dataSource[i]['Date of Joining'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Date of Joining']);
            this.dataSource[i]['Leave Type'] = this.dataSource[i]['Leave type']
            this.dataSource[i]['Employee status'] = this.dataSource[i]['Employee Status']
            this.dataSource[i]['Leave policy'] = this.dataSource[i]['Leave policy']
            this.dataSource[i]['Applied on'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Applied on']);
            this.dataSource[i]['Approved on'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Approved on'])
            this.dataSource[i]['Rejected on'] = this.appservice.dateFormatDisplay(this.dataSource[i]['Rejected on'])
            this.dataSource[i]['From date'] = this.appservice.dateFormatDisplay(this.dataSource[i]['from_date'])
            this.dataSource[i]['To date'] = this.appservice.dateFormatDisplay(this.dataSource[i]['to_date'])
          }
        }

        this.fullData = this.dataSource
        this.dataSource = new MatTableDataSource(this.dataSource);
        this.setDataSourceAttributes();
      }

      // if(this.childFilterSubmit){
        this.resDataFilter     = res?.data_filter;
        this.resHeader_filter  = res?.header_filter;
      // }
      this.defaultHeaderList = res?.header_list?.slice()
      this.hint_dict = res?.hint_dict ? res?.hint_dict : {}

      if (res.hasOwnProperty('applied_filter')) {
        this.appliedFilter = res.applied_filter;
        this.dateSetup = res?.applied_filter
        if (res.applied_filter?.leave_period && res.applied_filter?.leave_period != 'last_month' && res.applied_filter?.leave_period != 'this_month')
          this.dateSplit = res.applied_filter?.leave_period
        if (this.dateSplit != undefined && res.applied_filter?.leave_period != 'last_month' && res.applied_filter?.leave_period != 'this_month') {
          this.requestForm.get('leave_period')?.setValue(true);
          this.requestForm.get('from_date')?.setValue(new Date(this.dateSplit[0]))
          this.requestForm.get('to_date')?.setValue(new Date(this.dateSplit[1]))
        } else {
          this.requestForm.get('leave_period')?.reset(res.applied_filter.leave_period)
        }
        if (res.applied_filter?.leave_period == undefined) {
          this.requestForm.get('leave_period')?.reset('this_month')
        }
        if (this.appliedFilter?.status) {
          this.requestForm.get('status')?.reset(res?.applied_filter?.status)
        }
      }

      this.columns2 = res['check_list'];
      this.columnOptions = this.columns2;
      this.columnsControl.setValue(this.columnOptions1);
      this.nodata = res?.data?.length == 0 ? true : false;
      this.cd.detectChanges()

      // /**dynamic data table**/
      if (!this.resetOffset && !this.pageChangeDetect) {
        if (this.resHeader_filter?.length != 0)
          this.dynamicHeader(res.data?.length, this.resHeader_filter, res['check_list']);
        else
          this.dynamicHeader(res.data?.length, res['header_list'], res['check_list']);
      }

      /**Filter data**/
      if (this.resDataFilter != null && this.resDataFilter != undefined) {
        if (!!Object.keys(this.resDataFilter)?.length == true) {
          this.requestForm.reset(this.resDataFilter);
          this.OuFilterForm.reset(this.resDataFilter)

          if (this.resDataFilter?.company?.length > 0) {
            this.getBUList();
            if (this.resDataFilter?.bu?.length > 0)
              this.requestForm.get('bu')?.setValue(this.resDataFilter.bu);
          }

          // Setting for the specific month to be active if no other option is selected
          if (this.resDataFilter?.company?.length > 0) {
            this.getBUList();
            if (this.resDataFilter?.bu?.length > 0)
              this.OuFilterForm.get('bu')?.setValue(this.resDataFilter.bu);
          }

          if (this.resDataFilter.leave_period != 'last_month' && this.resDataFilter.leave_period != 'this_month') {
            this.requestForm.get('leave_period')?.setValue(true);
            this.requestForm.get('from_date')?.setValue(new Date(this.dateSplit[0]))
            this.requestForm.get('to_date')?.setValue(new Date(this.dateSplit[1]))
          } else {
            this.requestForm.get('leave_period')?.reset(this.resDataFilter.leave_period);
          }

          if (this.resDataFilter?.Status) {
            this.requestForm.get('status')?.reset(this.resDataFilter?.Status)
          }
        }
      }

      /**Header filter data**/
      if (!this.resetOffset && !this.pageChangeDetect) {
        if (this.resHeader_filter?.length != 0)
          this.headerFilterImplementation(this.resHeader_filter)
        else
          this.headerFilterImplementation(res?.header_list)
      }

      this.resetOffset = false
      this.checkListElements();
      this.checkChange = true
      this.headerFilterApply()
      this.loader = false;
      this.pageChangeDetect = false
    })
  }

  // Submit after filter applied, getting value from child
  submitDone(value:any){
    if(this.callReportVal && Object.keys(this.resDataFilter)?.length > 0){
      this.filterMemorizeFunction('saveFilter','')
    }else{
      this.callReportVal = false
    }
    this.limit = value.limit
    this.offset = value.offset
    this.filterFormData = value.filterForm
    this.filterSubmit = !this.filterFormData?.split('&').every((data:any)=>{
      const [key, value] = data?.split('=')
      if(key === 'ordering'){
        return true;
      }
      return (value === '[]' || value === '')
    })
    this.childFilterSubmit = false
    this.page = 1
    this.pageNumber = 0
    this.resetOffset = true
    // this.callReportVal = false
    this.callAllReportsFunctions()
    this.pageChangeDetect = false
    this.viewDetail = false
  }


  //  Header Filter Add
  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  setDataSourceAttributes() {
    if (this.dataSource != undefined && this.dataSource != null) {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

  checkChange: boolean = false
  headerFilterAdd(e: any, index: any) {
    this.checkChange = true
    this.columnApplied = true
    this.header_filter = this.dupliCateRemove(this.header_filter)
    if (e.target.checked) {
      this.submitted = true;
      this.checkColumn.push(e.target.value);
      this.header_filter.push(e.target.value);
    }
    else {
      if (this.header_filter.length == 1) {
        var elements = document.getElementsByName("check");
        setTimeout(() => {

          var element = <HTMLInputElement>elements[index];
          element.checked = true;

        })
      }
      else {
        this.checkColumn = this.dupliCateRemove(this.checkColumn)
        const index: number = this.checkColumn.indexOf(e.target.value);
        const header_index: number = this.header_filter.indexOf(e.target.value);
        const column_1: number = this.columns1.indexOf(e.target.value);
        this.checkColumn.splice(index, 1);
        this.header_filter.splice(header_index, 1);
        this.columns1.splice(column_1, 1);
      }
    }
  }

  columnApplied: boolean = false

  headerFilterApply() {
    this.columnsSave = this.displayedColumns
    if (!this.checkChange) {
      return
    }
    let array3 = this.columns1?.concat(this.checkColumn);
    array3 = array3?.filter((item: any, index: any) => {
      return (array3.indexOf(item) == index)
    })
    if(this.columnChangedArr?.length !=0 ){
      array3 = this.reorderArray(this.columnChangedArr,array3)
    }
    this.displayedColumns = array3;
    this.columnsArr = this.displayedColumns;
    if (this.checkColumn?.length > 0 && this.columnApplied)
      this.checkFilterSubmit = true;
    else
      this.checkFilterSubmit = false;

    // this.checkFilterSubmit  = true;
    this.checkChange = false
  }

  resetFilterFunction(data: any) {
    this.resetReportName = data;
    if (this.resDataFilter != undefined && this.resDataFilter != null && this.resDataFilter != '') {
      if (!!Object.keys(this.resDataFilter)?.length == true) {
        this.resetFilter = true
      } else {
        this.resetFilterControllers('reset');
      }
    }
  }

  resetColumnFilterFunction() {
    if (this.resHeader_filter?.length != 0) {
      this.is_colfilter = true;
      this.resetFilter = true
    } else {
      this.resetAllUnDefaultColumns();
    }
    this.columnChangedArr = []
  }

  arrayAreSame(res1: any, res2: any) {
    if (res1.length !== res2.length) {
      return false
    }
    for (let i = 0; i < res1.length; i++) {
      if (res1[i] !== res2[i]) {
        return false
      }
    }
    return true
  }

  reportMemorizeFunction() {
    if (this.reportId != undefined && this.reportId != null) {
      if (this.scheduleReportFilterMemorizeForm.value.filter == false) {
        if (this.scheduleText == 'Save filters and schedule the report') {
          this.filterMemorizeFunction('saveFilter', 'saveAndSchedule');
        } else {
          this.router.navigate(['/scheduled-reports/reports-setup/' + this.reportId + '/true']);
        }
      } else {
        this.router.navigate(['/scheduled-reports/reports-setup/' + this.reportId + '/false']);
      }
    }
  }

  disableDate() {
    return false;
  }

  confirm(): boolean {
    if (this.isFilter) {
      return true;
    }
    else {
      // boolean value from child
      if (this.filterSubmit == true || this.checkFilterSubmit == true) {
        this.applyFilter = true;
        this.filterSubmit = false;
        this.checkFilterSubmit = false;

        return false;
      } else {
        this.applyFilter = false;
        return true;
      }
    }

  }
  public isChangesSaved() {
    if (this.submitted == true) {
      return confirm("Do you want to discard changes?")
    }
    return true;
  }

  extremeLimit: boolean = false

  // File Export
  export() {
    let limit = this.lastPage
    let offset = 0
    this.fullData = []
    this.loader = true
    if (this.reportName == 'Leave Balance On A Particular Day') {
      this.leaveService.balanceParticularDay(limit, offset, this.ordering, this.filterFormData, this.childFilterSubmit).subscribe((res: any) => {
        if (res?.data?.length != 0) {
          this.fullData = res?.data;
          this.lastPage = res?.count
          // Formating as per standard for dates
          if (this.fullData?.length > 0) {
            for (let i = 0; i < res.data.data?.length; i++) {
              this.fullData[i]['Date of Joining'] = this.appservice.dateFormatDisplay(this.fullData[i]['Date of Joining']);
              this.fullData[i]['Leave Type'] = this.fullData[i]['Leave type']
              this.fullData[i]['Employment status'] = this.fullData[i]['Employee Status']
            }
          }
        }
        this.commonLogic(this.fullData)
      })
    }
    if (this.reportName == 'Leave Carry Forward Report') {
      this.leaveService.carryForwardReport(limit, offset, this.ordering, this.filterFormData, this.childFilterSubmit).subscribe((res: any) => {
        if (res.data?.length != 0) {
          this.fullData = res?.data
          this.lastPage = res?.count
          // Formating as per standard for dates
          if (this.fullData?.length > 0) {
            for (let i = 0; i < res.data?.length; i++) {
              this.fullData[i]['Date of Joining'] = this.appservice.dateFormatDisplay(this.fullData[i]['Date of Joining']);
              this.fullData[i]['Leave Type'] = this.fullData[i]['Leave type']
              this.fullData[i]['Employee status'] = this.fullData[i]['Employee Status']
            }
          }
        }
        this.commonLogic(this.fullData)
      })
    }
    if (this.reportName == 'Leave Availed Report') {
      this.leaveService.availedReport(limit, offset, this.ordering, this.filterFormData, this.childFilterSubmit).subscribe((res: any) => {
        if (res.data?.length != 0) {
          this.fullData = res?.data
          this.lastPage = res?.count
          // Formating as per standard for dates
          if (this.fullData?.length > 0) {
            for (let i = 0; i < res.data?.length; i++) {
              this.fullData[i]['Date of Joining'] = this.appservice.dateFormatDisplay(this.fullData[i]['Date of Joining']);
              this.fullData[i]['Applied on'] = this.appservice.dateFormatDisplay(this.fullData[i]['Applied on']);
              this.fullData[i]['Availed From'] = this.appservice.dateFormatDisplay(this.fullData[i]['Availed From']);
              this.fullData[i]['Availed To'] = this.appservice.dateFormatDisplay(this.fullData[i]['Availed To']);
              this.fullData[i]['Approved on'] = this.appservice.dateFormatDisplay(this.fullData[i]['Approved on'])
              this.fullData[i]['Leave Type'] = this.fullData[i]['Leave type']
              this.fullData[i]['Employee status'] = this.fullData[i]['Employee Status']
            }
          }
        }
        this.commonLogic(this.fullData)
      })
    }
    if (this.reportName == 'Leave Credit Report') {
      this.leaveService.creditReport(limit, offset, this.ordering, this.filterFormData, this.childFilterSubmit).subscribe((res: any) => {
        if (res.data?.length != 0) {
          this.fullData = res?.data
          this.lastPage = res?.count
          // Formating as per standard for dates
          if (this.fullData?.length > 0) {
            for (let i = 0; i < res.data?.length; i++) {
              this.fullData[i]['Date of Joining'] = this.appservice.dateFormatDisplay(this.fullData[i]['Date of Joining']);
              this.fullData[i]['Leave Type'] = this.fullData[i]['Leave type']
              this.fullData[i]['Leave policy'] = this.fullData[i]['Leave policy'][0]
              this.fullData[i]['Granted on'] = this.appservice.dateFormatDisplay(this.fullData[i]['Granted on'])
              this.fullData[i]['Employee status'] = this.fullData[i]['Employee Status']
            }
          }
        }
        this.commonLogic(this.fullData)
      })
    }
    if (this.reportName == 'Leave Encashment Report') {
      this.leaveService.encashmentReport(limit, offset, this.ordering, this.filterFormData, this.childFilterSubmit).subscribe((res: any) => {
        if (res.data?.length != 0) {
          this.fullData = res?.data
          this.lastPage = res?.count
          // Formating as per standard for dates
          if (this.fullData?.length > 0) {
            for (let i = 0; i < res.data?.length; i++) {
              this.fullData[i]['Date of Joining'] = this.appservice.dateFormatDisplay(this.fullData[i]['Date of Joining']);
              this.fullData[i]['Leave Type'] = this.fullData[i]['Leave type']
              this.fullData[i]['Employee status'] = this.fullData[i]['Employee Status']
              this.fullData[i]['Number of days encashed'] = this.fullData[i]['Number of days encashed']
            }
          }
        }
        this.commonLogic(this.fullData)
      })
    }
    if (this.reportName == 'Lapsed Leaves Report') {
      this.leaveService.lapsedReport(limit, offset, this.ordering, this.filterFormData, this.childFilterSubmit).subscribe((res: any) => {
        if (res.data?.length != 0) {
          this.fullData = res?.data
          this.lastPage = res?.count
          if (this.fullData?.length > 0) {
            for (let i = 0; i < res.data?.length; i++) {
              this.fullData[i]['Date of Joining'] = this.appservice.dateFormatDisplay(this.fullData[i]['Date of Joining']);
              this.fullData[i]['Leave Type'] = this.fullData[i]['Leave type']
              this.fullData[i]['Employee status'] = this.fullData[i]['Employee Status']
              this.fullData[i]['Lapsed on'] = this.appservice.dateFormatDisplay(this.fullData[i]['Lapsed on'])
              this.fullData[i]['Leave policy'] = this.fullData[i]['Leave policy'][0]
            }
          }
        }
        this.commonLogic(this.fullData)
      })
    }
    if (this.reportName == 'Negative Leave Balance Report') {
      this.leaveService.negativeBalanceReport(limit, offset, this.ordering, this.filterFormData, this.childFilterSubmit).subscribe((res: any) => {
        if (res.data?.length != 0) {
          this.fullData = res?.data;
          this.lastPage = res?.count

          // Formating as per standard for dates
          if (this.fullData?.length > 0) {
            for (let i = 0; i < res.data?.length; i++) {
              this.fullData[i]['Date of Joining'] = this.appservice.dateFormatDisplay(this.fullData[i]['Date of Joining']);
              this.fullData[i]['Leave Type'] = this.fullData[i]['Leave type']
              this.fullData[i]['Employee status'] = this.fullData[i]['Employee Status']
            }
          }
        }
        this.commonLogic(this.fullData)
      })
    }
    if (this.reportName == 'Unpaid Leave Report') {
      this.leaveService.lopReport(limit, offset, this.ordering, this.filterFormData, this.childFilterSubmit).subscribe((res: any) => {
        if (res.data?.length != 0) {
          this.fullData = res?.data
          this.lastPage = res?.count
          // Formating as per standard for dates
          if (this.fullData?.length > 0) {
            for (let i = 0; i < res.data?.length; i++) {
              this.fullData[i]['Date of Joining'] = this.appservice.dateFormatDisplay(this.fullData[i]['Date of Joining']);
              this.fullData[i]['Leave Type'] = this.fullData[i]['Leave type']
              this.fullData[i]['Employee status'] = this.fullData[i]['Employee Status']
              this.fullData[i]['Lapsed on'] = this.appservice.dateFormatDisplay(this.fullData[i]['Lapsed on'])
              this.fullData[i]['Leave policy'] = this.fullData[i]['Leave policy']
            }
          }
        }
        this.commonLogic(this.fullData)
      })
    }
    if (this.reportName == 'Leave Balance Summary Report') {
      this.leaveService.balanceReport(limit, offset, this.ordering, this.filterFormData, this.checkFilterSubmit).subscribe((res: any) => {
        if (res.data?.length != 0) {
          this.fullData = res?.data
          this.lastPage = res?.count

          // Formating as per standard for dates
          if (this.fullData?.length > 0) {
            for (let i = 0; i < res.data?.length; i++) {
              this.fullData[i]['Date of Joining'] = this.appservice.dateFormatDisplay(this.fullData[i]['Date of Joining']);
              this.fullData[i]['Leave Type'] = this.fullData[i]['Leave name']
              this.fullData[i]['Employee status'] = this.fullData[i]['Employee Status']
              this.fullData[i]['Lapsed on'] = this.appservice.dateFormatDisplay(this.fullData[i]['Lapsed on'])
              this.fullData[i]['Leave policy'] = this.fullData[i]['Leave policy']
              this.fullData[i]['Last updated on'] = this.appservice.dateFormatDisplay(this.fullData[i]['Last updated on'])
            }
          }
        }
        this.commonLogic(this.fullData)
      })
    }
    if (this.reportName == 'Leave Ledger Report') {
      this.leaveService.ledgerReport(limit, offset, this.ordering, this.filterFormData, this.childFilterSubmit).subscribe((res: any) => {
        if (res.data?.length != 0) {
          this.fullData = res?.data
          this.lastPage = res?.count

          // Formating as per standard for dates
          if (this.fullData?.length > 0) {
            for (let i = 0; i < res.data?.length; i++) {
              this.fullData[i]['Date of Joining'] = this.appservice.dateFormatDisplay(this.fullData[i]['Date of Joining']);
              this.fullData[i]['Transaction date'] = this.appservice.dateFormatDisplay(this.fullData[i]['Transaction date']);
              this.fullData[i]['Leave Type'] = this.fullData[i]['Leave type']
              this.fullData[i]['Employee status'] = this.fullData[i]['Employee Status']
              this.fullData[i]['Leave policy'] = this.fullData[i]['Leave policy']
              this.fullData[i]['Opening balance'] = this.fullData[i]['Opening_balance']
              this.fullData[i]['Closing Balance'] = this.fullData[i]['Closing balance']
            }
          }
        }
        this.commonLogic(this.fullData)
      })
    }
    if (this.reportName == 'Leave Request Status Report') {
      this.leaveService.requestReport(limit, offset, this.ordering, this.filterFormData, this.childFilterSubmit).subscribe((res: any) => {
        if (res.data?.length != 0) {
          this.fullData = res?.data
          this.lastPage = res?.count

          // Formating as per standard for dates
          if (this.fullData?.length > 0) {
            for (let i = 0; i < res.data?.length; i++) {
              this.fullData[i]['Date of Joining'] = this.appservice.dateFormatDisplay(this.fullData[i]['Date of Joining']);
              this.fullData[i]['Leave Type'] = this.fullData[i]['Leave type']
              this.fullData[i]['Employee status'] = this.fullData[i]['Employee Status']
              this.fullData[i]['Leave policy'] = this.fullData[i]['Leave policy']
              this.fullData[i]['Applied on'] = this.appservice.dateFormatDisplay(this.fullData[i]['Applied on']);
              this.fullData[i]['Approved on'] = this.appservice.dateFormatDisplay(this.fullData[i]['Approved on'])
              this.fullData[i]['Rejected on'] = this.appservice.dateFormatDisplay(this.fullData[i]['Rejected on'])
              this.fullData[i]['From date'] = this.appservice.dateFormatDisplay(this.fullData[i]['from_date'])
              this.fullData[i]['To date'] = this.appservice.dateFormatDisplay(this.fullData[i]['to_date'])
            }
          }
        }
        this.commonLogic(this.fullData)
      })
    }
  }

  commonLogic(data: any) {
    let column = this.displayedColumns;
    const newArray = [];
    for (const obj of data) {
      const newObject: any = {};
      for (const key of column) {
        if (obj.hasOwnProperty(key)) {
          newObject[key] = obj[key];
        }
      }
      newArray.push(newObject);
    }

    if (data != undefined)
      this.exportExcel(newArray, this.reportName);
    this.loader = false
    this.extremeLimit = false
    this.cd.detectChanges()
  }

  public exportExcel(jsonData: any[], fileName: string): void {

    if (!this.nodata) {
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonData);
      const wb: XLSX.WorkBook = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      this.saveExcelFile(excelBuffer, fileName);
    }
    else {
      for (let i = 0; i < this.displayedColumns.length; i++) {
        this.excelHeaders.push(this.displayedColumns[i])
        this.templateToExcel = [this.excelHeaders, []];
      }
      const wss: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.templateToExcel);
      const wbb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wbb, wss, 'Sheet1');
      XLSX.writeFile(wbb, fileName + this.fileExtension)
    }
  }

  private saveExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: this.fileType });
    FileSaver.saveAs(data, fileName + this.fileExtension);
  }

  // Table COlumn Drop
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.displayedColumns, event.previousIndex, event.currentIndex);
    this.columnChangedArr = this.displayedColumns
    this.filterSubmit = true
  }

  // Reorder Arr
  reorderArray(arr1:any, arr2:any) {
    const set1 = new Set(arr1);
    const matchingItems = arr2.filter((item:any) => set1.has(item));
    const extraItems = arr2.filter((item:any) => !set1.has(item));
    matchingItems.sort((a:any, b:any) => arr1.indexOf(a) - arr1.indexOf(b));
    return matchingItems.concat(extraItems);
  }

  // Tage Underscore
  removeUnderscoreAddUpperCase(str: any) {
    var i, frags = str.split('_');
    for (i = 0; i < frags?.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
  }

  tagMultiDataFunction(data: any) {
    this.tagMultiData = [];
    this.tagMultiCnt = 0;
    this.tagMultiData.push(data[0]);
    if (data?.length == 1) {
      return this.tagMultiData;
    } else {
      this.tagMultiCnt = '+' + JSON.stringify(data?.length - 1);
      return this.tagMultiData;
    }
  }

  // Tag Close
  applyTagClose(rptName: any, controlName: any) {
    Object.keys(this.appliedFilter).length > 0 || this.columnApplied ? this.filterSubmit = true : this.filterSubmit = false;
    this.resetOffset = true
    controlName = controlName == 'employment_type' ? 'employee_type' : controlName
    if (rptName == 'Leave Balance On A Particular Day') {
      this.callReportVal = true;
      this.closeControl = controlName;
    }
    if (rptName == 'Leave Carry Forward Report') {
      this.callReportVal = true;
      this.closeControl = controlName;
    }
    if (rptName == 'Leave Availed Report') {
      this.callReportVal = true;
      this.closeControl = controlName;
    }
    if (rptName == 'Leave Credit Report') {
      this.callReportVal = true
      this.closeControl = controlName
    }
    if (rptName == 'Leave Encashment Report') {
      this.callReportVal = true
      this.closeControl = controlName
    }
    if (rptName == 'Lapsed Leaves Report') {
      this.callReportVal = true
      this.closeControl = controlName
    }
    if (rptName == 'Negative Leave Balance Report') {
      this.callReportVal = true
      this.closeControl = controlName
    }
    if (rptName == 'Unpaid Leave Report') {
      this.callReportVal = true
      this.closeControl = controlName
    }
    if (rptName == 'Leave Balance Summary Report') {
      this.callReportVal = true
      this.closeControl = controlName
    }
    if (rptName == 'Leave Ledger Report') {
      this.callReportVal = true
      this.closeControl = controlName
    }
    if (rptName == 'Leave Request Status Report') {
      this.callReportVal = true
      this.closeControl = controlName
    }
  }

  // Save
  saveFilter() {
    this.columnsArr = [];
    if (this.filterSaveForm.value.filter == true) {
      this.saveFilterClicked = true
      this.filterMemorizeFunction('saveFilter', '');
    } else {
      this.applyFilter = false;
      this.submitted = false;
      this.filterSubmit = false;
      this.checkFilterSubmit = false;
    }
    this.router.navigate(['/leave-report'])
  }

  // Arrange data based on 1st & remove if not in 2nd
  rearragneAndRemove(arr1:any,arr2:any){
    let result = arr1.filter((item:any) => arr2.includes(item))
    let additionalItems = arr2.filter((item:any) => !arr1.includes(item));
    result.push(...additionalItems);
    return result
  }

  reorderArrays(arr1:any, arr2:any) {
    const isSameElements = (a:any, b:any) => {
        const aSorted = [...a].sort();
        const bSorted = [...b].sort();
        return JSON.stringify(aSorted) === JSON.stringify(bSorted);
    }
    if (!isSameElements(arr1, arr2)) {
        // console.log("Arrays do not have the same elements.");
        return arr2;
    }
    const arr2Map = new Map();
    arr2.forEach((value:any, index:any) => {
        arr2Map.set(value, index);
    });
    const reorderedArr2 = arr1.map((value:any) => arr2[arr2Map.get(value)]);
    return reorderedArr2;
}


  // Filter Memorize
  filterMemorizeFunction(data: any, data1: any) {
    if (this.header_filter?.length > 0) {
      var mySet = new Set(this.header_filter);
      this.header_filter = [...mySet];
    }
    let headerNeeded = !this.arrayAreSame(this.displayedColumns, this.defaultHeaderList)
    if(this.columnChangedArr?.length !=0){
      this.columnChangedArr = this.rearragneAndRemove(this.columnChangedArr,this.checkColumn)
    }
    if (this.reportName == 'Leave Balance On A Particular Day') {
      this.dataFilter = Object.keys(this.appliedFilter).length > 0 ? this.balanceParticularDay.getData() : ""
    }
    else if (this.reportName == 'Leave Availed Report') {
      this.dataFilter = Object.keys(this.appliedFilter).length > 0 ? this.availedReport.getData() : ""
    }
    else if (this.reportName == 'Leave Carry Forward Report') {
      this.dataFilter = Object.keys(this.appliedFilter).length > 0 ? this.carryForwadReport.getData() : ""
    }
    else if (this.reportName == 'Leave Credit Report') {
      this.dataFilter = Object.keys(this.appliedFilter).length > 0 ? this.creditReport.getData() : ""
    }
    else if (this.reportName == 'Leave Encashment Report') {
      this.dataFilter = Object.keys(this.appliedFilter).length > 0 ? this.encashmentReport.getData() : ""
    }
    else if (this.reportName == 'Lapsed Leaves Report') {
      this.dataFilter = Object.keys(this.appliedFilter).length > 0 ? this.lapsedReport.getData() : ""
    }
    else if (this.reportName == 'Leave Ledger Report') {
      this.dataFilter = Object.keys(this.appliedFilter).length > 0 ? this.ledgerReport.getData() : ""
    }
    else if (this.reportName == 'Unpaid Leave Report') {
      this.dataFilter = Object.keys(this.appliedFilter).length > 0 ? this.lopReport.getData() : ""
    }
    else if (this.reportName == 'Negative Leave Balance Report') {
      this.dataFilter = Object.keys(this.appliedFilter).length > 0 ? this.negativeReport.getData() : ""
    }
    else if (this.reportName == 'Leave Request Status Report') {
      this.dataFilter = Object.keys(this.appliedFilter).length > 0 ? this.requestReport.getData() : ""
    }
    else {
      // (this.reportName == 'Leave Balance Summary Report')
      this.dataFilter = Object.keys(this.appliedFilter).length > 0 ? this.balanceReport.getData() : ""
    }


    if (this.dataFilter == undefined || this.dataFilter == null || this.dataFilter == "") {
      this.dataFilter = JSON.stringify({});
    }

    this.reportService.filterMemorize({ 'data_filter': this.childCall == true ? JSON.stringify({}) : this.dataFilter, 'header_filter': this.columnChangedArr?.length != 0 ? JSON.stringify(this.columnChangedArr) : ((headerNeeded && !this.is_colfilter) ? JSON.stringify(this.displayedColumns) : JSON.stringify([])), 'is_active': true, 'report': Number(this.reportId) }).subscribe((res: any) => {
      if(this.callReportVal || this.resetFilter){
        this.notify.handleSuccessNotification("Updated Successfully","Success")
      }else{
        this.notify.handleSuccessNotification("Created Successfully","Success")
      }
      this.submitted = false;
      this.filterSubmit = false;
      this.saveFilterClicked = false
      this.restFilterSave = false
      this.hasChangeCount = 0;
      this.checkFilterSubmit = false
      if (data == 'saveFilter') {
        this.resetFilterControllers('save');
        this.applyFilter = false;
      }
      else if (data == 'resetFilter') {
        this.resetFilter = false;
        this.childCall = false
        if (data1 == 'dataFilter')
          this.resetFilterControllers('reset');
        else if (data1 == 'colFilter')
          this.resetAllUnDefaultColumns();

        this.viewDetail = false;
      }
      if (data1 == 'saveAndSchedule') {
        this.scheduleReport = false;
        setTimeout(() => {
          if (this.reportId != undefined && this.reportId != null)
            this.router.navigate(['/scheduled-reports/reports-setup/' + this.reportId + '/true']);
        }, 3000);
      }
    });
  }

  resetFilterControllers(data: any) {
    this.viewDetail = false;
    this.filterSubmit = false;

    if (this.reportName == 'Leave Balance On A Particular Day') {
      this.balanceParticularDay.resetFilterControllers('reset')
    }
    if (this.reportName == 'Leave Carry Forward Report') {
      this.carryForwadReport.resetFilterControllers('reset')
    }
    if (this.reportName == 'Leave Availed Report') {
      this.availedReport.resetFilterControllers('reset')
    }
    if (this.reportName == 'Leave Credit Report') {
      this.creditReport.resetFilterControllers('reset')
    }
    if (this.reportName == 'Leave Encashment Report') {
      this.encashmentReport.resetFilterControllers('reset')
    }
    if (this.reportName == 'Lapsed Leaves Report') {
      this.lapsedReport.resetFilterControllers('reset')
    }
    if (this.reportName == 'Negative Leave Balance Report') {
      this.negativeReport.resetFilterControllers('reset')
    }
    if (this.reportName == 'Unpaid Leave Report') {
      this.lopReport.resetFilterControllers('reset')
    }
    if (this.reportName == 'Leave Balance Summary Report') {
      this.balanceReport.resetFilterControllers('reset')
    }
    if (this.reportName == 'Leave Ledger Report') {
      this.ledgerReport.resetFilterControllers('reset')
    }
    if (this.reportName == 'Leave Request Status Report') {
      this.requestReport.resetFilterControllers('reset')
    }
  }

  resetAllUnDefaultColumns() {
    this.checkFilterSubmit = false;
    this.checkColumn = []
    var elements = document.getElementsByName("check");
    setTimeout(() => {
      for (let i = 0; i < elements?.length; i++) {
        var element = <HTMLInputElement>elements[i];
        if (element.disabled == false)
          element.checked = false;
      }

      for (let j = 0; j < this.header_filter?.length; j++) {
        const index: number = this.displayedColumns.indexOf(this.header_filter[j]);
        if (index != -1) {
          this.displayedColumns.splice(index, 1);
        }

      }
      if (this.resHeader_filter?.length == 0) {
        this.dynamicHeader(this.defaultHeaderList?.length, this.defaultHeaderList, this.columns2);
        this.headerFilterImplementation(this.defaultHeaderList)

      }
      this.header_filter = []
      this.columnsArr = []
    }, 1000);

    if (this.resHeader_filter?.length != 0) {
      this.callAllReportsFunctions()
    }
  }

  childCall: boolean = false


  // Reset Filter
  resetFilterApply() {
    this.restFilterSave = true
    if (this.is_colfilter == true) {
      this.header_filter = [];
      this.childCall = false
      this.filterMemorizeFunction('resetFilter', 'colFilter');
      this.is_colfilter = false
      this.childFilterSubmit = true;
    }
    else {
      this.childCall = true
      this.dataFilter = JSON.stringify({});
      this.filterMemorizeFunction('resetFilter', 'dataFilter');
    }
  }

  // After reseting the form from child, main report fn is called
  callReport(value: any) {
    // let callReports = value.reportName
    this.filterSubmit = false
    this.childFilterSubmit = true
    this.page = 1
    this.pageNumber = 0
    this.resetOffset = true
    this.callAllReportsFunctions()
  }

  checkListElements() {
    var elements = document.getElementsByName("check");
    setTimeout(() => {
      for (let i = 0; i < elements?.length; i++) {
        var element = <HTMLInputElement>elements[i];
        for (let j = 0; j < this.columns1?.length; j++) {
          if (element.defaultValue == this.columns1[j]) {
            element.disabled = false;

            // element.disabled  = true;
          }
        }
      }
    }, 1000);
  }

  dynamicHeader(data_length: any, header_list: any, check_list: any) {
    // this.nodata           = data_length==0?true:false;
    this.columns1 = header_list.slice();
    // this.displayedColumns = this.columnsArr?.length > 0 ? this.dupliCateRemove(this.columnsArr) : this.dupliCateRemove(this.columns1)
    this.displayedColumns = this.dupliCateRemove(this.columns1)

    this.columnOptions1 = this.columns1;
  }

  dupliCateRemove(data: any) {
    const uniqueColumnsSet = new Set();
    data.filter((column: any) => {
      if (!uniqueColumnsSet.has(column)) {
        uniqueColumnsSet.add(column);
        return true;
      }
      return false;
    });
    return Array.from(uniqueColumnsSet)
  }

  dynamicHeaders(value: any) {
    this.responseLength = value.length
    this.headerCheck = value.header
    this.checkList = value.checklist

    this.nodata = this.responseLength == 0 ? true : false;
    this.columns1 = this.headerCheck;
    this.displayedColumns = this.columns1;
    this.columnOptions1 = this.columns1;
  }

  headerFilterImplementation(headerFilter: any) {
    this.header_filter = [];
    var elements = document.getElementsByName("check");
    setTimeout(() => {
      for (let i1 = 0; i1 < elements?.length; i1++) {
        var elementss = <HTMLInputElement>elements[i1];
        elementss.checked = false;
      }
    }, 100);

    var elements = document.getElementsByName("check");
    setTimeout(() => {
      for (let i = 0; i < elements?.length; i++) {
        var element = <HTMLInputElement>elements[i];
        for (let j = 0; j < headerFilter?.length; j++) {
          if (element.defaultValue == headerFilter[j]) {
            element.checked = true;
            element.disabled = false;
            this.checkColumn.push(headerFilter[j]);
            this.header_filter.push(headerFilter[j]);
          }
        }
      }
    }, 1000);
    for (let i = 0; i < headerFilter?.length; i++) {
      const element = headerFilter[i];
      if (!this.displayedColumns.includes(element)) {
        this.displayedColumns.push(element);
      }
    }
  }

  salFilter(d: any) {
    let dateRange = [new Date(new Date().getFullYear() - 50, 0, 1),
    new Date(new Date().getFullYear() + 50, 11, 31)]
    return (d >= dateRange[0] && d <= dateRange[1])
  }

  // Company List
  getCompanyList() {
    this.roleService.getCompanyList().subscribe((res: any) => {

      if (res.status == 200 && res.body?.length > 0) {
        this.companyDD = res.body;
        this.selectAllForDropdownItems(this.companyDD);
      }
    });
  }

  // Business Unit
  getBUList() {
    let comp;
    if (this.reportName == 'Leave Balance On A Particular Day') {
      comp = this.balanceParticularForm.value.company;
      this.balanceParticularForm.get('bu')?.setValue('');
    }
    if (this.reportName == 'Leave Carry Forward Report') {
      comp = this.carryForwardForm.value.company;
      this.carryForwardForm.get('bu')?.setValue('');
    }
    if (this.reportName == 'Leave Availed Report') {
      comp = this.availedForm.value.company;
      this.availedForm.get('bu')?.setValue('');
    }
    if (this.reportName == 'Leave Credit Report') {
      comp = this.creditForm.value.company;
      this.creditForm.get('bu')?.setValue('');
    }
    if (this.reportName == 'Leave Encashment Report') {
      comp = this.encashmentForm.value.company;
      this.encashmentForm.get('bu')?.setValue('');
    }
    if (this.reportName == 'Lapsed Leaves Report') {
      comp = this.lapsedForm.value.company;
      this.OuFilterForm.get('bu')?.setValue('');
    }
    if (this.reportName == 'Negative Leave Balance Report') {
      comp = this.negativeForm.value.company;
      this.negativeForm.get('bu')?.setValue('');
    }
    if (this.reportName == 'LOP Report') {
      comp = this.lopForm.value.company;
      this.lopForm.get('bu')?.setValue('');
    }
    if (this.reportName == 'Leave Balance Summary Report') {
      comp = this.balanceForm.value.company;
      this.balanceForm.get('bu')?.setValue('');
    }
    if (this.reportName == 'Leave Ledger Report') {
      comp = this.ledgerForm.value.company;
      this.ledgerForm.get('bu')?.setValue('');
    }
    if (this.reportName == 'Leave Request Status Report') {
      comp = this.requestForm.value.company;
      this.requestForm.get('bu')?.setValue('');
    }

    if (comp != undefined && comp != null && comp != '') {
      this.reportService.getBuList(comp).subscribe((res: any) => {
        this.buDD = res;
        this.selectAllForDropdownItems(this.buDD);
      });
    }
  }

  // Department List
  getDepList() {
    this.roleService.getDepartmentList().subscribe((res: any) => {
      if (res.status == 200 && res.body?.length > 0) {
        this.DepDD = res.body;
        this.selectAllForDropdownItems(this.DepDD);
      }
    });
  }

  // Designation List
  getDesignationList() {
    this.roleService.getDesignationList().subscribe((res: any) => {
      if (res.status == 200 && res.body?.length > 0) {
        this.DesignationDD = res.body;
        this.selectAllForDropdownItems(this.DesignationDD);
      }
    });
  }

  // Grade List
  getGradeList() {
    this.roleService.getGradeList().subscribe((res: any) => {
      if (res.status == 200 && res.body?.length > 0) {
        this.gradeDD = res.body;
        this.selectAllForDropdownItems(this.gradeDD);
      }
    });
  }

  // Branch List
  getBranchList() {
    this.reportService.getBranchList().subscribe((res: any) => {
      this.BranchDD = res;
      this.selectAllForDropdownItems(this.BranchDD);
    });
  }

  // Employee List
  getEmployeeList() {
    this.leaveService.getEmpList(true)
      .subscribe((res: any) => {

        if (res?.length > 0) {
          for (let i = 0; i < res?.length; i++) {
            res[i]['name'] = res[i]['first_name'];
            if (res[i]['first_name'] != null)
              res[i]['name'] = res[i]['first_name'] + " ";
            if (res[i]['middle_name'] != null)
              res[i]['name'] += res[i]['middle_name'] + " ";
            if (res[i]['last_name'] != null)
              res[i]['name'] += res[i]['last_name'];
            res[i]['name'] += " (" + res[i]['employee_code'] + ")";

          }
          this.employeeDropdown = res;
          let len = this.employeeDropdown.length;
          for (let i = 0; i < len; i++) {
            this.employeeDropdown[i]['fullname'] = "";
            if (this.employeeDropdown[i]['first_name'] != null)
              this.employeeDropdown[i]['fullname'] += this.employeeDropdown[i]['first_name'] + " ";
            if (this.employeeDropdown[i]['middle_name'] != null)
              this.employeeDropdown[i]['fullname'] += this.employeeDropdown[i]['middle_name'] + " ";
            if (this.employeeDropdown[i]['last_name'] != null)
              this.employeeDropdown[i]['fullname'] += this.employeeDropdown[i]['last_name'];
          }
          // this.selectAllForDropdownItems(this.employeeDropdown);
        }


      })
  }

  // Leave Type
  getLeaveTypeList() {
    this.leavePolicyServ.getleavetypeList().subscribe((res: any) => {
      this.leaveType = res
      this.selectAllForDropdownItems(this.leaveType)
    })
  }

  unPaidType: any

  getUnpaidLeaveList() {
    this.leaveTypeServ.getUnPaidDropDownList().subscribe((res: any) => {
      this.unPaidType = res
      this.selectAllForDropdownItems(this.unPaidType)
    })
  }

  // Leave Policy
  getLeavePolicyList() {
    this.leavePolicyServ.getSearchList(true).subscribe((res: any) => {
      this.leavePolicy = res
      this.selectAllForDropdownItems(this.leavePolicy)
    })
  }

  // Loader
  loaderFn(value: any) {
    this.loader = value
  }

  // Datafilters for filter save
  dataFilters(value: any) {
    this.dataFilter = value.data
  }

  // Filter Panel ON and OFF
  panel(value: any) {
    this.viewDetail = value
  }

  // Reset filter btn
  resetFilterFn(value: any) {
    this.resetFilter = value
    this.page = 1
    this.pageNumber = 0
    this.columnApplied = false
  }

  // Filtersubmit
  filterSubmits(value: any) {
    this.filterSubmit = value
  }

  // Date Format
  getDateFormat() {
    this.matDateFormat.display.dateInput = this.appservice.getdatepickerformat();
    if (this.matDateFormat.display.dateInput == '') {
      setTimeout(() => {
        this.getDateFormat();
      }, 1000);
    }
  }

  // Select ALL option for Multiselect
  selectAllForDropdownItems(items: any[]) {
    let allSelect = (items: any[]) => {
      items.forEach(element => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }

  limit = 20
  offset = 0
  page = 1
  lastPage: any
  initialLoaded: boolean = true
  pageNumber: any = 1
  pageResetElseFlag = false
  resetOffset = false
  pageChanged(val: any) {
    this.pageNumber = val - 1
    this.offset = this.reportService.calculateOffset(val - 1)
    this.cd.detectChanges()
    this.pageChangeDetect = true
    this.callAllReportsFunctions()
    if (!this.columnApplied) {
      this.columnApplied = false;
    } else {
      this.columnApplied = true;
    }
  }

  callParentFunction(val: any) {
    this.initialLoaded = val
  }

  resetCall(val: any) {
    this.page = 1
    this.pageNumber = 0
    this.resetOffset = true
  }

  direction : string = 'asc'
  sortProperty : string = 'Employee Code'
  ordering: any = 'Employee Code'

  onSort(val: any) {
    if (this.sortProperty === val) {
      this.direction = this.direction === 'desc' ? 'asc' : 'desc';
    } else {
      this.sortProperty = val;
      this.direction = 'desc';
    }
    const newOrder = this.direction === 'desc' ? '-' : '';
    this.ordering = newOrder + val
    this.cd.detectChanges();
    this.filterFormData = this.filterFormData?.replace(/ordering=.*?(?=&|$)/, `ordering=${this.ordering}`);
    this.pageChangeDetect = true
    this.columnApplied == true ? this.columnApplied = true : this.columnApplied = false
    this.callAllReportsFunctions();
    // this.columnApplied = false
  }

  callAllReportsFunctions() {
    if (this.reportName == 'Unpaid Leave Report') {
      this.leaveLOPReport()
    }
    if (this.reportName == 'Lapsed Leaves Report') {
      this.leaveLapsedReport()
    }
    if (this.reportName == 'Leave Availed Report') {
      this.leaveAvailedReport();
    }
    if (this.reportName == 'Leave Carry Forward Report') {
      this.carryForwardReport()
    }
    if (this.reportName == 'Leave Credit Report') {
      this.leaveCreditReport();
    }
    if (this.reportName == 'Leave Encashment Report') {
      this.leaveEncashmentReport();
    }
    if (this.reportName == 'Leave Ledger Report') {
      this.leaveLedgerReport();
    }
    if (this.reportName == 'Negative Leave Balance Report') {
      this.leaveNegativeReport();
    }
    if (this.reportName == 'Leave Request Status Report') {
      this.leaveRequest();
    }
    if (this.reportName == 'Leave Balance Summary Report') {
      this.leaveBalanceReport();
    }
    if (this.reportName == 'Leave Balance On A Particular Day') {
      this.leaveBalanceParticularDay()
    }
  }

  dojFilter(d: any) {
    let dateRange = [new Date(new Date().getFullYear() - 50, 0, 1),
    new Date()]
    return (d >= dateRange[0] && d <= dateRange[1])
  }
}
