import { Component, OnInit ,ViewChild} from '@angular/core';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
import { AttendanceDashboardService } from 'src/app/@core/services/attendance-dashboard.service';
import { EmployeeService } from 'src/app/@core/services/employee.service';
import * as XLSX from 'xlsx';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { DatePipe } from '@angular/common';
import {  Router } from '@angular/router';
import { Subject } from 'rxjs';
import { delay,switchMap  } from 'rxjs/operators';
import { SearchbarComponent } from 'src/app/@shared/components/searchbar/searchbar.component';


@Component({
  selector: 'app-employee-attendance',
  templateUrl: './employee-attendance.component.html',
  styleUrls: ['./employee-attendance.component.scss']
})
export class EmployeeAttendanceComponent implements OnInit {

  sLoading = false
  limit                = 20
  offset               = 0
  page                 = 1
  filter               = false;
  filterOpened         = false;
  from                 = 'EmployeeAttendance';
  loader               = false;
  displayedColumns:any = ['Employee']
  datePickDay: any     = moment().format('MMM YYYY');
  landData: any        = []
  searchListData:any   = []
  searchFilter:any     = this.attendanceService.SEARCHFILTER
  searchKeyword        = "";
  searchPlaceholder    = this.messageService.searchdisplay('Employee');
  viewDetail           = false;
  showSidePanel        = false;  
  employeeView         = false;
  pickDate : any       = this.attendanceService.SETDATE
  selectedList : any   = []
  alertDiv             = true
  selectedName: any    = 'Attendance not mapped'
  notificationPanel    = false;
  filterdata:any = this.attendanceService.FILTERDATA
  searchData : Subject<void> = new Subject();
  doj:any;
  activatePunch:any
  weekRange : any
  activeEmpData:any;
  permissions: any
  fromDay   : any
  toDay     : any
  fromDate   : any
  toDate     : any
  noData:any
  activeEmployee :any;
  activeYear:any;
  activeMonth:any;
   lastPage: any
  empId:number = 0;
  months          = ["January", "February", "March", "April", "May", "June", "July", "August", "September",  "October", "November", "December"]
  currentDay:any  =  this.datePipe.transform(new Date(), 'yyyy-MM-dd')?.toString()
  @ViewChild(SearchbarComponent) child:any;

  filterForm           = this.fb.group({
                          company       : '',
                          bu            : '',
                          branch        : '',
                          department    : '',
                          designation   : '',
                          grade         : '',
                          emp_status    : '',
                          emp_type      : '',
                          repomanager   : '',
                          funmanager    : '',
                          sortby        : 'All',
  })
  defaultClass:any      = {
    'Present'   : 'present',
    'Absent'    : 'absent',
    'Leave'     : 'leave',
    'Weekoff'   : 'weekoff',
    'Holiday'   : 'holiday'

  }
  isMemorized : boolean = false

    minDate = new Date(2020, 0, 1);
    date = new Date();
    maxDate = new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0);
    radiobtnvalue = true;

  constructor(
    public appService                 : AppService,
    public messageService             : MessageService,
    public fb                         : FormBuilder,
    public attendanceService          : AttendanceDashboardService,
    public empServ                    : EmployeeService,
    private notificationService       : NotificationService,
    public datePipe                   : DatePipe,
    public router: Router

  ) { }

  ngOnInit(): void {

    this.fromDate = moment(this.pickDate).startOf('week').format('YYYY-MM-DD')
    this.toDate = moment(this.pickDate).endOf('week').format('YYYY-MM-DD')
    this.datePickDay = moment(this.pickDate).format('MMM YYYY');

    this.getLandingscreenData();
    // this.getSearchFilter();
	  this.getPermission();
    this.getNodataHeader();

    let arrFilter: any = []
    let arrList = []
    let arrData = []
    if(this.filterdata){
      this.isMemorized = true
      arrData = this.filterdata.split("&")
      arrData.forEach((element: any) => {
        arrList = element.split('=')
        arrFilter[arrList[0]] =arrList[1]
      })
      let ordering:any = this.appService.memOrdering()
      this.filterForm.reset(this.appService.memFilterArray3(arrFilter,ordering));
    }

    this.searchData.pipe(delay(1000),
      switchMap(() =>  this.attendanceService.getAllAttendanceData(this.fromDate,this.toDate,this.limit,this.offset,this.searchKeyword,this.filterdata,this.empId)
    )).subscribe((result: any) => {
      this.arrangeLandingScreen(result)
      // this.getSearchFilter();
    });

  }

  getPermission(){
    this.permissions = this.appService.getPermissions('/employee-attendance');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  } 

  // Today Date
  isCurrentDate(date: any): boolean {
    const currentDate = moment().format('YYYY-MM-DD');
    return moment(currentDate).isSame(date)
  }

  closefn(flag:any){
    this.filter = flag;
  }
  resultfilterfn(data:any){
    this.filterdata = data;
    this.attendanceService.setFilterData(data)
    this.offset     = 0;
    this.child.clearBar();
    this.applyfilterfn();
  }
  applyfilterfn(){
    this.loader         = true;
    this.noData         = false;
    this.searchKeyword  = '';

    this.getLandingscreenData();
    this.filter = false;
  }
  searchDrop(val:any){
    this.searchFilter = val
    this.attendanceService.setSearchFilterData(val)
    this.offset       = 0
    this.page         = 1
  }

  fromDates(val:any){
    this.attendanceService.setDates(val.value)
    this.fromDate = moment(val.value)?.startOf('week').format('YYYY-MM-DD')
    this.toDate   = moment(val.value)?.endOf('week').format('YYYY-MM-DD')
    this.getLandingscreenData();
    this.datePickDay = moment(val.value).format('MMM YYYY')
  }
  getLandingscreenData(){
    this.loader = true;
    this.attendanceService.getAllAttendanceData(this.fromDate,this.toDate,this.limit,this.offset,this.searchKeyword,this.filterdata,this.empId).subscribe((res:any)=>{
      this.arrangeLandingScreen(res)
      // this.getSearchFilter();
    })
  }
  getNodataHeader(){
    let data = this.getDaysBetweenDates(moment(this.fromDate),moment(this.toDate))
    this.displayedColumns = []
    this.displayedColumns = ['Employee']
    data.forEach((item:any,index:any)=>{
      this.displayedColumns.push((item?.weekday+' - '+this.appService.getDayMonth(item?.date)))
    })
    let length = this.displayedColumns.length
    this.weekRange = `( ${this.displayedColumns[1].slice(6)} - ${this.displayedColumns[length-1].slice(6)} )`

  }
  getDaysBetweenDates = (startDate:any, endDate:any)=> {
    var now = startDate.clone(), dates = [];

    while (now.isSameOrBefore(endDate)) {
        dates.push({
          weekday   : moment(now).format('ddd'),
          date      :  now.format('YYYY-MM-DD')
        });
        now.add(1, 'days');
    }
    return dates;
  };
  arrangeLandingScreen(res:any){

    if(res.body.data?.length!=0){
      this.landData     = res.body.data
      this.lastPage     = res.body?.total_employees
      this.selectedList = res?.body?.not_assigned_employees


      for(let i =0;i<this.landData?.length;i++){
        this.doj = this.landData[i]['date_of_joined']

        for(let j=0;j<this.landData[i].attendance_detail.length;j++){
          let atendanceData = this.landData[i].attendance_detail[j];
          if(atendanceData?.status_firstHalf!=null && atendanceData?.status_secondHalf!=null && atendanceData?.date!=this.currentDay){

            if(atendanceData?.status_firstHalf == atendanceData?.status_secondHalf){
              this.landData[i].attendance_detail[j]['attendance_class'] = "status-"+this.defaultClass[atendanceData?.status_firstHalf];
            }
            else{
              this.landData[i].attendance_detail[j]['attendance_class'] = "status-"+this.defaultClass[atendanceData?.status_firstHalf]+"-"+this.defaultClass[atendanceData?.status_secondHalf];

            }
          }
          else{
            if(this.doj>atendanceData?.date)
            this.landData[i].attendance_detail[j]['attendance_class'] = '';
            else if(atendanceData?.shift?.from_time == 0)
            this.landData[i].attendance_detail[j]['attendance_class']="status-shift-unassigned"
            else if(atendanceData?.date>this.currentDay)
            this.landData[i].attendance_detail[j]['attendance_class']="status-upcoming"
            else
            this.landData[i].attendance_detail[j]['attendance_class'] = '';
          }
        }
      }
    }else{
      this.selectedList = []
    }
    this.getNodataHeader();
    this.noData = res.body.data?.length == 0 ? true : false
    this.loader = false;
    this.sLoading = false
  }

   searchResults(data:any){
    this.loader = true
    if(data == ''){
      this.searchKeyword = ""
      this.empId  = 0
      this.offset       = 0
      this.page         = 1
      this.landData     = []
      this.searchData.next()
    }else{

       this.offset = 0
       this.page   = 1
       this.empId  = data
       this.searchData.next()
    }
  }

  searchEmp(keyWord:any){
    this.sLoading = true
    this.loader   = true
    this.empId  = 0
    this.offset   = 0
    this.landData = []
    this.searchKeyword = keyWord
    if(this.searchKeyword!=""){
      this.page = this.offset = 0
    this.searchData.next()
    }else{
      this.page = this.offset = 0
    }
  }
  getPageFromService(val:any){

    let oldOffset = (val - 1) * this.limit
    this.offset   = oldOffset
    if(this.filterdata=='')
    this.getLandingscreenData()
    else
    {
      this.getLandingscreenData();
    }

  }
  
  singleEmployeeview(id:any){

    this.activeYear     = (new Date(this.datePickDay)).getFullYear()
    this.activeMonth    = this.months[(new Date(this.datePickDay)).getMonth()]
    this.router.navigateByUrl("/employee-attendance/employee-attendance-view/"+id+"/"+this.activeYear+"/"+this.activeMonth)

  }

  changeWeek(val:any){
    if(val=='left'){
      this.pickDate = new Date(moment(this.pickDate).subtract(7, 'days').format('YYYY-MM-DD'))
    }else{
      this.pickDate = new Date(moment(this.pickDate).add(7, 'days').format('YYYY-MM-DD'))
    }
    this.attendanceService.setDates(this.pickDate)
    this.fromDate = moment(this.pickDate).startOf('week').format('YYYY-MM-DD')
    this.toDate = moment(this.pickDate).endOf('week').format('YYYY-MM-DD')
    this.getLandingscreenData()
    this.datePickDay = moment(this.pickDate).format('MMM YYYY')
  }
  closePanel(val:any){
    this.notificationPanel = val
  } 
  closesidePanel(val:any){
    this.showSidePanel = val
  } 
  uploadeddata(event:any){
    this.showSidePanel = false;
    this.getLandingscreenData();

  }
}
