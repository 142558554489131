<div class="container-fluid px-32 py-24">
  <div class="row row-16">
    <div class="col-12 d-flex gap-8  align-items-center">
      <span class="text-light-500 fs-12 text-nowrap">{{requestitem?.created_display}}</span>
      <button class="btn-reset btn-star ms-auto text-light-500 fs-12" [class.mail-stared]="requestitem['star']==true"
      (click)="performAction(2,0,requestitem['id'],'','',requestitem['star'])">
        <i class="bi toggle-star"></i>
      </button>
    </div>
    <div class="col-12 mt-8 card-ribbon">
      <div class="w-100 d-flex align-items-center gap-8 card-ribbon">
        <h5 class="mb-8">Asset Request Approval</h5>
        <div *ngIf="requestitem?.request_data?.status != 'Invalid'" class="d-flex align-items-center ms-auto gap-8">
          <span class="badge px-16 ms-auto fs-14 badge-warning py-1"
          *ngIf="requestitem?.approved_status == 'Pending'">Pending</span>
          <span class="badge px-16 ms-auto fs-14 badge-success py-1"
          *ngIf="requestitem?.approved_status == 'Approved'">Accepted</span>
          <span class="badge px-16 ms-auto fs-14 badge-danger py-1"
          *ngIf="requestitem?.approved_status == 'Rejected'">Rejected</span>
          <span class="text-light-400">|</span>
          <span class="link-primary fs-14" (click)="requestViewStatus()">View Status</span>
        </div>
         <!-- Invalid request -->
         <span *ngIf="requestitem?.request_data?.status == 'Invalid'"
         class="ribbon ribbon-right ribbon-danger  ribbon-sm" style="right:-0.0625rem;bottom: 0.75rem;">Invalid</span>
      </div>
      <span class=" badge-custom {{requestitem?.request_type_color_code}}">Asset Request </span>
      <span *ngIf="requestitem?.is_mail_overdue && !requestitem?.achieved_status && requestitem?.request_data?.status != 'Invalid'" class="ribbon ribbon-right ribbon-danger" style="right: 0.5rem; bottom: 0">Overdue</span>
    </div>

    <app-inbox-progress-bar [requestitem]="requestitem"></app-inbox-progress-bar>

    <div class="col-12">
      <p class="mb-0 fs-14" [innerHTML]="requestitem?.message"></p>
    </div>

    <ngb-accordion class="custom-accordion" #acc="ngbAccordion" activeIds="ngb-panel-0">
      <ngb-panel *ngIf="requestitem?.requests?.new_assets_inbox?.length > 0" id="ngb-panel-0">
        <ng-template ngbPanelHeader>
          <button ngbPanelToggle class="accordion-button">New Assets</button>
        </ng-template>
        <ng-template ngbPanelContent>
          <ng-container *ngIf="requestitem?.requests?.new_assets_inbox?.length > 0">
              <div class="card card-c2 p-0">
                <div class="table-responsive scrollbar-10 radius-4">
                  <table
                    class="table table-striped table-borderless td-12 sticky-header td-fs-14
                                    tr-fs-14 table-sm form-table  sticky-first-col  sticky-last-col">
                    <thead>
                      <tr>
                        <th scope="col" class="text-center" style="width: 46px;">
                          <input class="form-check-input  checkbox-16 m-0" type="checkbox" value=""
                            (click)="checkall($event)" [checked]="checkAllSelected()">
                          </th>
                        <th class="fw-600 text-uppercase" scope="col">category</th>
                        <th class="fw-600 text-uppercase" scope="col">Subcategory</th>
                        <th class="fw-600 text-uppercase" scope="col">Status</th>
                        <th class="fw-600 text-uppercase" scope="col">view</th>
                      </tr>
                    </thead>
                    <tbody class="cell-16 ">
                      <ng-container
                        *ngFor="let request of $any(requestitem?.requests?.new_assets_inbox | slice: (page1-1) * pageSize1 : page1 * pageSize1),let ind = index">
                        <tr>
                          <td scope="col" class="text-center"><input class="form-check-input  checkbox-16" type="checkbox" value=""
                              [(ngModel)]="request.checked">
                          </td>
                          <td class="text-trim w-17ch" data-toggle="tooltip" title="{{request?.asset_request_product?.category_name}}">
                            {{request?.asset_request_product?.category_name}}</td>
                          <td class="text-trim w-17ch" data-toggle="tooltip" title="{{request?.asset_request_product?.sub_category_name}}">
                            {{request?.asset_request_product?.sub_category_name}}</td>
                          <td><span
                              class="badge {{request?.status == 'Pending' ? 'badge-warning' : request?.status == 'Approved' ? 'badge-success' : 'badge-danger'}}  py-1 px-3">{{request?.status}}</span>
                          </td>
                          <td><i class="icon-eye link-primary fs-18"
                              (click)="viewData=request;viewDetails = true;assetType='New'"></i></td>
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                </div>
              </div>

            <ngb-pagination *ngIf="requestitem?.requests?.new_assets_inbox?.length>pageSize1" class="d-flex justify-content-end"
              [collectionSize]="requestitem?.requests?.new_assets_inbox?.length" [(page)]="page1" [maxSize]="3" [rotate]="true"
              [ellipses]="false" [boundaryLinks]="true" [pageSize]="pageSize1"></ngb-pagination>
          </ng-container>
        </ng-template>
      </ngb-panel>
      <ngb-panel *ngIf="requestitem?.requests?.return_assets_inbox?.length > 0" id="ngb-panel-1">
        <ng-template ngbPanelHeader>
          <button ngbPanelToggle class="accordion-button">Return Assets</button>
        </ng-template>
        <ng-template ngbPanelContent>
          <ng-container *ngIf="requestitem?.requests?.return_assets_inbox?.length > 0">
              <div class="card card-c2 p-0">
                <div class="table-responsive scrollbar-10 radius-4">
                  <table
                    class="table table-striped table-borderless td-12 sticky-header td-fs-14
                                    tr-fs-14 table-sm form-table  sticky-first-col sticky-last-col">
                    <thead>
                      <tr>
                        <th scope="col" class="text-center" style="width: 46px;"><input class="form-check-input checkbox-16 m-0" type="checkbox" value=""
                            (click)="checkallReturn($event)" [checked]="checkAllSelectedReturn()"></th>
                        <th class="fw-600 text-uppercase" scope="col">category</th>
                        <th class="fw-600 text-uppercase" scope="col">Subcategory</th>
                        <th class="fw-600 text-uppercase" scope="col">Asset Name</th>
                        <th class="fw-600 text-uppercase" scope="col">Status</th>
                        <th class="fw-600 text-uppercase" scope="col">view</th>
                      </tr>
                    </thead>
                    <tbody class="cell-16 ">
                      <ng-container
                        *ngFor="let return of $any(requestitem?.requests?.return_assets_inbox | slice: (page2-1) * pageSize2 : page2 * pageSize2),let j = index">
                        <tr>
                          <td scope="col" class="text-center"><input [disabled]="return?.status == 'Invalid'"
                              class="form-check-input checkbox-16 m-0" type="checkbox" value="" [(ngModel)]="return.checked">
                          </td>
                          <td class="text-trim w-17ch" data-toggle="tooltip" title="{{return?.asset_request_product?.category_name}}">
                            {{return?.asset_request_product?.category_name}}</td>
                          <td class="text-trim w-17ch" data-toggle="tooltip" title="{{return?.asset_request_product?.sub_category_name}}">
                            {{return?.asset_request_product?.sub_category_name}}</td>
                          <td class="text-trim w-17ch" data-toggle="tooltip" title="{{return?.asset_request_product?.product_name}}">
                            {{return?.asset_request_product?.product_name}}</td>
                          <td>
                            <div class="hstack gap-8 align-items-center">
                              <span
                                class="badge {{return?.status == 'Pending' ? 'badge-warning' : return?.status == 'Approved' ? 'badge-success' : return?.status == 'Invalid' ? 'badge-danger1' : 'badge-danger'}} py-1 px-3">{{return?.status}}</span>
                              <i *ngIf="return?.status == 'Invalid'" class="bi bi-info-circle text-danger fs-12 tooltip-icon"
                                ngbPopover="Your asset request is invalid because the status of the requested asset has changed. Please check the current asset status or contact HR for further details."
                                popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave"></i>
                            </div>
                          </td>
                          <td><i class="icon-eye link-primary fs-18"
                              (click)="viewData=return;viewDetails = true;assetType='Return'"></i></td>
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                </div>
              </div>

            <ngb-pagination *ngIf="requestitem?.requests?.return_assets_inbox?.length>pageSize2" class="d-flex justify-content-end"
              [collectionSize]="requestitem?.requests?.return_assets_inbox?.length" [(page)]="page2" [maxSize]="3" [rotate]="true"
              [ellipses]="false" [boundaryLinks]="true" [pageSize]="pageSize2"></ngb-pagination>
          </ng-container>
        </ng-template>
      </ngb-panel>

    </ngb-accordion>

    <!-- New Asset Section -->
    <!-- <ng-container *ngIf="requestitem?.requests?.new_assets_inbox?.length > 0">
      <div class="col-12">
        <div class="card card-c2">
          <div class="card-body collapse-button">
            <div class="d-flex">
              <p class="mb-0 title">New Assets</p>
              <span class="ms-auto"><i class="icon-caret-down-fill fs-6"></i></span>
            </div>
          </div>
          <div class="card-body collapse-target ">
            <div class="card card-c2 p-0">
              <div class="table-responsive scrollbar-10 radius-4">
                <table class="table sticky-header table-striped table-sm td-white-space td-width-15ch sticky-first-col sticky-last-col">
                  <thead>
                    <tr>
                      <th scope="col" class="form-cell"><input class="form-check-input m-0" type="checkbox" value="" (click)="checkall($event)"
                        [checked]="checkAllSelected()"></th>
                      <th class="fw-600 text-uppercase" scope="col">category</th>
                      <th class="fw-600 text-uppercase" scope="col">Subcategory</th>
                      <th class="fw-600 text-uppercase" scope="col">Status</th>
                      <th class="fw-600 text-uppercase" scope="col">view</th>
                    </tr>
                  </thead>
                  <tbody class="cell-16 ">
                    <ng-container *ngFor="let request of $any(requestitem?.requests?.new_assets_inbox | slice: (page1-1) * pageSize1 : page1 * pageSize1),let ind = index">
                      <tr>
                        <td scope="col" class="form-cell"><input class="form-check-input m-0" type="checkbox" value="" [(ngModel)]="request.checked">
                        </td>
                        <td data-toggle="tooltip" title="{{request?.asset_request_product?.category_name}}">{{request?.asset_request_product?.category_name}}</td>
                        <td data-toggle="tooltip" title="{{request?.asset_request_product?.sub_category_name}}">{{request?.asset_request_product?.sub_category_name}}</td>
                        <td><span class="badge {{request?.status == 'Pending' ? 'badge-warning' : request?.status == 'Approved' ? 'badge-success' : 'badge-danger'}}  py-1 px-3">{{request?.status}}</span></td>
                        <td><i class="icon-eye link-primary fs-18" (click)="viewData=request;viewDetails = true;assetType='New'"></i></td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ngb-pagination *ngIf="requestitem?.requests?.new_assets_inbox?.length>pageSize1" class="d-flex justify-content-end"
        [collectionSize]="requestitem?.requests?.new_assets_inbox?.length" [(page)]="page1" [maxSize]="3" [rotate]="true"
        [ellipses]="false" [boundaryLinks]="true" [pageSize]="pageSize1"></ngb-pagination>
    </ng-container> -->

    <!-- Return Section -->

    <!-- <ng-container *ngIf="requestitem?.requests?.return_assets_inbox?.length > 0">
      <div class="col-12">
        <div class="card card-c2">
          <div class="card-body  ">
            <div class="d-flex">
              <p class="mb-0 title">Return Assets</p>
              <span class="ms-auto"><i class="icon-caret-down-fill fs-6"></i></span>
            </div>
          </div>
          <div class="card-body collapse-target ">
            <div class="card card-c2 p-0">
              <div class="table-responsive scrollbar-10 radius-4">
                <table class="table sticky-header table-striped table-sm td-white-space td-width-15ch sticky-first-col sticky-last-col">
                  <thead>
                    <tr>
                      <th scope="col" class="form-cell"><input class="form-check-input m-0" type="checkbox" value="" (click)="checkallReturn($event)"
                        [checked]="checkAllSelectedReturn()"></th>
                      <th class="fw-600 text-uppercase" scope="col">category</th>
                      <th class="fw-600 text-uppercase" scope="col">Subcategory</th>
                      <th class="fw-600 text-uppercase" scope="col">Asset Name</th>
                      <th class="fw-600 text-uppercase" scope="col">Status</th>
                      <th class="fw-600 text-uppercase" scope="col">view</th>
                    </tr>
                  </thead>
                  <tbody class="cell-16 ">
                    <ng-container *ngFor="let return of $any(requestitem?.requests?.return_assets_inbox | slice: (page2-1) * pageSize2 : page2 * pageSize2),let j = index">
                      <tr>
                        <td scope="col" class="form-cell"><input [disabled]="return?.status == 'Invalid'" class="form-check-input m-0" type="checkbox" value="" [(ngModel)]="return.checked">
                        </td>
                        <td>{{return?.asset_request_product?.category_name}}</td>
                        <td>{{return?.asset_request_product?.sub_category_name}}</td>
                        <td>{{return?.asset_request_product?.product_name}}</td>
                        <td>
                          <div class="hstack gap-8 align-items-center">
                            <span class="badge {{return?.status == 'Pending' ? 'badge-warning' : return?.status == 'Approved' ? 'badge-success' : return?.status == 'Invalid' ? 'badge-danger1' : 'badge-danger'}} py-1 px-3">{{return?.status}}</span>
                            <i *ngIf="return?.status == 'Invalid'"
                            class="bi bi-info-circle text-danger fs-12 tooltip-icon"
                            ngbPopover="Request is considered as invalid when asset status is manually updated by the HR."
                            popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave"></i>
                          </div>
                        </td>
                        <td><i class="icon-eye link-primary fs-18" (click)="viewData=return;viewDetails = true;assetType='Return'"></i></td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ngb-pagination *ngIf="requestitem?.requests?.return_assets_inbox?.length>pageSize2" class="d-flex justify-content-end"
        [collectionSize]="requestitem?.requests?.return_assets_inbox?.length" [(page)]="page2" [maxSize]="3" [rotate]="true"
        [ellipses]="false" [boundaryLinks]="true" [pageSize]="pageSize2"></ngb-pagination>
    </ng-container> -->


    <div *ngIf="!requestitem?.achieved_status" class="col-12" [formGroup]="form">
      <textarea name="" id="" rows="3" placeholder="{{this.messageService.descriptiontext('comment')}}" formControlName="comment" [ngClass]="{ 'is-invalid': f.comment.errors}" class="form-control textarea-comment"></textarea>
      <div *ngIf="f.comment.errors?.maxlength" class="invalid-feedback">
        {{this.messageService.fieldlengthvalidation('word','500')}}</div>
    </div>

    <div *ngIf="!requestitem?.achieved_status" class="col-12 d-flex gap-16 py-16 position-sticky bottom-0 bg-white" style="z-index: 11;">
      <div class="d-flex gap-16">
        <p class="mb-0 d-flex align-items-center gap-6">
          <span class="fw-600">{{countSelected()}}</span>
          <span class="fs-14">Requests selected</span>
        </p>
        <button class="btn btn-outline-danger btn-icon fs-14 radius-2" [disabled]="countSelected()==0" (click)="approveReject('Rejected')"> <i class="icon-close-circle"></i>
          Reject</button>
        <button class="btn btn-outline-success btn-icon fs-14 radius-2" [disabled]="countSelected()==0" (click)="approveReject('Approved')"> <i class="icon-check-circle"></i>
          Approve</button>
      </div>
      <button class=" btn btn-primary text-uppercase ms-auto btn-sm fw-400" (click)="confirm()">Submit</button>
    </div>
  </div>
</div>

<!-- View -->
<div class="side-panel" style="--sidepanel-width:40.625rem;" [class.side-pane-active]='viewDetails === true'>
  <div class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>View</h5>
      <a class="toggle-panel" (click)="viewDetails = false"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <div class="row row-16">
          <div class="col-12">
            <div class="mb-0 fw-500">ASSET REQUEST</div>
          </div>
          <div class="col-12">
            <div class="card details-card shadow-0 px-24 pb-24 pt-16">
              <div class="row row-16">
                <div class="col-12">
                  <div>
                    <div class="row row-24">
                      <div class="col-12">
                        <span class="title">{{assetType}} ASSET DETAILS</span>
                      </div>
                      <ng-container *ngIf="assetType == 'Return'">
                        <div class="col-sm-4 col-12">
                          <div class="detail-title">Asset name</div>
                          <div class="detail-desc">
                            {{viewData?.asset_request_product?.product_name}}
                          </div>
                        </div>
                        <div class="col-sm-4 col-12">
                          <div class="detail-title">Serial no</div>
                          <div class="detail-desc">
                            {{viewData?.asset_request_product?.serial_number}}
                          </div>
                        </div>
                      </ng-container>
                      <div class="col-sm-4 col-12">
                        <div class="detail-title">Category</div>
                        <div class="detail-desc">
                          {{viewData?.asset_request_product?.category_name}}
                        </div>
                      </div>
                      <div class="col-sm-4 col-12">
                        <div class="detail-title">Subcategory</div>
                        <div class="detail-desc">
                          {{viewData?.asset_request_product?.sub_category_name}}
                        </div>
                      </div>
                      <div class="col-sm-4 col-12">
                        <div class="detail-title">Status</div>
                        <div class="detail-desc">
                        <div class="fs-14 px-2 fw-500 badge3 {{viewData?.status == 'Pending' ? 'badge-warning' : viewData?.status == 'Approved' ? 'badge-success' : viewData?.status == 'Invalid' ? 'badge-danger1' : 'badge-danger'}}">
                          {{viewData?.status}}
                        </div>
                        </div>
                      </div>
                      <div *ngIf="(viewData?.asset_request_product?.asset_reason != null && viewData?.asset_request_product?.asset_reason != '')" class="col-sm-12 col-12">
                        <div class="detail-title">Reason</div>
                        <div class="detail-desc">
                          {{viewData?.asset_request_product?.asset_reason}}
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- View Status -->
<ng-container *ngIf="viewstatusPanel">
  <div class="side-panel" style="--sidepanel-width:32.1875rem;" [class.side-pane-active]='viewstatusPanel === true'>
      <form class="side-panel-container" autocomplete="off">
          <header class="side-panel-head">
              <h5>Activities</h5>
              <a class="toggle-panel" (click)="viewstatusPanel = false"><i class="icon-close-lg fs-16"></i></a>
          </header>
          <app-view-status [loader]="billLoader" [approvalRequired]="true" [viewStatusData]="viewStatusData"
              [viewStatusLevelCnt]="viewStatusLevelCnt" [viewStatusmaxLevel]="viewStatusmaxLevel" [from]="'asset-inbox'"></app-view-status>
      </form>
  </div>
</ng-container>
<!-- End -->
