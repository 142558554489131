import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormBuilder } from '@angular/forms';
import { MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ExpenseHRReportsService } from 'src/app/@core/services/expense-hrreports.service';
import { LeaveReportService } from 'src/app/@core/services/leave-report.service';
import { ReportsService } from 'src/app/@core/services/reports.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { AppService } from 'src/app/app.global';
import * as moment from 'moment';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { ExpenseMainFilterComponent } from './expense-main-filter/expense-main-filter.component';
import { NotificationService } from 'src/app/@core/services/notification.service';
@Component({
  selector: 'app-expense-report-configure',
  templateUrl: './expense-report-configure.component.html',
  styleUrls: ['./expense-report-configure.component.scss']
})
export class ExpenseReportConfigureComponent implements OnInit {

  alertShow                         = true;
  reportName: any                   = '';
  reportId: any                     = 0;
  closeControl: any;
  callReportVal: any;
  responseLength: any;
  headerCheck: any;
  checkList: any;
  dataSource: any                   = [];
  employeeDropdown: any             = [];
  displayedColumns: any             = [];
  fullData: any                     = [];
  columnOptions: any                = [];
  excelHeaders: string[]            = [];
  templateToExcel: string[][]       = [];
  checkColumn: any                  = [];
  columns1: string[]                = [];
  columns2: string[]                = [];
  columnOptions1: any               = [];
  expensePolicyDropdown: any        = [];
  advancePolicyDropdown: any        = [];
  defaultHeaderList: any            = [];
  applyFilter                       = false;
  viewDetail                        = false;
  resetFilter                       = false;
  scheduleReport                    = false;
  addColumnFilter                   = false;
  nodata                            = false;
  loader                            = true;
  disabled                          = false;
  columnsControl                    = new FormControl();
  columnApplied: boolean            = false;
  columnChangedArr : any = []
  fileType                          = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  fileExtension                     = '.xlsx';

  //Filter Memorize Variables
  resetReportName: any;
  resDataFilter: any;
  hasChangeCount: any               = 0;
  dataFilter: any                   = [];
  header_filter: any                = [];
  resHeader_filter: any             = [];
  filterDisable                     = false;
  is_colfilter                      = false;
  submitted                         = false;
  filterSubmit                      = false;
  checkFilterSubmit                 = false;
  saveFilterClicked                 = false;
  restFilterSave                    = false;

  event: any;
  contrlName: any;
  val: any;
  type: any;
  form: any;
  dateSetup: any;
  dateSplit: any;

  //Tag Filter Variables
  appliedFilter: any                = [];
  tagMultiData: any                 = [];
  tagMultiCnt: any                  = 0;

  //SCHEDULE REPORT MEMORIZE VARIABLES
  isFilter                          = false;
  scheduleText: any                 = '';

  // Pagination
  limit                             = 20;
  offset                            = 0;
  page                              = 1;
  lastPage: any
  initialLoaded: boolean            = true;
  pageNumber: any                   = 1;
  pageResetElseFlag                 = false;
  resetOffset                       = false;
  ordering                          = 'employee_code';

  // test start
  childFilterSubmit: boolean        = false;
  filterFormData: any               = '';
  callemp: boolean                  = false;
  // test end

  emptypeDD = [
    { id: "Contractual", name: "Contractual" },
    { id: "Consultant", name: "Consultant" },
    { id: "Permanent", name: "Permanent" },
    { id: "Trainee", name: "Trainee" },
    { id: "Wages", name: "Wages" },
  ];
  empstatusDD = [
    { id: 0, name: "Probation" },
    { id: 1, name: "Confirmed" },
    { id: 2, name: "Relieved" },
    { id: 3, name: "Resigned" },
    { id: 4, name: "Settled" },
    { id: 5, name: "Terminated" },
  ];

  companyDD: any = [{
    id: 0,
    company_name: ''
  }];

  buDD = [{
    id: 0,
    name: ''
  }];

  DepDD = [{
    id: 0,
    name: ''
  }];

  DesignationDD = [{
    id: 0,
    name: ''
  }];

  countryDD = [{
    id: 0,
    name: ''
  }];

  stateDD = [{
    id: 0,
    name: ''
  }];

  BranchDD = [{
    id: 0,
    name: ''
  }];

  cityDD = [{
    id: 0,
    name: ''
  }];

  gradeDD = [{
    id: 0,
    name: ''
  }];

  columnsArr: any = [] // for store optional headers

  // Test Form
  testform = this.formBuilder.group({
    checkbox: [''],
  })

  // Schedule
  scheduleReportFilterMemorizeForm = this.formBuilder.group({
    filter: true,
  });

  // Filter Save Form
  filterSaveForm = this.formBuilder.group({
    filter: true,
  });

  scrollLeft(e: any): void {
    let wrapper = e.srcElement.parentElement.nextSibling;
    wrapper.scrollLeft -= 150;
  }
  scrollRight(e: any): void {
    let wrapper = e.srcElement.parentElement.nextSibling;
    wrapper.scrollLeft += 150;
  }

  OuFilterForm = this.formBuilder.group({
    company          : [''],
    bu               : [''],
    branch           : [''],
    designation      : [''],
    department       : [''],
    employee_list    : [''],
    grade            : [''],
    employee_type    : [''],
    employment_status: [''],
  })

  expenseMainForm = this.formBuilder.group({
    expense_policy: [''],
    advance_policy: [''],
    reimb_type    : [''],
    ouFilterForm  : this.OuFilterForm
  })

  hint_dict:any;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  @ViewChild(ExpenseMainFilterComponent, { static: false }) expenseMainComp !: ExpenseMainFilterComponent;


  constructor(
    @Inject(MAT_DATE_FORMATS) public matDateFormat: MatDateFormats,
    public appservice: AppService,
    public route: ActivatedRoute,
    private reportService: ReportsService,
    public router: Router,
    private formBuilder: FormBuilder,
    public expenseServ: ExpenseHRReportsService,
    public cd: ChangeDetectorRef,
    private roleService: RoleService,
    private leaveService: LeaveReportService,
    private notify : NotificationService
  ) { }

  ngOnInit(): void {
    this.childFilterSubmit = true;
    // Date Format
    this.getDateFormat();
    // Redirection
    this.route.params.subscribe((params: Params) => {
      if (!isNaN(params['id'])) {
        this.reportId = params['id'];
      }
      // Expense Advance Report
      if (params['name'] == 'Expense Advance') {
        this.reportName = 'Expense Advance'
        this.expenseAdvanceReport();
      }
      // Expense Report - Amount
      if (params['name'] == 'Expense Report - Amount') {
        this.reportName = 'Expense Report - Amount'
        this.expenseReport();
      }
      // Expense Report - Amount
      if (params['name'] == 'Expense Report - Mileage') {
        this.reportName = 'Expense Report - Mileage'
        this.expenseMileageReport();
      }
      // Expense Payment
      if (params['name'] == 'Expense Payment Register') {
        this.reportName = 'Expense Payment Register'
        this.expensePaymentReport();
      }
      // Expense Receipt
      if (params['name'] == 'Expense Receipt Details') {
        this.reportName = 'Expense Receipt Details'
        this.expenseReceiptReport();
      }
      // Expense Outstanding
      if (params['name'] == 'Outstanding Advance Report') {
        this.reportName = 'Outstanding Advance Report'
        this.expenseOutstandingReport();
      }
      // Expense Report Details
      if (params['name'] == 'Expense Report Details') {
        this.reportName = 'Expense Report Details'
        this.expenseDetailsReport();
      }
    })
  }

  dateFormat = '';

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort      = this.sort;
    this.getCompanyList();
    this.getBUList();
    this.getDepList();
    this.getDesignationList();
    this.getGradeList();
    this.getBranchList();
    this.getEmployeeList();
    this.getExpensePolicyList();
    this.getAdvancePolicyList();
    this.selectAllForDropdownItems(this.empstatusDD);
    this.selectAllForDropdownItems(this.emptypeDD);
  }


  // Expense Advance Report
  expenseAdvanceReport() {
    this.loader        = true;
    this.reportName    = "Expense Advance";
    this.appliedFilter = [];
    this.offset        = this.resetOffset ? 0 : this.offset;
    this.page          = this.resetOffset ? 1 : this.page;

    this.expenseServ.expenseAdvanceReport(this.limit, this.offset, this.ordering, this.filterFormData, this.childFilterSubmit).subscribe((res: any) => {
      if (res?.data?.length != 0) {
        this.dataSource = res?.data;
        this.lastPage   = res?.count
        // Formating as per standard for dates
        if (this.dataSource?.length > 0) {
          for (let i = 0; i < res.data?.length; i++) {
            this.dataSource[i]['Full name']         = res?.data[i]['Employee name'];
            this.dataSource[i]['Approved on']       = this.appservice.dateFormatDisplay(res?.data[i]['Approved on'])
            this.dataSource[i]['Dispensed on']      = this.appservice.dateFormatDisplay(res?.data[i]['Dispensed on'])
            this.dataSource[i]['Employment status'] = res?.data[i]['Employment Status'];
          }
        }
        this.dataSource = new MatTableDataSource(this.dataSource);
        this.setDataSourceAttributes();
      }

      // if(this.childFilterSubmit){
        this.resDataFilter     = res?.data_filter;
        this.resHeader_filter  = res?.header_filter;
      // }

      this.defaultHeaderList = res?.header_list?.slice()
      this.hint_dict         = res?.hint_dict ? res?.hint_dict : {};

      if (res?.hasOwnProperty('applied_filter'))
        this.appliedFilter = res.applied_filter;

      this.columns2      = res['check_list'];
      this.columnOptions = this.columns2;
      this.columnsControl.setValue(this.columnOptions1);
      this.nodata        = res?.data?.length == 0 ? true : false;

      // /**dynamic data table**/
      if (!this.pageChangeDetect && !this.resetOffset) {
        if (this.resHeader_filter?.length != 0)
          this.dynamicHeader(res.data?.length, this.resHeader_filter, res['check_list']);
        else
          this.dynamicHeader(res.data?.length, res['header_list'], res['check_list']);
      }

      /**Filter data**/
      if (this.resDataFilter != null && this.resDataFilter != undefined) {
        if (!!Object.keys(this.resDataFilter)?.length == true) {
          this.expenseMainForm.reset(this.resDataFilter);
          this.OuFilterForm.reset(this.resDataFilter)
          if (this.resDataFilter?.company?.length > 0) {
            this.getBUList();
            if (this.resDataFilter?.bu?.length > 0)
              this.OuFilterForm.get('bu')?.setValue(this.resDataFilter.bu);
          }
          if(this.resDataFilter?.emp_status.length > 0){
            this.OuFilterForm.get('employment_status')?.setValue(this.resDataFilter.emp_status)
          }
          if(this.resDataFilter?.emp_type.length > 0){
            this.OuFilterForm.get('employee_type')?.setValue(this.resDataFilter.emp_type);
          }
        }
      }

      /**Header filter data**/
      if (!this.pageChangeDetect && !this.resetOffset) {
        if (this.resHeader_filter?.length != 0)
          this.headerFilterImplementation(this.resHeader_filter);
        else
          this.headerFilterImplementation(res?.header_list);
      }

      this.checkListElements();
      this.resetOffset      = false;
      this.pageChangeDetect = false;
      this.checkChange      = true;
      this.headerFilterApply();
      this.loader           = false;
    })
  }

  // Expense Report - Amount
  expenseReport() {
    this.loader        = true;
    this.reportName    = "Expense Report - Amount";
    this.appliedFilter = [];
    this.offset        = this.resetOffset ? 0 : this.offset;
    this.page          = this.resetOffset ? 1 : this.page;

    this.expenseServ.expenseReport(this.limit, this.offset, this.ordering, this.filterFormData, this.childFilterSubmit).subscribe((res: any) => {
      if (res?.data?.length != 0) {
        this.dataSource = res?.data;
        this.lastPage   = res?.count;
        // Formating as per standard for dates
        if (this.dataSource?.length > 0) {
          for (let i = 0; i < res.data?.length; i++) {
            // this.dataSource[i]['Full name']         = res?.data[i]['Employee name'];
            this.dataSource[i]['Date']              = this.appservice.dateFormatDisplay(res?.data[i]['Date'])
            this.dataSource[i]['Created date']      = this.appservice.dateFormatDisplay(res?.data[i]['Created date'])
            this.dataSource[i]['Dispensed on']      = this.appservice.dateFormatDisplay(res?.data[i]['Dispensed on'])
            this.dataSource[i]['From location']     = res?.data[i]['From Location'];
            this.dataSource[i]['To location']       = res?.data[i]['To location'];
            this.dataSource[i]['Employment status'] = res?.data[i]['Employment Status'];
            this.dataSource[i]['Employee Name']     = res?.data[i]['Employee name'];
          }
        }
        this.dataSource = new MatTableDataSource(this.dataSource);
        this.setDataSourceAttributes();
      }

      // if(this.childFilterSubmit){
        this.resDataFilter     = res?.data_filter;
        this.resHeader_filter  = res?.header_filter;
      // }

      this.defaultHeaderList   = res?.header_list?.slice();
      this.hint_dict           = res?.hint_dict ? res?.hint_dict : {}

      if (res?.hasOwnProperty('applied_filter'))
        this.appliedFilter = res.applied_filter;

      this.columns2      = res['check_list'];
      this.columnOptions = this.columns2;
      this.columnsControl.setValue(this.columnOptions1);
      this.nodata        = res?.data?.length == 0 ? true : false;

      // /**dynamic data table**/
      if (!this.pageChangeDetect && !this.resetOffset) {
        if (this.resHeader_filter?.length != 0)
          this.dynamicHeader(res.data?.length, this.resHeader_filter, res['check_list']);
        else
          this.dynamicHeader(res.data?.length, res['header_list'], res['check_list']);
      }

      /**Filter data**/
      if (this.resDataFilter != null && this.resDataFilter != undefined) {
        if (!!Object.keys(this.resDataFilter)?.length == true) {
          this.expenseMainForm.reset(this.resDataFilter);
          this.OuFilterForm.reset(this.resDataFilter)
          if (this.resDataFilter?.company?.length > 0) {
            this.getBUList();
            if (this.resDataFilter?.bu?.length > 0)
              this.OuFilterForm.get('bu')?.setValue(this.resDataFilter.bu);
          }
          if(this.resDataFilter?.emp_status.length > 0){
            this.OuFilterForm.get('employment_status')?.setValue(this.resDataFilter.emp_status)
          }
          if(this.resDataFilter?.emp_type.length > 0){
            this.OuFilterForm.get('employee_type')?.setValue(this.resDataFilter.emp_type);
          }
        }
      }

      /**Header filter data**/
      if (!this.pageChangeDetect && !this.resetOffset) {
        if (this.resHeader_filter?.length != 0)
          this.headerFilterImplementation(this.resHeader_filter)
        else
          this.headerFilterImplementation(res?.header_list)
      }

      this.checkListElements();
      this.resetOffset      = false;
      this.pageChangeDetect = false;
      this.checkChange      = true;
      this.headerFilterApply();
      this.loader           = false;
    })
  }

  // Expense Report - Mileage
  expenseMileageReport() {
    this.loader        = true;
    this.reportName    = "Expense Report - Mileage";
    this.appliedFilter = [];
    this.offset        = this.resetOffset ? 0 : this.offset
    this.page          = this.resetOffset ? 1 : this.page

    this.expenseServ.expenseMileageReport(this.limit, this.offset, this.ordering, this.filterFormData, this.childFilterSubmit).subscribe((res: any) => {
      if (res?.data?.length != 0) {
        this.dataSource = res?.data;
        this.lastPage   = res?.count
        // Formating as per standard for dates
        if (this.dataSource?.length > 0) {
          for (let i = 0; i < res.data?.length; i++) {
            // this.dataSource[i]['Full name']         = res?.data[i]['Employee name'];
            this.dataSource[i]['Employment status'] = res?.data[i]['Employment Status'];
            this.dataSource[i]['Date']              = this.appservice.dateFormatDisplay(res?.data[i]['Date'])
            this.dataSource[i]['From location']     = res?.data[i]['From Location'];
            this.dataSource[i]['To location']       = res?.data[i]['To location'];
            this.dataSource[i]['Created date']      = this.appservice.dateFormatDisplay(res?.data[i]['Created date'])
            this.dataSource[i]['Employee Name']     = res?.data[i]['Employee name'];
          }
        }
        this.dataSource = new MatTableDataSource(this.dataSource);
        this.setDataSourceAttributes();
      }

      // if(this.childFilterSubmit){
        this.resDataFilter     = res?.data_filter;
        this.resHeader_filter  = res?.header_filter;
      // }

      this.defaultHeaderList = res?.header_list?.slice();
      this.hint_dict         = res?.hint_dict ? res?.hint_dict : {}

      if (res?.hasOwnProperty('applied_filter'))
        this.appliedFilter = res.applied_filter;

      this.columns2      = res['check_list'];
      this.columnOptions = this.columns2;
      this.columnsControl.setValue(this.columnOptions1);
      this.nodata        = res?.data?.length == 0 ? true : false;

      // /**dynamic data table**/
      if (!this.pageChangeDetect && !this.resetOffset) {
        if (this.resHeader_filter?.length != 0)
          this.dynamicHeader(res.data?.length, this.resHeader_filter, res['check_list']);
        else
          this.dynamicHeader(res.data?.length, res['header_list'], res['check_list']);
      }
      /**Filter data**/
      if (this.resDataFilter != null && this.resDataFilter != undefined) {
        if (!!Object.keys(this.resDataFilter)?.length == true) {
          this.expenseMainForm.reset(this.resDataFilter);
          this.OuFilterForm.reset(this.resDataFilter)
          if (this.resDataFilter?.company?.length > 0) {
            this.getBUList();
            if (this.resDataFilter?.bu?.length > 0)
              this.OuFilterForm.get('bu')?.setValue(this.resDataFilter.bu);
          }
          if(this.resDataFilter?.emp_status.length > 0){
            this.OuFilterForm.get('employment_status')?.setValue(this.resDataFilter.emp_status)
          }
          if(this.resDataFilter?.emp_type.length > 0){
            this.OuFilterForm.get('employee_type')?.setValue(this.resDataFilter.emp_type);
          }
        }
      }

      /**Header filter data**/
      if (!this.pageChangeDetect && !this.resetOffset) {
        if (this.resHeader_filter?.length != 0)
          this.headerFilterImplementation(this.resHeader_filter)
        else
          this.headerFilterImplementation(res?.header_list)
      }

      this.checkListElements();
      this.resetOffset      = false;
      this.pageChangeDetect = false;
      this.checkChange      = true;
      this.headerFilterApply();
      this.loader           = false;
    })
  }

  // Expense Payment
  expensePaymentReport() {
    this.loader        = true;
    this.reportName    = "Expense Payment Register";
    this.appliedFilter = [];
    this.offset        = this.resetOffset ? 0 : this.offset;
    this.page          = this.resetOffset ? 1 : this.page;

    this.expenseServ.expensePaymentReport(this.limit, this.offset, this.ordering, this.filterFormData, this.childFilterSubmit).subscribe((res: any) => {
      if (res?.data?.length != 0) {
        this.dataSource = res?.data;
        this.lastPage   = res?.count;
        // Formating as per standard for dates
        if (this.dataSource?.length > 0) {
          for (let i = 0; i < res.data?.length; i++) {
            this.dataSource[i]['Full name']         = res?.data[i]['Employee name'];
            this.dataSource[i]['Paid on']           = this.appservice.dateFormatDisplay(res?.data[i]['Paid on'])
            this.dataSource[i]['Employment status'] = res?.data[i]['Employment Status'];
            const [start, end]                      = res?.data[i]['Duration'].split(' - ');
            this.dataSource[i]['Duration']          = start == '' ? '' : `${moment(start).format(this.dateFormat)} - ${moment(end).format(this.dateFormat)}`
          }
        }
        this.dataSource = new MatTableDataSource(this.dataSource);
        this.setDataSourceAttributes();
      }

      // if(this.childFilterSubmit){
        this.resDataFilter     = res?.data_filter;
        this.resHeader_filter  = res?.header_filter;
      // }

      this.defaultHeaderList    = res?.header_list?.slice();
      this.hint_dict            = res?.hint_dict ? res?.hint_dict : {}

      if (res?.hasOwnProperty('applied_filter'))
        this.appliedFilter = res.applied_filter;

      this.columns2      = res['check_list'];
      this.columnOptions = this.columns2;
      this.columnsControl.setValue(this.columnOptions1);
      this.nodata        = res?.data?.length == 0 ? true : false;

      // /**dynamic data table**/
      if (!this.pageChangeDetect && !this.resetOffset) {
        if (this.resHeader_filter?.length != 0)
          this.dynamicHeader(res.data?.length, this.resHeader_filter, res['check_list']);
        else
          this.dynamicHeader(res.data?.length, res['header_list'], res['check_list']);
      }

      /**Filter data**/
      if (this.resDataFilter != null && this.resDataFilter != undefined) {
        if (!!Object.keys(this.resDataFilter)?.length == true) {
          this.expenseMainForm.reset(this.resDataFilter);
          this.OuFilterForm.reset(this.resDataFilter)
          if (this.resDataFilter?.company?.length > 0) {
            this.getBUList();
            if (this.resDataFilter?.bu?.length > 0)
              this.OuFilterForm.get('bu')?.setValue(this.resDataFilter.bu);
          }
          if(this.resDataFilter?.emp_status.length > 0){
            this.OuFilterForm.get('employment_status')?.setValue(this.resDataFilter.emp_status)
          }
          if(this.resDataFilter?.emp_type.length > 0){
            this.OuFilterForm.get('employee_type')?.setValue(this.resDataFilter.emp_type);
          }
        }
      }

      /**Header filter data**/
      if (!this.pageChangeDetect && !this.resetOffset) {
        if (this.resHeader_filter?.length != 0)
          this.headerFilterImplementation(this.resHeader_filter)
        else
          this.headerFilterImplementation(res?.header_list)
      }

      this.checkListElements();
      this.resetOffset      = false;
      this.pageChangeDetect = false;
      this.checkChange      = true;
      this.headerFilterApply();
      this.loader           = false;
    })
  }

  // Expense Receipt Details
  expenseReceiptReport() {
    this.loader        = true;
    this.reportName    = "Expense Receipt Details";
    this.appliedFilter = [];
    this.offset        = this.resetOffset ? 0 : this.offset
    this.page          = this.resetOffset ? 1 : this.page

    this.expenseServ.expenseReceiptReport(this.limit, this.offset, this.ordering, this.filterFormData, this.childFilterSubmit).subscribe((res: any) => {
      if (res?.data?.length != 0) {
        this.dataSource = res?.data;
        this.lastPage   = res?.count
        // Formating as per standard for dates
        if (this.dataSource?.length > 0) {
          for (let i = 0; i < res.data?.length; i++) {
            this.dataSource[i]['Full name']         = res?.data[i]['Employee name'];
            this.dataSource[i]['Dispensed on']      = this.appservice.dateFormatDisplay(res?.data[i]['Dispensed on'])
            this.dataSource[i]['Employment status'] = res?.data[i]['Employment Status'];
            this.dataSource[i]['Date']              = this.appservice.dateFormatDisplay(res?.data[i]['Date'])
            this.dataSource[i]['Created date']      = this.appservice.dateFormatDisplay(res?.data[i]['Created date'])
          }
        }
        this.dataSource = new MatTableDataSource(this.dataSource);
        this.setDataSourceAttributes();
      }

      // if(this.childFilterSubmit){
        this.resDataFilter     = res?.data_filter;
        this.resHeader_filter  = res?.header_filter;
      // }

      this.defaultHeaderList    = res?.header_list?.slice();
      this.hint_dict            = res?.hint_dict ? res?.hint_dict : {}

      if (res?.hasOwnProperty('applied_filter'))
        this.appliedFilter = res.applied_filter;

      this.columns2      = res['check_list'];
      this.columnOptions = this.columns2;
      this.columnsControl.setValue(this.columnOptions1);
      this.nodata        = res?.data?.length == 0 ? true : false;

      // /**dynamic data table**/
      if (!this.pageChangeDetect && !this.resetOffset) {
        if (this.resHeader_filter?.length != 0)
          this.dynamicHeader(res.data?.length, this.resHeader_filter, res['check_list']);
        else
          this.dynamicHeader(res.data?.length, res['header_list'], res['check_list']);
      }

      /**Filter data**/
      if (this.resDataFilter != null && this.resDataFilter != undefined) {
        if (!!Object.keys(this.resDataFilter)?.length == true) {
          this.expenseMainForm.reset(this.resDataFilter);
          this.OuFilterForm.reset(this.resDataFilter)
          if (this.resDataFilter?.company?.length > 0) {
            this.getBUList();
            if (this.resDataFilter?.bu?.length > 0)
              this.OuFilterForm.get('bu')?.setValue(this.resDataFilter.bu);
          }
          if(this.resDataFilter?.emp_status.length > 0){
            this.OuFilterForm.get('employment_status')?.setValue(this.resDataFilter.emp_status)
          }
          if(this.resDataFilter?.emp_type.length > 0){
            this.OuFilterForm.get('employee_type')?.setValue(this.resDataFilter.emp_type);
          }
        }
      }

      /**Header filter data**/
      if (!this.pageChangeDetect && !this.resetOffset) {
        if (this.resHeader_filter?.length != 0)
          this.headerFilterImplementation(this.resHeader_filter)
        else
          this.headerFilterImplementation(res?.header_list)
      }

      this.checkListElements();
      this.resetOffset      = false;
      this.pageChangeDetect = false;
      this.checkChange      = true;
      this.headerFilterApply();
      this.loader           = false;
    })
  }

  // Expense Outstanding
  expenseOutstandingReport() {
    this.loader        = true;
    this.reportName    = "Outstanding Advance Report";
    this.appliedFilter = [];
    this.offset        = this.resetOffset ? 0 : this.offset;
    this.page          = this.resetOffset ? 1 : this.page;

    this.expenseServ.expenseOutstandingReport(this.limit, this.offset, this.ordering, this.filterFormData, this.childFilterSubmit).subscribe((res: any) => {
      if (res?.data?.length != 0) {
        this.dataSource = res?.data;
        this.lastPage   = res?.count
        // Formating as per standard for dates
        if (this.dataSource?.length > 0) {
          for (let i = 0; i < res.data?.length; i++) {
            this.dataSource[i]['Full name']                          = res?.data[i]['Employee name'];
            this.dataSource[i]['Employment status']                  = res?.data[i]['Employment Status'];
            this.dataSource[i]['Outstanding expense advance amount'] = res?.data[i]['Outstanding']
          }
        }
        this.dataSource = new MatTableDataSource(this.dataSource);
        this.setDataSourceAttributes();
      }

      // if(this.childFilterSubmit){
        this.resDataFilter     = res?.data_filter;
        this.resHeader_filter  = res?.header_filter;
      // }

      this.defaultHeaderList    = res?.header_list?.slice()
      this.hint_dict            = res?.hint_dict ? res?.hint_dict : {}

      if (res?.hasOwnProperty('applied_filter'))
        this.appliedFilter = res.applied_filter;

      this.columns2      = res['check_list'];
      this.columnOptions = this.columns2;
      this.columnsControl.setValue(this.columnOptions1);
      this.nodata        = res?.data?.length == 0 ? true : false;

      // /**dynamic data table**/
      if (!this.pageChangeDetect && !this.resetOffset) {
        if (this.resHeader_filter?.length != 0)
          this.dynamicHeader(res.data?.length, this.resHeader_filter, res['check_list']);
        else
          this.dynamicHeader(res.data?.length, res['header_list'], res['check_list']);
      }

      /**Filter data**/
      if (this.resDataFilter != null && this.resDataFilter != undefined) {
        if (!!Object.keys(this.resDataFilter)?.length == true) {
          this.expenseMainForm.reset(this.resDataFilter);
          this.OuFilterForm.reset(this.resDataFilter)
          if (this.resDataFilter?.company?.length > 0) {
            this.getBUList();
            if (this.resDataFilter?.bu?.length > 0)
              this.OuFilterForm.get('bu')?.setValue(this.resDataFilter.bu);
          }
          if(this.resDataFilter?.emp_status?.length > 0){
            this.OuFilterForm.get('employment_status')?.setValue(this.resDataFilter.emp_status)
          }
          if(this.resDataFilter?.emp_type?.length > 0){
            this.OuFilterForm.get('employee_type')?.setValue(this.resDataFilter.emp_type);
          }
        }
        // else {
        //   Object.keys(this.OuFilterForm.controls).forEach(key => {
        //     this.OuFilterForm.get(key)?.setValue('');
        //   });
        // }
      }

      /**Header filter data**/
      if (!this.pageChangeDetect && !this.resetOffset) {
        if (this.resHeader_filter?.length != 0)
          this.headerFilterImplementation(this.resHeader_filter)
        else
          this.headerFilterImplementation(res?.header_list)
      }

      this.checkListElements();
      this.resetOffset      = false;
      this.pageChangeDetect = false;
      this.checkChange      = true;
      this.headerFilterApply();
      this.loader           = false;
    })
  }

  // Expense Report Details
  expenseDetailsReport() {
    this.loader        = true;
    this.reportName    = "Expense Report Details";
    this.appliedFilter = [];
    this.offset        = this.resetOffset ? 0 : this.offset;
    this.page          = this.resetOffset ? 1 : this.page;

    this.expenseServ.expenseDetailsReport(this.limit, this.offset, this.ordering, this.filterFormData, this.childFilterSubmit).subscribe((res: any) => {
      if (res?.data?.length != 0) {
        this.dataSource = res?.data;
        this.lastPage   = res?.count;
        // Formating as per standard for dates
        if (this.dataSource?.length > 0) {
          for (let i = 0; i < res.data?.length; i++) {
            this.dataSource[i]['Full name']         = res?.data[i]['Employee name'];
            this.dataSource[i]['Employment status'] = res?.data[i]['Employment Status'];
            const [start, end]                      = res?.data[i]['Duration'] == undefined ? '-' : res?.data[i]['Duration'].split(' - ');
            this.dataSource[i]['Duration']          = start == '' ? '' : `${moment(start).format(this.dateFormat)} - ${moment(end).format(this.dateFormat)}`
          }
        }
        this.dataSource = new MatTableDataSource(this.dataSource);
        this.setDataSourceAttributes();
      }

      // if(this.childFilterSubmit){
        this.resDataFilter     = res?.data_filter;
        this.resHeader_filter  = res?.header_filter;
      // }

      this.defaultHeaderList = res?.header_list?.slice();
      this.hint_dict         = res?.hint_dict ? res?.hint_dict : {}

      if (res?.hasOwnProperty('applied_filter'))
        this.appliedFilter = res.applied_filter;

      this.columns2      = res['check_list'];
      this.columnOptions = this.columns2;
      this.columnsControl.setValue(this.columnOptions1);
      this.nodata        = res?.data?.length == 0 ? true : false;

      // /**dynamic data table**/
      if (!this.pageChangeDetect && !this.resetOffset) {
        if (this.resHeader_filter?.length != 0)
          this.dynamicHeader(res.data?.length, this.resHeader_filter, res['check_list']);
        else
          this.dynamicHeader(res.data?.length, res['header_list'], res['check_list']);
      }

      /**Filter data**/
      if (this.resDataFilter != null && this.resDataFilter != undefined) {
        if (!!Object.keys(this.resDataFilter)?.length == true) {
          this.expenseMainForm.reset(this.resDataFilter);
          this.OuFilterForm.reset(this.resDataFilter)
          if (this.resDataFilter?.company?.length > 0) {
            this.getBUList();
            if (this.resDataFilter?.bu?.length > 0)
              this.OuFilterForm.get('bu')?.setValue(this.resDataFilter.bu);
          }
          if(this.resDataFilter?.emp_status.length > 0){
            this.OuFilterForm.get('employment_status')?.setValue(this.resDataFilter.emp_status)
          }
          if(this.resDataFilter?.emp_type.length > 0){
            this.OuFilterForm.get('employee_type')?.setValue(this.resDataFilter.emp_type);
          }
        }
        // else {
        //   Object.keys(this.OuFilterForm.controls).forEach(key => {
        //     this.OuFilterForm.get(key)?.setValue('');
        //   });
        // }
      }

      /**Header filter data**/
      if (!this.pageChangeDetect && !this.resetOffset) {
        if (this.resHeader_filter?.length != 0)
          this.headerFilterImplementation(this.resHeader_filter)
        else
          this.headerFilterImplementation(res?.header_list)
      }

      this.checkListElements();
      this.resetOffset      = false;
      this.pageChangeDetect = false;
      this.checkChange      = true;
      this.headerFilterApply();
      this.loader           = false;
    })
  }

  // Submit after filter applied, getting value from child
  submitDoneOld(value: any) {
    this.appliedFilter = []
    if (value.data?.length != 0) {
      this.dataSource = new MatTableDataSource(value?.data);
      this.lastPage   = value?.count
      this.setDataSourceAttributes();
    }
    this.nodata = value.data?.length == 0 ? true : false;
    if (value.hasOwnProperty('applied_filter')) {
      this.appliedFilter = value.applied_filter;
    }

    if (!this.pageChangeDetect && !this.resetOffset) {
      this.columnsArr    = value['header_list'];
      this.columnOptions = value['check_list'];
      this.dynamicHeader(value.data?.length, this.resHeader_filter, value['check_list']);
      this.checkListElements();
      this.checkChange = true;
      this.headerFilterApply()
    }

    this.pageChangeDetect = false;
    this.viewDetail       = false;
  }

  submitDone(value:any){
    if(this.callemp && Object.keys(this.resDataFilter)?.length > 0){
      this.filterMemorizeFunction('saveFilter','')
    }else{
      this.callemp           = false;
    }
    this.limit           = value.limit;
    this.offset          = value.offset;
    this.filterFormData  = value.filterForm;
    this.filterSubmit = !this.filterFormData?.split('&').every((data:any)=>{
      const [key, value] = data?.split('=')
      if(key === 'ordering'){
        return true;
      }
      return (value === '[]' || value === '');
    })
    this.childFilterSubmit = false;
    this.page              = 1;
    this.pageNumber        = 0;
    this.resetOffset       = true;
    this.callAllReportsFunctions();
    this.pageChangeDetect  = false;
    this.viewDetail        = false;
  }

  callAllReportsFunctions(){
    if(this.reportName == 'Expense Advance')
      this.expenseAdvanceReport();
    else if(this.reportName == 'Expense Report - Amount')
      this.expenseReport();
    else if(this.reportName == 'Expense Report - Mileage')
      this.expenseMileageReport();
    else if(this.reportName == 'Expense Payment Register')
      this.expensePaymentReport();
    else if(this.reportName == 'Expense Receipt Details')
      this.expenseReceiptReport();
    else if(this.reportName == 'Outstanding Advance Report')
      this.expenseOutstandingReport();
    else if(this.reportName == 'Expense Report Details')
      this.expenseDetailsReport();
  }

  // Configurations
  setDataSourceAttributes() {
    if (this.dataSource != undefined && this.dataSource != null) {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort      = this.sort;
    }
  }

  checkListElements() {
    var elements = document.getElementsByName("check");
    setTimeout(() => {
      for (let i = 0; i < elements?.length; i++) {
        var element = <HTMLInputElement>elements[i];
        for (let j = 0; j < this.columns1?.length; j++) {
          if (element.defaultValue == this.columns1[j]) {
            element.disabled = false;
          }
        }
      }
    }, 1000);
  }

  dynamicHeader(data_length: any, header_list: any, check_list: any) {
    // this.nodata           = data_length==0?true:false;
    this.columns1         = header_list.slice();
    this.displayedColumns = this.dupliCateRemove(this.columns1)
    this.columnOptions1   = this.columns1;
  }

  dupliCateRemove(data: any) {
    const uniqueColumnsSet = new Set();
    data.filter((column: any) => {
      if (!uniqueColumnsSet.has(column)) {
        uniqueColumnsSet.add(column);
        return true;
      }
      return false;
    });
    return Array.from(uniqueColumnsSet)
  }

  headerFilterImplementation(headerFilter: any) {
    this.header_filter = [];
    var elements = document.getElementsByName("check");
    setTimeout(() => {
      for (let i1 = 0; i1 < elements?.length; i1++) {
        var elementss = <HTMLInputElement>elements[i1];
        elementss.checked = false;
      }
    }, 100);

    var elements = document.getElementsByName("check");
    setTimeout(() => {
      for (let i = 0; i < elements?.length; i++) {
        var element = <HTMLInputElement>elements[i];
        for (let j = 0; j < headerFilter?.length; j++) {
          if (element.defaultValue == headerFilter[j]) {
            element.checked  = true;
            element.disabled = false;
            this.checkColumn.push(headerFilter[j]);
            this.header_filter.push(headerFilter[j]);
          }
        }
      }
    }, 1000);
    for (let i = 0; i < headerFilter?.length; i++) {
      const element = headerFilter[i];
      if (!this.displayedColumns.includes(element)) {
        this.displayedColumns.push(element);
      }
    }
  }

  // Select ALL option for Multiselect
  selectAllForDropdownItems(items: any[]) {
    let allSelect = (items: any[]) => {
      items.forEach(element => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }

  // Tags Underscore
  removeUnderscoreAddUpperCase(str: any) {
    var i, frags = str.split('_');
    for (i = 0; i < frags?.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
  }

  // Muli tags
  tagMultiDataFunction(data: any) {
    this.tagMultiData = [];
    this.tagMultiCnt  = 0;
    this.tagMultiData.push(data[0]);
    if (data?.length == 1) {
      return this.tagMultiData;
    } else {
      this.tagMultiCnt = '+' + JSON.stringify(data?.length - 1);
      return this.tagMultiData;
    }
  }

  title: any = '';
  // Tag Close
  applyTagClose(rptName: any, controlName: any) {
    this.filterSubmit = true;
    controlName = controlName == 'employment_type' ? 'employee_type' : controlName
    controlName = controlName == 'compnay' ? 'company' : controlName
    controlName = controlName == 'reimbursement_type' ? 'reimb_type' : controlName
    controlName = controlName == 'business_unit' ? 'bu' : controlName
    this.page          = 1;
    this.pageNumber    = 0
    this.callReportVal = true;
    this.closeControl  = controlName;
    this.cd.detectChanges();
    this.expenseMainComp.applyTagClose1();
  }

  // File Export
  export() {
    let limit     = this.lastPage;
    let offset    = 0;
    this.fullData = [];
    // if (this.initialLoaded) {
      this.loader = true;
      if (this.reportName == 'Expense Advance') {
        this.expenseServ.expenseAdvanceReport(limit, offset, this.ordering, this.filterFormData, this.childFilterSubmit).subscribe((res: any) => {
          if (res?.data?.length != 0) {
            this.fullData = res?.data;
            this.lastPage = res?.count
            if (this.fullData?.length > 0) {
              for (let i = 0; i < res.data?.length; i++) {
                this.fullData[i]['Full name']         = res?.data[i]['Employee name'];
                this.fullData[i]['Approved on']       = this.appservice.dateFormatDisplay(res?.data[i]['Approved on'])
                this.fullData[i]['Dispensed on']      = this.appservice.dateFormatDisplay(res?.data[i]['Dispensed on'])
                this.fullData[i]['Employment status'] = res?.data[i]['Employment Status'];
              }
            }
          }
          this.commonLogic(this.fullData)
        })
      }
      if (this.reportName == 'Expense Report - Amount') {
        this.expenseServ.expenseReport(limit, offset, this.ordering, this.filterFormData, this.childFilterSubmit).subscribe((res: any) => {
          if (res?.data?.length != 0) {
            this.fullData = res?.data;
            this.lastPage = res?.count
            // Formating as per standard for dates
            if (this.fullData?.length > 0) {
              for (let i = 0; i < res.data?.length; i++) {
                // this.fullData[i]['Full name']         = res?.data[i]['Employee name'];
                this.fullData[i]['Date']              = this.appservice.dateFormatDisplay(res?.data[i]['Date'])
                this.fullData[i]['Created date']      = this.appservice.dateFormatDisplay(res?.data[i]['Created date'])
                this.fullData[i]['Employment status'] = res?.data[i]['Employment Status'];
                this.fullData[i]['From location']     = res?.data[i]['From Location'];
                this.fullData[i]['To location']       = res?.data[i]['To location'];
                this.fullData[i]['Employee Name']     = res?.data[i]['Employee name'];
              }
            }
          }
          this.commonLogic(this.fullData)
        })
      }
      if (this.reportName == 'Expense Report - Mileage') {
        this.expenseServ.expenseMileageReport(limit, offset, this.ordering, this.filterFormData, this.childFilterSubmit).subscribe((res: any) => {
          if (res?.data?.length != 0) {
            this.fullData = res?.data;
            this.lastPage = res?.count
            // Formating as per standard for dates
            if (this.fullData?.length > 0) {
              for (let i = 0; i < res.data?.length; i++) {
                // this.fullData[i]['Full name']         = res?.data[i]['Employee name'];
                this.fullData[i]['Employment status'] = res?.data[i]['Employment Status'];
                this.fullData[i]['Date']              = this.appservice.dateFormatDisplay(res?.data[i]['Date'])
                this.fullData[i]['From location']     = res?.data[i]['From Location'];
                this.fullData[i]['To location']       = res?.data[i]['To location'];
                this.fullData[i]['Created date']      = this.appservice.dateFormatDisplay(res?.data[i]['Created date'])
                this.fullData[i]['Employee Name']     = res?.data[i]['Employee name'];
              }
            }
          }
          this.commonLogic(this.fullData)
        })
      }
      if (this.reportName == 'Expense Payment Register') {
        this.expenseServ.expensePaymentReport(limit, offset, this.ordering, this.filterFormData, this.childFilterSubmit).subscribe((res: any) => {
          if (res?.data?.length != 0) {
            this.fullData = res?.data;
            this.lastPage = res?.count
            // Formating as per standard for dates
            if (this.fullData?.length > 0) {
              for (let i = 0; i < res.data?.length; i++) {
                this.fullData[i]['Full name']         = res?.data[i]['Employee name'];
                // this.fullData[i]['Paid on']        = this.appservice.dateFormatDisplay(res?.data[i]['Paid on'])
                this.fullData[i]['Employment status'] = res?.data[i]['Employment Status'];
                const [start, end]                    = res?.data[i]['Duration'].split(' - ');
                this.fullData[i]['Duration']          = end == undefined ? '' : `${moment(start).format(this.dateFormat)} - ${moment(end).format(this.dateFormat)}`
              }
            }
          }
          this.commonLogic(this.fullData)
        })
      }
      if (this.reportName == 'Expense Receipt Details') {
        this.expenseServ.expenseReceiptReport(limit, offset, this.ordering, this.filterFormData, this.childFilterSubmit).subscribe((res: any) => {
          if (res?.data?.length != 0) {
            this.fullData = res?.data;
            this.lastPage = res?.count
            // Formating as per standard for dates
            if (this.fullData?.length > 0) {
              for (let i = 0; i < res.data?.length; i++) {
                this.fullData[i]['Full name']         = res?.data[i]['Employee name'];
                this.fullData[i]['Dispensed on']      = this.appservice.dateFormatDisplay(res?.data[i]['Dispensed on'])
                this.fullData[i]['Employment status'] = res?.data[i]['Employment Status'];
                this.fullData[i]['Date']              = this.appservice.dateFormatDisplay(res?.data[i]['Date'])
                this.fullData[i]['Created date']      = this.appservice.dateFormatDisplay(res?.data[i]['Created date'])
              }
            }
          }
          this.commonLogic(this.fullData)
        })
      }
      if (this.reportName == 'Outstanding Advance Report') {
        this.expenseServ.expenseOutstandingReport(limit, offset, this.ordering, this.filterFormData, this.childFilterSubmit).subscribe((res: any) => {
          if (res?.data?.length != 0) {
            this.fullData = res?.data;
            this.lastPage = res?.count
            if (this.fullData?.length > 0) {
              for (let i = 0; i < res.data?.length; i++) {
                this.fullData[i]['Full name']                          = res?.data[i]['Employee name'];
                this.fullData[i]['Employment status']                  = res?.data[i]['Employment Status'];
                this.fullData[i]['Outstanding expense advance amount'] = res?.data[i]['Outstanding']
              }
            }
          }
          this.commonLogic(this.fullData)
        })
      }
      if (this.reportName == 'Expense Report Details') {
        this.expenseServ.expenseDetailsReport(limit, offset, this.ordering, this.filterFormData, this.childFilterSubmit).subscribe((res: any) => {
          if (res?.data?.length != 0) {
            this.fullData = res?.data;
            this.lastPage = res?.count
            // Formating as per standard for dates
            if (this.fullData?.length > 0) {
              for (let i = 0; i < res.data?.length; i++) {
                this.fullData[i]['Full name']         = res?.data[i]['Employee name'];
                this.fullData[i]['Employment status'] = res?.data[i]['Employment Status'];
                const [start, end]                    = res?.data[i]['Duration'].split(' - ');
                this.fullData[i]['Duration']          = end == undefined ? '' : `${moment(start).format(this.dateFormat)} - ${moment(end).format(this.dateFormat)}`
              }
            }
          }
          this.commonLogic(this.fullData)
        })
      }
  }

  commonLogic(data: any) {
    let column     = this.displayedColumns;
    const newArray = [];
    for (const obj of data) {
      const newObject: any = {};
      for (const key of column) {
        if (obj.hasOwnProperty(key)) {
          newObject[key] = obj[key];
        }
      }
      newArray.push(newObject);
    }

    if (data != undefined)
      this.exportExcel(newArray, this.reportName);
    this.loader = false
    this.cd.detectChanges()
  }

  public exportExcel(jsonData: any[], fileName: string): void {
    if (!this.nodata) {
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonData);
      const wb: XLSX.WorkBook = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      this.saveExcelFile(excelBuffer, fileName);
    }
    else {
      for (let i = 0; i < this.displayedColumns.length; i++) {
        this.excelHeaders.push(this.displayedColumns[i])
        this.templateToExcel = [this.excelHeaders, []];
      }
      const wss: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.templateToExcel);
      const wbb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wbb, wss, 'Sheet1');
      XLSX.writeFile(wbb, fileName + this.fileExtension)
    }
  }

  private saveExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: this.fileType });
    if (this.nodata != true)
      FileSaver.saveAs(data, fileName + this.fileExtension);
  }

  // Table
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.displayedColumns, event.previousIndex, event.currentIndex);
    this.columnChangedArr = this.displayedColumns
    this.filterSubmit = true
  }

   // Reorder Arr
   reorderArray(arr1:any, arr2:any) {
    const set1 = new Set(arr1);
    const matchingItems = arr2.filter((item:any) => set1.has(item));
    const extraItems = arr2.filter((item:any) => !set1.has(item));
    matchingItems.sort((a:any, b:any) => arr1.indexOf(a) - arr1.indexOf(b));
    return matchingItems.concat(extraItems);
  }

  pageChangeDetect: boolean = false

  // Pagination
  pageChanged(val: any) {
    this.pageNumber = val - 1
    this.offset = this.reportService.calculateOffset(val - 1)
    this.cd.detectChanges()
    if (this.initialLoaded) {
      this.pageChangeDetect = true
      this.callMainReportFunction()
    } else {
      this.pageChangeDetect = true
      this.expenseMainComp.submitFilterComponent()
    }
    if (!this.columnApplied) {
      this.columnApplied = false;
    } else {
      this.columnApplied = true;
    }
  }

  callParentFunction(val: any) {
    this.initialLoaded = val;
  }

  resetCall(val: any) {
    this.page        = 1;
    this.pageNumber  = 0;
    this.resetOffset = true;
  }

  // Loader
  loaderFn(value: any) {
    this.loader = value;
  }

  // Datafilters for filter save
  dataFilters(value: any) {
    this.dataFilter = value.data;
  }

  // Filter Panel ON and OFF
  panel(value: any) {
    this.viewDetail = value;
  }

  // Reset filter btn
  resetFilterFn(value: any) {
    this.resetFilter   = value;
    this.columnApplied = false;
  }

  // Filtersubmit
  filterSubmits(value: any) {
    this.filterSubmit = value;
  }

  childCall: boolean = false;

  // Reset Filter
  resetFilterApply() {
    this.restFilterSave   = true;
    if (this.is_colfilter == true) {
      this.header_filter  = [];
      this.childCall      = false;
      this.filterMemorizeFunction('resetFilter', 'colFilter');
      this.is_colfilter   = false;
      this.childFilterSubmit = true;
    }
    else {
      this.childCall  = true;
      this.dataFilter = JSON.stringify({});
      this.filterMemorizeFunction('resetFilter', 'dataFilter');
    }
  }

  // Arrange data based on 1st & remove if not in 2nd
  rearragneAndRemove(arr1:any,arr2:any){
    let result = arr1.filter((item:any) => arr2.includes(item))
    let additionalItems = arr2.filter((item:any) => !arr1.includes(item));
    result.push(...additionalItems);
    return result
  }

  // Filter Memorize
  filterMemorizeFunction(data: any, data1: any) {
    if (this.header_filter?.length > 0) {
      var mySet          = new Set(this.header_filter);
      this.header_filter = [...mySet];
    }
    let headerNeeded = !this.arrayAreSame(this.displayedColumns, this.defaultHeaderList)
    if(this.columnChangedArr?.length !=0){
      this.columnChangedArr = this.rearragneAndRemove(this.columnChangedArr,this.checkColumn)
    }
    this.dataFilter = Object.keys(this.appliedFilter).length > 0 ? this.expenseMainComp.getData() : ""

    if (this.dataFilter == undefined || this.dataFilter == null || this.dataFilter == "") {
      this.dataFilter = JSON.stringify({});
    }

    this.reportService.filterMemorize({ 'data_filter': this.childCall == true ? JSON.stringify({}) : this.dataFilter, 'header_filter': this.columnChangedArr?.length != 0 ? JSON.stringify(this.columnChangedArr) : ((headerNeeded && !this.is_colfilter) ? JSON.stringify(this.displayedColumns) : JSON.stringify([])), 'is_active': true, 'report': Number(this.reportId) }).subscribe((res: any) => {
      if(this.callemp || this.resetFilter){
        this.notify.handleSuccessNotification("Updated Successfully","Success")
      }else{
        this.notify.handleSuccessNotification("Created Successfully","Success")
      }
      this.submitted         = false;
      this.filterSubmit      = false;
      this.saveFilterClicked = false;
      this.checkFilterSubmit = false;
      this.restFilterSave    = false;
      this.hasChangeCount    = 0;
      if (data == 'saveFilter') {
        this.resetFilterControllers('save');
        this.applyFilter = false;
      }
      else if (data == 'resetFilter') {
        this.resetFilter = false;
        this.childCall   = false;
        if (data1 == 'dataFilter')
          this.resetFilterControllers('reset');
        else if (data1 == 'colFilter')
          this.resetAllUnDefaultColumns();

        this.viewDetail     = false;
      }
      if (data1 == 'saveAndSchedule') {
        this.scheduleReport = false;
        setTimeout(() => {
          if (this.reportId != undefined && this.reportId != null)
            this.router.navigate(['/scheduled-reports/reports-setup/' + this.reportId + '/true']);
        }, 3000);
      }
    });
  }

  confirm(): boolean {
    if (this.isFilter) {
      return true;
    }
    else {
      if (this.filterSubmit == true || this.checkFilterSubmit == true) {
        this.applyFilter       = true;
        this.filterSubmit      = false;
        this.checkFilterSubmit = false;

        return false;
      } else {
        this.applyFilter       = false;
        return true;
      }
    }
  }


  resetFilterControllers(data: any) {
    this.viewDetail    = false;
    this.filterSubmit  = false;
    this.expenseMainComp.resetFilterControllers(data)
  }

  resetAllUnDefaultColumns() {
    this.checkFilterSubmit = false;
    this.checkColumn = []
    var elements = document.getElementsByName("check");
    setTimeout(() => {
      for (let i = 0; i < elements?.length; i++) {
        var element = <HTMLInputElement>elements[i];
        if (element.disabled == false)
          element.checked = false;
      }

      for (let j = 0; j < this.header_filter?.length; j++) {
        const index: number = this.displayedColumns.indexOf(this.header_filter[j]);
        if (index != -1) {
          this.displayedColumns.splice(index, 1);
        }
      }
      if (this.resHeader_filter?.length == 0) {
        this.dynamicHeader(this.defaultHeaderList?.length, this.defaultHeaderList, this.columns2);
        this.headerFilterImplementation(this.defaultHeaderList)
      }
      this.header_filter = [];
      this.columnsArr    = [];
    }, 1000);

    if (this.resHeader_filter?.length != 0) {
      this.callMainReportFunction()
    }
  }

  // Save
  saveFilter() {
    this.columnsArr          = [];
    if (this.filterSaveForm.value.filter == true) {
      this.saveFilterClicked = true;
      this.filterMemorizeFunction('saveFilter', '');
    } else {
      this.applyFilter       = false;
      this.submitted         = false;
      this.filterSubmit      = false;
      this.checkFilterSubmit = false;
    }
    this.router.navigate(['/expense-report'])
  }

  checkChange: boolean = false;
  headerFilterAdd(e: any, index: any) {
    this.checkChange    = true;
    this.columnApplied  = true;
    this.header_filter  = this.dupliCateRemove(this.header_filter);
    if (e.target.checked) {
      this.submitted    = true;
      this.checkColumn.push(e.target.value);
      this.header_filter.push(e.target.value);
    }
    else {
      if (this.header_filter.length == 1) {
        var elements = document.getElementsByName("check");
        setTimeout(() => {
          var element = <HTMLInputElement>elements[index];
          element.checked = true;
        })
      }
      else {
        this.checkColumn = this.dupliCateRemove(this.checkColumn)
        const index: number        = this.checkColumn.indexOf(e.target.value);
        const header_index: number = this.header_filter.indexOf(e.target.value);
        const column_1: number     = this.columns1.indexOf(e.target.value);
        this.checkColumn.splice(index, 1);
        this.header_filter.splice(header_index, 1);
        this.columns1.splice(column_1, 1);
      }
    }
  }

  resetColumnFilterFunction() {
    if (this.resHeader_filter?.length != 0) {
      !this.arrayAreSame(this.resHeader_filter, this.defaultHeaderList) ? this.resetFilter = true : this.resetFilter   = false;
      this.is_colfilter  = true;
    } else {
      this.resetAllUnDefaultColumns();
    }
    this.columnChangedArr = []
  }

  arrayAreSame(res1: any, res2: any) {
    if (res1.length !== res2.length) {
      return false
    }
    for (let i = 0; i < res1.length; i++) {
      if (res1[i] !== res2[i]) {
        return false
      }
    }
    return true
  }

  headerFilterApply() {
    if (!this.checkChange) {
      return
    }
    let array3 = this.columns1?.concat(this.checkColumn);
    array3 = array3?.filter((item: any, index: any) => {
      return (array3.indexOf(item) == index)
    })
    if(this.columnChangedArr?.length !=0){
      array3 = this.reorderArray(this.columnChangedArr,array3)
    }
    this.displayedColumns    = array3;
    this.columnsArr          = this.displayedColumns;
    if (this.checkColumn?.length > 0 && this.columnApplied)
      this.checkFilterSubmit = true;
    else
      this.checkFilterSubmit = false;

    // this.checkFilterSubmit  = true;
    this.checkChange         = false;
  }

  salFilter(d: any) {
    let dateRange = [new Date(new Date().getFullYear() - 50, 0, 1),
    new Date(new Date().getFullYear() + 50, 11, 31)]
    return (d >= dateRange[0] && d <= dateRange[1])
  }

  callReport(value: any) {
    this.filterSubmit      = false;
    this.childFilterSubmit = true;
    this.page              = 1;
    this.pageNumber        = 0;
    this.resetOffset       = true;
    this.callMainReportFunction();
  }

  // Dropdowns
  // Company List
  getCompanyList() {
    this.roleService.getCompanyList().subscribe((res: any) => {
      if (res.status == 200 && res.body?.length > 0) {
        this.companyDD = res.body;
        this.selectAllForDropdownItems(this.companyDD);
      }
    });
  }

  // BU List
  getBUList() {
    let comp = this.OuFilterForm.value.company
    if (comp != undefined && comp != null && comp != '') {
      this.reportService.getBuList(comp).subscribe((res: any) => {
        this.buDD = res;
        this.selectAllForDropdownItems(this.buDD);
      });
    }
  }

  // Department List
  getDepList() {
    this.roleService.getDepartmentList().subscribe((res: any) => {
      if (res.status == 200 && res.body?.length > 0) {
        this.DepDD = res.body;
        this.selectAllForDropdownItems(this.DepDD);
      }
    });
  }

  // Designation List
  getDesignationList() {
    this.roleService.getDesignationList().subscribe((res: any) => {
      if (res.status == 200 && res.body?.length > 0) {
        this.DesignationDD = res.body;
        this.selectAllForDropdownItems(this.DesignationDD);
      }
    });
  }

  // Grade List
  getGradeList() {
    this.roleService.getGradeList().subscribe((res: any) => {
      if (res.status == 200 && res.body?.length > 0) {
        this.gradeDD = res.body;
        this.selectAllForDropdownItems(this.gradeDD);
      }
    });
  }

  // Branch List
  getBranchList() {
    this.reportService.getBranchList().subscribe((res: any) => {
      this.BranchDD = res;
      this.selectAllForDropdownItems(this.BranchDD);
    });
  }

  // Employee List
  getEmployeeList() {
    this.leaveService.getEmpList(true)
      .subscribe((res: any) => {
        if (res?.length > 0) {
          for (let i = 0; i < res?.length; i++) {
            res[i]['name'] = res[i]['first_name'];
            if (res[i]['first_name'] != null)
              res[i]['name'] = res[i]['first_name'] + " ";
            if (res[i]['middle_name'] != null)
              res[i]['name'] += res[i]['middle_name'] + " ";
            if (res[i]['last_name'] != null)
              res[i]['name'] += res[i]['last_name'];
            res[i]['name'] += " (" + res[i]['employee_code'] + ")";
          }
          this.employeeDropdown = res;
          let len = this.employeeDropdown.length;
          for (let i = 0; i < len; i++) {
            this.employeeDropdown[i]['fullname'] = "";
            if (this.employeeDropdown[i]['first_name'] != null)
              this.employeeDropdown[i]['fullname'] += this.employeeDropdown[i]['first_name'] + " ";
            if (this.employeeDropdown[i]['middle_name'] != null)
              this.employeeDropdown[i]['fullname'] += this.employeeDropdown[i]['middle_name'] + " ";
            if (this.employeeDropdown[i]['last_name'] != null)
              this.employeeDropdown[i]['fullname'] += this.employeeDropdown[i]['last_name'];
          }
          // this.selectAllForDropdownItems(this.employeeDropdown);
        }
      })
  }

  // Expense Policy List
  getExpensePolicyList() {
    this.expenseServ.getExpensePolicyDropdown().subscribe((res: any) => {
      this.expensePolicyDropdown = res;
      this.selectAllForDropdownItems(this.expensePolicyDropdown);
    })
  }

  // Advance Policy List
  getAdvancePolicyList() {
    this.expenseServ.getAdvancePolicyDropdown().subscribe((res: any) => {
      this.advancePolicyDropdown = res;
      this.selectAllForDropdownItems(this.advancePolicyDropdown);
    })
  }

  // Date Format
  getDateFormat() {
    this.matDateFormat.display.dateInput = this.appservice.getdatepickerformat();
    this.dateFormat = this.appservice.getdatepickerformat()
    if (this.matDateFormat.display.dateInput == '' && this.dateFormat == '') {
      setTimeout(() => {
        this.getDateFormat();
      }, 1000);
    }
  }

  onPopoverClick(event: Event): void {
    // Prevent the click event from propagating to parent elements
    event.stopPropagation();
  }

  dynamicColumn: string = 'Employee Code';
  dynamicSortDirection: 'asc' | 'desc' = 'asc';

  sortProperty : string = 'Employee Code';
  direction : string    = 'asc';

  onSort(val: any) {
    if (this.sortProperty === val) {
      this.direction = this.direction === 'desc' ? 'asc' : 'desc';
    } else {
      this.sortProperty = val;
      this.direction = 'desc';
    }
    const active = val.split(' ').join('_').toLowerCase();
    const newOrder = this.direction === 'desc' ? '-' : '';
    this.ordering = newOrder + (val.includes(' ') ? active : val.toLowerCase())
    this.cd.detectChanges();
    this.filterFormData = this.filterFormData?.replace(/ordering=.*?(?=&|$)/, `ordering=${this.ordering}`);
    this.pageChangeDetect = true
    this.columnApplied == true ? this.columnApplied = true : this.columnApplied = false
    this.callAllReportsFunctions();
    // this.columnApplied = false;
  }

  callMainReportFunction() {
    if (this.reportName == 'Expense Advance') {
      this.expenseAdvanceReport();
    }
    if (this.reportName == 'Expense Report - Amount') {
      this.expenseReport()
    }
    if (this.reportName == 'Expense Report - Mileage') {
      this.expenseMileageReport()
    }
    if (this.reportName == 'Expense Payment Register') {
      this.expensePaymentReport();
    }
    if (this.reportName == 'Expense Receipt Details') {
      this.expenseReceiptReport();
    }
    if (this.reportName == 'Outstanding Advance Report') {
      this.expenseOutstandingReport();
    }
    if (this.reportName == 'Expense Report Details') {
      this.expenseDetailsReport();
    }
  }
}
