import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-nodata-view',
  templateUrl: './nodata-view.component.html',
  styleUrls: ['./nodata-view.component.scss']
})
export class NodataViewComponent implements OnInit {
  @Input() hasPermission: any;
  @Input() noDataText: any;
  @Input() noDataPara: any;
  @Input() noDataImg: any;
  @Input() imgHeight: any;
  @Input() addtext: any = 'ADD NOW';
  @Output() addData: any = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    this.noDataImg = this.noDataImg == undefined ? 'bg0.png' : this.noDataImg
    this.imgHeight = this.imgHeight == undefined ? '15rem' : this.imgHeight
  }
  addNewdata() {
    this.addData.emit();

  }

}
// [imgHeight] = "' '"
// [noDataText] = "' '"
// [noDataPara] = "' '"
