import { Component, OnInit, Input, Output, EventEmitter ,Inject} from '@angular/core';
import { FormBuilder,Validators  } from '@angular/forms';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { ExpenseReceiptService } from 'src/app/@core/services/expense-receipt.service';
import { MatDateFormats, MAT_DATE_FORMATS } from '@angular/material/core';
import { digitdecimalValidator } from '../../validators/2digitdecimal.validators';
import { ExpenseReportProfileService } from 'src/app/@core/services/expense-report-profile.service';
import * as moment from 'moment';
import { AuthService } from 'src/app/@core/auth/auth.service';
@Component({
  selector: 'app-add-new-expense',
  templateUrl: './add-new-expense.component.html',
  styleUrls: ['./add-new-expense.component.scss']
})
export class AddNewExpenseComponent implements OnInit {
  selectedOption = 'amount';
  @Input() from:any
  @Input() addExpense:any
  @Input() editId:any = 0
  @Input() currentreport:any;
  @Output() closeAddExpense  = new EventEmitter();
  @Output() saveReceipt      = new EventEmitter();
  reportDD:any               = [];
  categoryDD:any             = [];
  locationDD:any             = [];
  singleCategory:any         = [];
  locationLabel:any          = 'Location';
  isrequired:any             = false;
  milageRate:any             = 0;
  uploadedFile:any           = [];
  listOfFiles:any            = [];
  loader                     = false;
  modelLabel:any             = 'Add';
  fileError                  = false;
  fileErrorMsg: any          = "";
  attachmentRequired         = false;
  minDate                    = new Date(2020, 0, 1);
  currency:any
  receiptForm                = this.formBuilder.group({
                                expense_type      : [false],
                                report            : [null],
                                expense_date      : ['',Validators.required],
                                category          : [null,Validators.required],
                                amount            : [''],
                                is_reimbursement  : [false],
                                desicription      : ['',[Validators.maxLength(500)]],
                                attachments       : [[]],
                                from_location     : [null,Validators.required],
                                to_location       : [null],
                                distance          : [0],
  })
  employee_id:any;
  categoryselect=false;
  // File Upload
  modalToggle                     = false;
  allowedExtension                = "";
  alertMsg:any                    = '';
  alertToggle                     = false;
  fileName                        = "";
  currentDocName:any;
  viewDocName:any;
  currentExtension:any;
  disablebtn = false;
  editinitkey:any;
  constructor(
    private formBuilder: FormBuilder,
    public appService: AppService,
    public messageService : MessageService,
    public receiptService:ExpenseReceiptService,
    public reportService:ExpenseReportProfileService,
    @Inject(MAT_DATE_FORMATS) public matDateFormat: MatDateFormats,
    private authService:AuthService,
  ) { }
  ngOnInit(): void {
    let userData        = this.appService.getEmpData();
    this.currency       = userData.currency;
    this.employee_id    = this.authService.isEmployeeId
    if(this.editId!=0){ 
      this.editinitkey = this.editId;
      this.editReceipt();
    }
    else{
      this.editinitkey = '';
      this.changeExpenseType();
      this.resetForm();
    }
    this.getDateFormat()
    this.getAllReport();
    if(this.from=='expenseReportRequest'){
      this.receiptForm.get('report')?.setValue(this.currentreport);
      this.receiptForm.get('report')?.disable();
    }
  }
  resetForm(){
    this.receiptForm.reset();
    this.receiptForm.get('expense_type')?.setValue(false)
    this.receiptForm.get('is_reimbursement')?.setValue(false)
    this.listOfFiles  = [];
    this.uploadedFile = []
  }
  getDateFormat(){
    this.matDateFormat.display.dateInput = this.appService.getdatepickerformat();
    if(this.matDateFormat.display.dateInput == ''){
      setTimeout(() => {
        this.getDateFormat();
      }, 1000);
    }
  }
  close(){
    this.closeAddExpense.emit();
  }
  saveExpense(){
    let attachment:any= []
    if(this.receiptForm.get('attachments')?.value==null)
    this.receiptForm.get('attachments')?.setValue(attachment);
    this.saveReceipt.emit({form_value: this.receiptForm.getRawValue() , editId:this.editId});
  }
  get f() { return this.receiptForm.controls; }
  getAllReport(){
    this.receiptService.getReportDropdown().subscribe((res:any)=>{
      this.reportDD = res;
    })
  }
  dateFilter(d: any){
    let dateRange = [new Date(new Date().getFullYear() - 100, 0, 1),
      new Date()]
    return (d >= dateRange[0] && d<= dateRange[1])
  }
  changeExpenseType(){
    this.receiptForm.get('report')?.reset();
    this.receiptForm.get('expense_date')?.reset();
    this.receiptForm.get('category')?.reset(); 
    this.receiptForm.get('amount')?.reset();    
    this.receiptForm.get('is_reimbursement')?.reset(); 
    this.receiptForm.get('desicription')?.reset();    
    this.receiptForm.get('attachments')?.reset();   
    this.receiptForm.get('from_location')?.reset();   
    this.receiptForm.get('to_location')?.reset();    
    this.receiptForm.get('distance')?.reset(); 
    this.getCategoryData(this.receiptForm.get('expense_type')?.value)
    if(this.receiptForm.get('expense_type')?.value==true)
    this.milegeValidators();
    else
    this.amountFiledValidators();
  }
  getCategoryData(type:any){
    this.receiptService.categoryListData(type,this.employee_id).subscribe((res:any)=>{
      this.categoryDD = res;      
      let date = new Date();
      if(res.length != 0){
        this.receiptService.getCategoryConfig(this.categoryDD[0].id,moment(date).format('YYYY-MM-DD'),this.editinitkey).subscribe((res:any)=>{
        if(res.is_file_expense == false){
             this.minDate = res.date_of_joined;   
            }  
    })
  }
    })
  }
  getLocationData(id:any){
    // this.loader = true;
    this.categoryselect = false;
    this.receiptService.LocatonListData(id).subscribe((res:any)=>{
      this.locationDD = res;
      this.categoryselect = true;
      // this.loader = false;
    })
  }
  amountFiledValidators(){
    this.locationLabel  = 'Location';
    this.setValidators('amount',true,digitdecimalValidator,10)
    this.setValidators('to_location',false,false,0)
    this.setValidators('distance',false,digitdecimalValidator,10)
    this.receiptForm.get('distance')?.setValue(0)
    this.setValue();
  }
  milegeValidators(){
    this.locationLabel  = 'From Location';
    this.setValidators('amount',false,digitdecimalValidator,10)
    this.setValidators('to_location',true,false,0)
    this.setValidators('distance',true,digitdecimalValidator,10)
    this.receiptForm.get('distance')?.setValue('')
    this.setValue();
    this.calculateAmount();
  }
  setValue(){
    this.receiptForm.get('category')?.setValue(null)
    this.receiptForm.get('amount')?.setValue('')
    this.receiptForm.get('to_location')?.setValue(null)
    this.receiptForm.get('from_location')?.setValue(null)
    this.receiptForm.get('is_reimbursement')?.setValue(false)
    this.receiptForm.get('is_reimbursement')?.enable()
  }
  changeCategory(){
    if(this.receiptForm.get('category')?.value!=null && this.receiptForm.get('category')?.value!=''){
    this.getLocationData(this.receiptForm.get('category')?.value);
    if(this.receiptForm.get('expense_date')?.value==null || this.receiptForm.get('expense_date')?.value==''){
      // this.receiptService.getSingleCategoryData(this.receiptForm.get('category')?.value).subscribe((res:any)=>{
      //   this.singleCategory = res;        
      //   this.setDefaultvalue();
      //   this.validateAttachment();
      // })
    }
    else{
      let date = this.appService.dateFormatConvert(this.receiptForm.get('expense_date')?.value)
        this.receiptService.getCategoryConfig(this.receiptForm.get('category')?.value,date,this.editinitkey).subscribe((res:any)=>{
          this.singleCategory = res;
          this.seterror(res);
          this.setDefaultvalue();
          this.validateAttachment();
          if(this.receiptForm.get('from_location')?.value !='' && this.receiptForm.get('from_location')?.value !=null && this.singleCategory.amount_limit_type =='location_dependent_limit'){ 
            this.locationbasedlimit(); 
          }
        })
      }
    }
  }
  setDefaultvalue(){
    if(this.editId==0)
    this.receiptForm.get('is_reimbursement')?.setValue(this.singleCategory['is_reimbursable'])
    if(this.singleCategory['is_reimbursable']==false)
    this.receiptForm.get('is_reimbursement')?.disable();
    this.setValidators('desicription',this.singleCategory['is_description'],false,500);
    if(this.singleCategory['limit']!=null && this.receiptForm.get('expense_type')?.value==false){
    this.receiptForm.get('amount')?.setValidators([Validators.required,Validators.maxLength(10),digitdecimalValidator(),Validators.max(this.singleCategory['claim_limit_balance']),Validators.min(1)])
    this.receiptForm.get('amount')?.updateValueAndValidity();    
    }
    if(this.receiptForm.get('expense_type')?.value==true && this.singleCategory['limit']!=null ){
    this.receiptForm.get('distance')?.setValidators([Validators.required,Validators.maxLength(10),digitdecimalValidator(),Validators.max(this.singleCategory['claim_distance_balance']),Validators.min(1)])
    this.receiptForm.get('distance')?.updateValueAndValidity();      
    }
    if(this.receiptForm.get('amount')?.invalid && (this.receiptForm.get('amount')?.value !='' && this.receiptForm.get('amount')?.value !=null))
    this.receiptForm?.get('amount')?.markAsTouched()
    if(this.singleCategory['claim_fequency_limit_balance']<=0)
    this.f.expense_date.setErrors({
      'category' : true
    })
    if(this.receiptForm.get('category')?.invalid)
    this.receiptForm?.get('category')?.markAsTouched()
    if(this.receiptForm.get('expense_type')?.value==false && this.receiptForm.get('amount')?.value !='' && this.receiptForm.get('amount')?.value !=null){
      this.receiptForm?.get('amount')?.markAsTouched();
    }
    if((this.receiptForm.get('distance')?.value>this.singleCategory['claim_distance_balance']) && this.receiptForm.get('expense_type')?.value==true){
      this.f.distance.setErrors({
        'max' : true
      })
      this.receiptForm?.get('distance')?.markAsTouched();
    }
    this.calculateAmount()
  }
  setValidators(control:any,value:any,pattern:any,maxlength:any){
    if(value==true){
      if(pattern==false && maxlength==0)
      this.receiptForm.get(control)?.setValidators([Validators.required])
      else if(pattern==false && maxlength!=0)
      this.receiptForm.get(control)?.setValidators([Validators.required,Validators.maxLength(maxlength)])
      else if(pattern!=false && maxlength!=0)
      this.receiptForm.get(control)?.setValidators([Validators.required,Validators.maxLength(maxlength),digitdecimalValidator(),Validators.min(1)])
      else
      this.receiptForm.get(control)?.setValidators([Validators.required,digitdecimalValidator(),Validators.min(1)])
    }
    else{
    this.receiptForm.get(control)?.clearValidators();
    }
    this.receiptForm.get(control)?.updateValueAndValidity();
  }
  calculateAmount(){
    if(this.receiptForm.get('expense_type')?.value==true){
      this.milageRate = Number(this.singleCategory['rate_per_km'])*Number(this.receiptForm.get('distance')?.value)
      this.receiptForm.get('amount')?.setValue(this.milageRate);
    }
  }
  getURL(event:any){
    this.fileError        = false;
    this.uploadedFile[this.uploadedFile.length-1]['url']    = event;
    this.uploadedFile[this.uploadedFile.length-1]['loader'] = false;
    this.listOfFiles.push(event)
    this.receiptForm.get('attachments')?.setValue(this.listOfFiles)
  }
  getloader(event:any){
    if(event==true){
      let file = {
        loader  : event,
        url     : '',
        size    : ''
      }
      this.uploadedFile.push(file)
    }
    else{
      this.uploadedFile[this.uploadedFile.length-1]['loader'] = event;
    }
  }
  getfileformat(event: any) {
    if (event == false) {
      this.fileError    = true;
      this.fileErrorMsg = this.messageService.validateFileFormat('pdf,doc,img');
    }
  }
  getFilename(event: any) {
    this.uploadedFile[this.uploadedFile.length-1]['size'] = this.appService.formatSizeUnits(event.size);
  }
  getvalidsize(event: any) {
    this.fileError    = true;
    this.fileErrorMsg = event;
  }
  removeSelectedFile(index:any) {
    this.uploadedFile.splice(index, 1);
    this.listOfFiles.splice(index,1)
    this.receiptForm.get('attachments')?.setValue(this.listOfFiles)
  }
  editReceipt(){
    this.loader     = true;
    this.modelLabel = 'Edit'
    this.receiptService.getSingleReceipt(this.editId).subscribe((res:any)=>{
      Object.entries(res).forEach(([key, value]) => {
        if(key!='attachments' && key!='category' && key!='report' && key!='from_location' && key!='to_location'){
          this.receiptForm.get(key)?.setValue(value)
        }
        else if(key!='attachments'){
          this.receiptForm.get(key)?.setValue(res[key]['id'])
          if(key=='category'){
            this.changeCategory();
            this.getLocationData(this.receiptForm.get('category')?.value);
          }
        }
        else
        {
          for(let i=0;i<res.attachments.length;i++){
            this.listOfFiles.push(res.attachments[i]['attachment'])
            let file = {
              loader  : false,
              url     : res.attachments[i]['attachment'],
              size    : ''
            }
            this.uploadedFile.push(file)
          }
          this.receiptForm.get('attachments')?.setValue(this.listOfFiles)
        }
        this.loader = false
      })
      this.getCategoryData(this.receiptForm.get('expense_type')?.value)
    })
  }
  validateAttachment(){
    // this.seterror(this.singleCategory);
    if(this.singleCategory['is_attachment']==true && this.singleCategory['attachment_minimum_amount_limit']==null){
      this.receiptForm.get('attachments')?.setValidators([Validators.required])
      this.attachmentRequired = true;
    }
    else if(this.singleCategory['is_attachment']==true && this.singleCategory['attachment_minimum_amount_limit']!=null){
      if(this.receiptForm.get('amount')?.value>=this.singleCategory['attachment_minimum_amount_limit']){
        this.receiptForm.get('attachments')?.setValidators([Validators.required])
        this.attachmentRequired = true;
      }
      else if(this.receiptForm.get('amount')?.value<this.singleCategory['attachment_minimum_amount_limit']){
        this.receiptForm.get('attachments')?.clearValidators();
        this.attachmentRequired = false;
      }
    }
    else{
      this.receiptForm.get('attachments')?.clearValidators();
      this.attachmentRequired = false;
    }
    this.receiptForm.get('attachments')?.updateValueAndValidity()
  }
  validateExpenseDate(from:any){
  if(this.receiptForm.get('expense_date')?.value!=null && this.receiptForm.get('expense_date')?.value!=''){
    if(this.receiptForm.get('report')?.value!=null && this.receiptForm.get('report')?.value!=''){
      let id                 = this.receiptForm.get('report')?.value
      const expenseDate      = moment(this.receiptForm.get('expense_date')?.value).format('YYYY-MM-DD')
      let convertDate        = new Date(expenseDate)
      this.reportService.getSingleReportData(id).subscribe((res:any)=>{
        const fromDate      = moment(res.from_date).format('YYYY-MM-DD')
        const toDate        = moment(res.to_date).format('YYYY-MM-DD')
        const ConvertedFrom = new Date(fromDate);
        const ConvertedTo   = new Date(toDate);
        if(convertDate.getTime() < ConvertedFrom.getTime() || convertDate.getTime() > ConvertedTo.getTime() ){
          this.f.expense_date.setErrors({
            'exceed' : true
          })
        }
        else{
          this.f.expense_date.setErrors(null);
        }
        
      })
    }
    if(from=='datefilter'){
      if(this.receiptForm.get('category')?.value!=null && this.receiptForm.get('category')?.value!='' && this.receiptForm.get('expense_date')?.value!='' && this.receiptForm.get('expense_date')?.value!=null && (this.receiptForm.get('from_location')?.value =='' || this.receiptForm.get('from_location')?.value ==null)){
        let date = this.appService.dateFormatConvert(this.receiptForm.get('expense_date')?.value)
        this.receiptService.getCategoryConfig(this.receiptForm.get('category')?.value,date,this.editinitkey).subscribe((res:any)=>{
          if(res['limit']!=null)
          this.receiptForm.get('amount')?.setValidators([Validators.required,Validators.maxLength(10),digitdecimalValidator(),Validators.max(res['claim_limit_balance']),Validators.min(1)])
          if(this.receiptForm.get('amount')?.invalid && (this.receiptForm.get('amount')?.value !='' && this.receiptForm.get('amount')?.value !=null))
          this.receiptForm?.get('amount')?.markAsTouched()
          this.seterror(res);
        })
      }else{
        this.locationbasedlimit(); 
      }
    }
  }
  }
  /********* VIEW DOCUMENT FUNCTION ****************/
  viewDocument(filename:any){
    this.modalToggle            = true;
    this.fileName               = filename;
    this.currentDocName         = this.appService.getDocName(filename);
    this.currentExtension       = this.fileName.split('.').pop();
    if(this.currentExtension == 'zip' || this.currentExtension == 'docx' || this.currentExtension == 'xlsx' || this.currentExtension == 'doc' || this.currentExtension == 'dotx'){
      this.modalToggle          = false;
      this.alertToggle         = true;
      this.alertMsg             = 'The preview is not available for the' +' '+this.currentExtension+' '+'file';
    }
  }
  seterror(res:any){
    if(res.claim_fequency_limit_balance == 0 ){
      this.disablebtn =true;
      this.f.category.setErrors({
        'limit' : true
      })
    }else{
      this.f.category.setErrors(null);
    }
    if(res.claim_limit_balance == 0 && res.amount_limit_type !='no_limit'){
      this.disablebtn =true;
      this.f.amount.setErrors({
        'exceed' : true
      })
    }else if(this.receiptForm.get('amount')?.errors?.required==false){
      this.f.amount.setErrors(null);
    }
    if(res.claim_distance_balance != 0 && (this.receiptForm.get('amount')?.value<res.claim_distance_balance || this.receiptForm.get('distance')?.value==res.claim_distance_balance) && this.receiptForm.get('distance')?.errors?.required==false){
      this.f.distance.setErrors(null);
    }
    if(res.claim_limit_balance != 0 && res.claim_fequency_limit_balance != 0){
      this.disablebtn =false;      
    }
  }
  locationbasedlimit(){
    if(this.receiptForm.get('category')?.value!=null && this.receiptForm.get('category')?.value!='' && this.receiptForm.get('expense_date')?.value!='' && this.receiptForm.get('expense_date')?.value!=null && this.receiptForm.get('from_location')?.value !='' && this.receiptForm.get('from_location')?.value !=null && (this.singleCategory.amount_limit_type =='location_dependent_limit' || this.receiptForm.get('expense_type')?.value==true)){
      let date = this.appService.dateFormatConvert(this.receiptForm.get('expense_date')?.value)
      this.receiptService.getCategoryvalidate(this.receiptForm.get('category')?.value,date,this.receiptForm.get('from_location')?.value,this.editinitkey).subscribe((res:any)=>{
        this.singleCategory = res;
        this.setDefaultvalue();
        this.validateAttachment();        
        this.seterror(res);
      if(this.receiptForm.get('amount')?.value !='' && this.receiptForm.get('amount')?.value !=null){
        this.receiptForm?.get('amount')?.markAsTouched();
      }
      })
    }
  }
  locationsame(){
   
    this.locationbasedlimit(); 
    if(this.receiptForm.get('from_location')?.value !='' && this.receiptForm.get('from_location')?.value !=null && this.receiptForm.get('to_location')?.value !='' && this.receiptForm.get('to_location')?.value !=null && (this.receiptForm.get('from_location')?.value == this.receiptForm.get('to_location')?.value)){
           this.f.to_location.setErrors({
          'same' : true
        })
      }else{
        this.f.to_location.setErrors(null);
      }
  }
}