<div class="mail-wrap">
  <!-- ****** Start Section 1 ******* -->
  <div class="mail-category">
    <div class="fs-12 fw-500 text-uppercase px-24 pt-24 pb-12 text-light-400"> Inbox </div>
    <ul>
      <li>
        <a [class.active]="selectedmenu == 1" (click)="menuClick(1, 1)">
          <span>All</span>
          <span>{{ alldatalength }}</span>
        </a>
      </li>
      <li>
        <a [class.active]="selectedmenu == 29" (click)="menuClick(29, 1)">
          <span>Appointment Letter</span>
          <span>{{ appointmenLetterLen }}</span>
        </a>
      </li>
      <li>
        <a [class.active]="selectedmenu == 19" (click)="menuClick(19, 1)">
          <span>Asset</span>
          <span>{{ assetLen }}</span>
        </a>
      </li>
      <li>
        <a [class.active]="selectedmenu == 11" (click)="menuClick(11, 1)" ngbPopover="Attendance Regularization" popoverClass="popover-default popover-info" container="body" triggers="hover">
          <span>Attendance Regularization</span>
          <span>{{ attRegLen }}</span>
        </a>
      </li>

      <li>
        <a [class.active]="selectedmenu == 18" (click)="menuClick(18, 1)">
          <span>Check-in</span>
          <span>{{ checkInLen }}</span>
        </a>
      </li>
      <li>
        <a [class.active]="selectedmenu == 16" (click)="menuClick(16, 1)">
          <span>Comp Off</span>
          <span>{{ compOffLen }}</span>
        </a>
      </li>
       <li>
        <a [class.active]="selectedmenu == 25" (click)="menuClick(25, 1)">
          <span>Confirmation Letter</span>
          <span>{{ LetterLen }}</span>
        </a>
      </li>
      <li>
        <a [class.active]="selectedmenu == 24" (click)="menuClick(24, 1)">
          <span>Confirmation Request</span>
          <span>{{ LetterreqLen }}</span>
        </a>
      </li>
      <li>
        <a [class.active]="selectedmenu == 3" (click)="menuClick(3, 1)">
          <span>Confirmed Declaration</span>
          <span>{{ cdatalength }}</span>
        </a>
      </li>
      <li>
        <a [class.active]="selectedmenu == 22" (click)="menuClick(22, 1)">
          <span>Dispense Advance</span>
          <span>{{ disAdv }}</span>
        </a>
      </li>
      <li>
        <a [class.active]="selectedmenu == 6" (click)="menuClick(6, 1)">
          <span>Employee Data</span>
          <span>{{ edatalength }}</span>
        </a>
      </li>
      <li>
        <a [class.active]="selectedmenu == 21" (click)="menuClick(21, 1)">
          <span>Expense Advance</span>
          <span>{{ expAdv }}</span>
        </a>
      </li>
      <li>
        <a [class.active]="selectedmenu == 23" (click)="menuClick(23, 1)">
          <span>Expense Report</span>
          <span>{{ expRepCnt }}</span>
        </a>
      </li>
      <li>
        <a [class.active]="selectedmenu == 10" (click)="menuClick(10, 1)">
          <span>FBP</span>
          <span>{{ fbpLen }}</span>
        </a>
      </li>
      <li>
        <a [class.active]="selectedmenu == 14" (click)="menuClick(14, 1)">
          <span>Final Settlement</span>
          <span>{{ ffALen }}</span>
        </a>
      </li>
      <li>
        <a [class.active]="selectedmenu == 13" (click)="menuClick(13, 1)" ngbPopover="Final Settlement Process" popoverClass="popover-default popover-info" container="body" triggers="hover">
          <span>Final Settlement Process</span>
          <span>{{ ffLen }}</span>
        </a>
      </li>
      <li>
        <a [class.active]="selectedmenu == 5" (click)="menuClick(5, 1)">
          <span>Floating Holiday</span>
          <span>{{ hdatalength }}</span>
        </a>
      </li>
      <li>
        <a [class.active]="selectedmenu == 8" (click)="menuClick(8, 1)">
          <span>Leave</span>
          <span>{{ ldatalength }}</span>
        </a>
      </li>
      <li>
        <a [class.active]="selectedmenu == 20" (click)="menuClick(20, 1)">
          <span>Manage Asset</span>
          <span>{{ manageAssetLen }}</span>
        </a>
      </li>
      <li>
        <a [class.active]="selectedmenu == 12" (click)="menuClick(12, 1)">
          <span>On Duty</span>
          <span>{{ onDutyLen }}</span>
        </a>
      </li>
      <li>
        <a [class.active]="selectedmenu == 15" (click)="menuClick(15, 1)">
          <span>Overtime</span>
          <span>{{ overTimeLen }}</span>
        </a>
      </li>
      <li>
        <a [class.active]="selectedmenu == 27" (click)="menuClick(27, 1)">
          <span>Probation Extension Letter</span>
          <span>{{ probationLetterLen }}</span>
        </a>
      </li>
      <li>
        <a [class.active]="selectedmenu == 2" (click)="menuClick(2, 1)">
          <span>Proposed Declaration </span>
          <span>{{ pdatalength }}</span>
        </a>
      </li>
      <li>
        <a [class.active]="selectedmenu == 28" (click)="menuClick(28, 1)">
          <span>Relieving Letter</span>
          <span>{{ relievingLetterLen }}</span>
        </a>
      </li>
      <li>
        <a [class.active]="selectedmenu == 9" (click)="menuClick(9, 1)">
          <span>Reimbursement</span>
          <span>{{ remLen }}</span>
        </a>
      </li>
      <li>
        <a [class.active]="selectedmenu == 7" (click)="menuClick(7, 1)">
          <span>Salary Revision</span>
          <span>{{ revisionlength }}</span>
        </a>
      </li>
      <li>
        <a [class.active]="selectedmenu == 30" (click)="menuClick(30, 1)">
          <span>Salary Revision Letter</span>
          <span>{{ revisionLetterLen }}</span>
        </a>
      </li>
      <li>
        <a [class.active]="selectedmenu == 4" (click)="menuClick(4, 1)">
          <span>Salary Structure</span>
          <span>{{ sdatalength }}</span>
        </a>
      </li>
       <li>
        <a [class.active]="selectedmenu == 31" (click)="menuClick(31, 1)">
          <span>Short Leave</span>
          <span>{{ SHLCnt }}</span>
        </a>
      </li>
      <li>
        <a [class.active]="selectedmenu == 26" (click)="menuClick(26, 1)">
          <span>Termination Letter</span>
          <span>{{ terLetterLen }}</span>
        </a>
      </li>
      <li>
        <a [class.active]="selectedmenu == 17" (click)="menuClick(17, 1)">
          <span>Work From Home</span>
          <span>{{ wrokfrmLen }}</span>
        </a>
      </li>
    </ul>
  </div>
  <!-- ****** Start Section 2 ******* -->
  <div class="mail-list" infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="100" [fromRoot]="true" (scrolled)="onScrollDown()" [scrollWindow]="false">
    <nav class="nav nav-custom sticky nav-inline p-0">
      <a class="nav-link" [class.active]="selectedtopmenu == 1" [class.disabled]="inboxLoader ? true : null" (click)="topmenuselector(false)"> Pending </a>
      <a class="nav-link" [class.active]="selectedtopmenu == 2" [class.disabled]="inboxLoader ? true : null" (click)="topmenuselector(true)"> Archive </a>
    </nav>
    <div class="px-32 d-flex align-items-center gap-16 py-16 position-sticky" style="top: 2.5rem; z-index: 888; background-color: #eff4f6">
      <div class="form-icon icon-left w-100">
        <input type="text" #search style="height: 2.0625rem" class="form-control fs-12" placeholder="Search by employee" (input)="searchValue(search.value)" />
        <i class="icon-search"></i>
      </div>
      <!-- <button class=" btn flex-center btn-outline-primary p-0 rounded-circle btn-sq-22 ms-auto fs-17">
            <i class="bi bi-filter mt-1"></i>
        </button>
        <div class="card" style="flex-basis: 100%;">
          <div class="card-body">.</div>
        </div> -->
      <div ngbDropdown #filterDropdown="ngbDropdown" container="body" class="d-inline-block">
        <button ngbDropdownToggle class="btn flex-center btn-outline-primary p-0 rounded-circle sq-24 ms-auto fs-17">
          <i class="bi bi-filter mt-1"></i>
        </button>
        <div ngbDropdownMenu class="p-24 mt-16 filter-dropdown" style="width: 21.625rem">
          <div class="row row-24 mb-24">
            <div class="col-12">
              <label for="" class="form-label">Filter by importance </label>
              <div class="form-check mt-12">
                <input type="checkbox" class="form-check-input" [(ngModel)]="starred" id="starred" (click)="getInboxList('', $event)" />
                <label for="starred" class="form-check-label">Starred</label>
              </div>
            </div>
            <div class="col-12 form-row">
              <label for="from_date" class="form-label">Time Period </label>
              <!-- <dp-date-picker mode="day" theme="dp-material" class=" form-ngdatepicker" [config]="config"  (onChange)="dateChanged($event)"></dp-date-picker> -->
              <div class="form-icon icon-right">
                <input class="form-control" type="text" ngxDaterangepickerMd [(ngModel)]="selected" [closeOnAutoApply]="true" [showCustomRangeLabel]="true" [alwaysShowCalendars]="false" [ranges]="ranges" [linkedCalendars]="true" [locale]="{ applyLabel: 'ok' }" [isInvalidDate]="isInvalidDate" [showClearButton]="true" placeholder="Select date" />
                <i class="icon-calendar pointer-event"></i>
              </div>
            </div>
          </div>
          <div class="row row-16">
            <div class="col-6">
              <div class="btn btn-outline-primary fs-14 btn-sm fw-400" (click)="dateChanged(''); filterDropdown.close()"> RESET </div>
            </div>
            <div class="col-6">
              <div class="btn btn-primary fs-14 btn-sm fw-400" (click)="dateChanged(selected); filterDropdown.close()"> APPLY </div>
              </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mail-list--container px-32">
      <app-c1-loader *ngIf="inboxLoader"></app-c1-loader>
      <ng-container *ngIf="!inboxLoader">
        <ng-container *ngIf="nodata">
          <div class="empty-container text-light-500" style="min-height: calc(100vh - (3rem + 2.5rem + 8.75rem))">
            <i class="icon-inbox-fill fs-56 mb-25 opacity-25"></i>
            <p class="mb-2 fs-18 fw-500">Your inbox is empty!</p>
            <p class="">There are no new messages currently</p>
          </div>
        </ng-container>
        <ng-container *ngIf="inboxdata.length > 0 && !nodata">
          <ng-container *ngFor="let inboxitem of inboxdata; let i = index">
            <div class="mail-card card-ribbon" [class.mail-active]="i == activeItem" [class.mail-new]="inboxitem['read_status'] == false" (click)="tabCardClick(inboxitem, i)">
              <span *ngIf="inboxitem.is_mail_overdue && !inboxitem?.achieved_status" class="ribbon ribbon-right ribbon-danger ribbon-sm" style="right: -0.0625rem; bottom: 0.75rem">Overdue</span>
              <span *ngIf="inboxitem.request_data.status == 'Invalid'" class="ribbon ribbon-right ribbon-danger ribbon-sm" style="right: -0.0625rem; bottom: 0.75rem">Invalid</span>
              <img class="avatar-circle sq-31 me-12 img-fluid rounded-circle" *ngIf="
                  inboxitem.from_person['profile_image'] != '' &&
                  inboxitem.from_person['profile_image'] != null
                " src="{{ inboxitem.from_person['profile_image'] }}" />
              <div class="avatar-circle fs-12 fw-500 sq-31 me-12 rounded-circle {{
                  inboxitem.from_person['color_code']
                }}" *ngIf="
                  inboxitem.from_person['profile_image'] == '' ||
                  inboxitem.from_person['profile_image'] == null
                ">
                <span>{{ this.appservice.getFirstChar(
                    inboxitem["from_person"].first_name +
                      "
                  " +
                      inboxitem["from_person"].last_name,
                    2
                  )
                }}</span>
              </div>
              <div class="mail-card-body">
                <div class="d-flex align-items-start w-100">
                  <div class="fs-14 fw-600 text-trim pe-8"> {{ inboxitem["from_person_name"] + " " }}({{ inboxitem["from_person"].employee_code }}) </div>
                  <span class="ms-auto text-light-500 fs-10 text-nowrap">{{ inboxitem["list_created_display"]
                  }}</span>
                  <button class="btn-reset btn-star ms-12 text-light-500 fs-10" [class.mail-stared]="inboxitem['star'] == true" (click)="
                      performAction(2, 0,  inboxitem['id'],'','',inboxitem['star'])">
                    <i class="bi toggle-star"></i>
                  </button>
                </div>

               <app-inbox-title class="align-items-start vstack" [inboxitem]="inboxitem"></app-inbox-title>
              </div>
            </div>
      </ng-container>
        </ng-container>
      </ng-container>
      <app-c1-loader *ngIf="infinityloader"></app-c1-loader>
    </div>
  </div>
  <!-- ****** Start Section 3 ******* -->
  <div class="mail-view">
    <div *ngIf="inboxLoader || inboxDataLoader" class="p-32">
      <app-c1-loader></app-c1-loader>
    </div>
    <ng-container *ngIf="!inboxLoader && !inboxDataLoader">
      <ng-container *ngIf="nodata">
        <div class="empty-container text-light-500" style="min-height: calc(100vh - 3rem)">
          <img src="assets\images\bg\change-password.png" class="img-fluid d-flex mx-auto pb-32" alt="" />
          <p class="">New messages will appear here...</p>
        </div>
      </ng-container>
      <ng-container *ngIf="inboxdata.length != 0">
        <app-salary-structure-approval *ngIf="inboxData.salarySturcture" [requestitem]="requestitem">
        </app-salary-structure-approval>
        <app-salary-revision [requestitem]="requestitem" *ngIf="inboxData.salaryRevision"
          (successEmitter)="refreshInbox($event)"></app-salary-revision>
        <app-floating-holiday-approval [requestitem]="requestitem" *ngIf="inboxData.floatingholiday"
          (successEmitter)="refreshInbox($event)"></app-floating-holiday-approval>
        <app-investment-declaration-approval *ngIf="inboxData.InvestmentDeclaration" [requestitem]="requestitem"
          (investmentcardClose)="closeSidePanel($event)"
          (refreshEmitter)="refreshInbox($event)"></app-investment-declaration-approval>
        <app-employee-data-approval [requestitem]="requestitem" *ngIf="inboxData.empDataApproval"
          (successEmitter)="refreshInbox($event)"></app-employee-data-approval>
        <app-leave-approval [requestitem]="requestitem" *ngIf="inboxData.leaveRequest"
          (successEmitter)="refreshInbox($event)"></app-leave-approval>
        <app-reimbursement-approval *ngIf="inboxData.reimbursement" [requestitem]="requestitem"
          (successEmitter)="refreshInbox($event)"></app-reimbursement-approval>
        <app-fbp-declaration-approval *ngIf="inboxData.fbpDeclaration" [requestitem]="requestitem"
          (successEmitter)="refreshInbox($event)"></app-fbp-declaration-approval>
        <app-on-duty-approval *ngIf="inboxData.onDuty" [requestitem]="requestitem"
          (successEmitter)="refreshInbox($event)"></app-on-duty-approval>
        <app-regularization-approval *ngIf="inboxData.regularization" [requestitem]="requestitem"
          (successEmitter)="refreshInbox($event)"></app-regularization-approval>
        <app-work-from-home-approval *ngIf="inboxData.workfromhome" [requestitem]="requestitem"
          (successEmitter)="refreshInbox($event)"></app-work-from-home-approval>
        <app-full-and-final-settlement *ngIf="inboxData.fandf"
          [requestitem]="requestitem"></app-full-and-final-settlement>
        <app-full-and-final-approval *ngIf="inboxData.fandfApp" [requestitem]="requestitem"
          (successEmitter)="refreshInbox($event)"></app-full-and-final-approval>
        <app-overtime-approval *ngIf="inboxData?.overtimeRequest" [requestitem]="requestitem"
          (successEmitter)="refreshInbox($event)"></app-overtime-approval>
        <app-compensatory-off *ngIf="inboxData?.compOffRequest" [requestitem]="requestitem"
          (successEmitter)="refreshInbox($event)"></app-compensatory-off>
        <app-checkin-approval *ngIf="inboxData?.checkin" [requestitem]="requestitem"
          (successEmitter)="refreshInbox($event)"></app-checkin-approval>
        <app-advance-approval *ngIf="inboxData?.expAdv || inboxData?.dispAdv" [requestitem]="requestitem"
        (successEmitter)="refreshInbox($event)"></app-advance-approval>
        <app-expense-report *ngIf="inboxData?.expRep" [requestitem]="requestitem"
        (successEmitter)="refreshInbox($event)"></app-expense-report>

        <app-asset-request-approval *ngIf="inboxData?.asset"
        [requestitem]="requestitem"
        (successEmitter)="refreshInbox($event)"></app-asset-request-approval>
        <app-manage-asset-approval *ngIf="inboxData?.manageAsset"
        [requestitem]="requestitem"
        (successEmitter)="refreshInbox($event)"></app-manage-asset-approval>
        <app-confirmation-req-approval
          *ngIf="inboxData?.Confirmation"
          [requestitem]="requestitem"
          (successEmitter)="refreshInbox($event)"
        ></app-confirmation-req-approval>
        <app-appointment-letter
          *ngIf="inboxData?.ConfirmationLetter || inboxData?.TerminationLetter || inboxData?.ProbationExtensionLetter || inboxData?.RelievingLetter || inboxData?.AppointmentLetter || inboxData?.SalaryRevisionLetter"
          [requestitem]="requestitem"
          (successEmitter)="refreshInbox($event)"
        ></app-appointment-letter>
        <app-short-leave *ngIf="inboxData?.shl" [requestitem]="requestitem"
        (successEmitter)="refreshInbox($event)"></app-short-leave>
      </ng-container>
    </ng-container>
  </div>
</div>
